import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'

import App from './app'
import LoginPage from '../login/login-page'
import LoginEsqueci from '../login/login-esqueci'
import RecuperarPage from '../login/recuperar-page'
import LockPage from '../login/lock-page'
import CancelPage from '../login/cancel-page'
import SenhaPage from '../login/senha-page'
import AgreePage from '../politica/agree-page'
//import AgreeLGPDPage from '../politica/agree-lgpd-page'
import { validarToken, logout, go_pwd_change } from '../login/login-actions'


class AppOrLogin extends Component {

    UNSAFE_componentWillMount() {
        if (this.props.login.usuario) {
            this.props.validarToken(this.props.login.usuario.token)
        }
    }


    componentWillUnmount() {
        document.body.classList.remove('login-page')
        document.body.classList.add('skin-blue')
        document.body.classList.add('sidebar-mini')
    }


    render() {
        const { usuario, token_valido, login_esqueci, agree, agree_lgpd} = this.props.login
        if (!agree) return <AgreePage/>
        //if (!agree_lgpd) return <AgreeLGPDPage/>
        
        if (login_esqueci) {
            return <LoginEsqueci />
        } else if ((usuario && token_valido)) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + usuario.token
            if (usuario['status'] === 2) {
                return <LockPage />
            } else if (usuario['status'] === 9) {
                return <CancelPage />
            } else if (usuario['status'] === 5) {
                return <SenhaPage />
            }
            if (usuario['recuperar']) {
                return <RecuperarPage />
            } else {
                return <App logout={this.props.logout} go_pwd_change={this.props.go_pwd_change}>
                            {this.props.children}
                        </App>
            }

        } else if(!usuario && !token_valido) {
            return <LoginPage />
        } else {
            return false
        }
    }
}

const mapStateToProps = state => ({ login: state.login })
const mapDispatchToProps = dispatch => bindActionCreators({ validarToken, logout, go_pwd_change }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AppOrLogin)