import React from 'react'

import { Anchor } from '../helper'
import { Link } from 'react-router-dom'

const HeaderAux= props => (
  <div className={`widget-user-header ${props.color_class}`} style={{minHeight: props.minHeightHeader || '0px'}}>
    <div className="widget-user-image">
      { props.image && <img className="img-circle" src={props.image} alt="Imagem" />}
      { props.icon && <i className={`${props.icon} pull-left`}></i> }

    </div>
    <h3 className="widget-user-username">{props.title || ''}</h3>
    <h5 className="widget-user-desc">{props.subtitle || ''}</h5>
  
  </div>
  
)

export default props => (
    <div className={`${props.col_class || ''}`} >
      <div className="box box-widget widget-user-2" >
        { props.route && <Link to={props.route}>  <HeaderAux {... props}/> </Link> }
        { !props.route && <HeaderAux {... props}/> }
        <div className="box-footer" style={{minHeight: props.minHeightContent || '0px'}}>
            <ul className="nav nav-stacked">
                {props.children}
            </ul>
        </div>
      </div>
    </div>

)

export const Item = props => (
  <li>
    {props.route && <Link to={props.route}>{props.children}</Link>}
    {!props.route &&
      <Anchor>
        {props.text || ' '}
        {props.children}
      </Anchor>
    }
  </li>
)

/*

<li><a href="#">Tasks <span class="pull-right badge bg-aqua">5</span></a></li>
<li><a href="#">Completed Projects <span class="pull-right badge bg-green">12</span></a></li>
<li><a href="#">Followers <span class="pull-right badge bg-red">842</span></a></li>


*/