import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Row, Grid } from '../common/layout'
import { ContentSection } from '../common/layout'
import { ButtonInfo } from '../common/ui/buttons'
import { LabelInfo } from '../common/ui/labels'


import CardProfile, { Item } from '../common/widget/card-profile'
import { modal_open } from '../common/ui/modal'
import OSFaturaStatus from './os-fatura-status'
// import OSFaturaServicoList from '../os-fatura/os-fatura-detalhe-list'
import OSFaturaServicosDetalhe from '../os-fatura/os-fatura-servico-detalhe';
import SendReport from '../tools/send-report'

import { date_loc, money_loc } from '../sys/normalize-location'

import { obter, listarServicos, listar_mais } from './os-fatura-action'
import { obter as obterDetalhe } from '../os-servico/os-servico-action'


import config from '../main/config'
import os_servico_card from '../os-servico/os-servico-card'

class OSFaturaFichaShow extends Component {


    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_fatura'])
        this.props.listarServicos({ field: 'id_fatura', value: params['id_fatura'] })
    }

    render_perfil() {
        const img_perfil_defa = config().IMAGE_PATH + 'fatura.png'
        return (
            <CardProfile
                image={img_perfil_defa}
                name={(this.props.os_fatura.dados['cliente'] || '') + ' ' + this.props.os_fatura.dados['documento']}
            >
                <Item label="Período" value={date_loc(this.props.os_fatura.dados['dia_inicio']) + ' à ' + date_loc(this.props.os_fatura.dados['dia_final'])}>
                    <LabelInfo title={this.props.os_fatura.dados['sit_fatura_label']} align_class="pull-right" />
                </Item>
                <Item label="Referência" value={this.props.os_fatura.dados['referencia'] || ''}></Item>
                <Item label="Preço" value={money_loc(this.props.os_fatura.dados['preco'] || 0)}></Item>
                <Item label="Preço adicional" value={money_loc(this.props.os_fatura.dados['preco_adicional'] || 0)}></Item>
                <Item label="Preço abater" value={money_loc(this.props.os_fatura.dados['preco_abater'] || 0)}></Item>
                <Item label="Cobrar HE" value={money_loc(this.props.os_fatura.dados['he_cobrar'] || 0)}></Item>

                <Item label="Custo" value={money_loc(this.props.os_fatura.dados['custo'] || 0)}></Item>
                <Item label="Custo Adicional Total" value={money_loc(this.props.os_fatura.dados['custo_adicional_total'])}></Item>
                <Item label="Combustível" value={money_loc(this.props.os_fatura.dados['combustivel'])}></Item>
                <Item label="Estacionamento" value={money_loc(this.props.os_fatura.dados['estacionamento'])}></Item>
                <Item label="Lavagem" value={money_loc(this.props.os_fatura.dados['lavagem'])}></Item>
                <Item label="Pedágio" value={money_loc(this.props.os_fatura.dados['pedagio'])}></Item>
                <Item label="Condução" value={money_loc(this.props.os_fatura.dados['conducao'])}></Item>
                <Item label="Alimentação" value={money_loc(this.props.os_fatura.dados['alimentacao'])}></Item>
                <Item label="Outros" value={money_loc(this.props.os_fatura.dados['outros'])}></Item>

                <Item label="Adiantamento Ag." value={money_loc(this.props.os_fatura.dados['adiantamento_agente'])}></Item>
                <Item label="Reembolsar Ag." value={money_loc(this.props.os_fatura.dados['reembolsar_agente'])}></Item>
                <Item label="Descontar Ag." value={money_loc(this.props.os_fatura.dados['descontar_agente'])}></Item>
                <Item label="Pagar HE Ag." value={money_loc(this.props.os_fatura.dados['he_pagar'])}></Item>
                <Item label="Total a Pagar" value={money_loc(this.props.os_fatura.dados['total_pagar'])}></Item>
                <Item label="Total a Cobrar" value={money_loc(this.props.os_fatura.dados['total_cobrar'])}></Item>

                <ButtonInfo align_class="btn-block" onClick={() => { modal_open('os-fatura-status') }}>Mudar Status</ButtonInfo>
                <ButtonInfo align_class="btn-block" onClick={() => { modal_open('fat-cobra-send-modal') }}>Fatura</ButtonInfo>
                <ButtonInfo align_class="btn-block" onClick={() => { modal_open('fat-cobra-interno-send-modal') }}>Listagem de Faturamento (interno)</ButtonInfo>
            </CardProfile>
        )
    }


    render() {

        return (
            <PageSetup title='Ficha da Fatura'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.render_perfil()}
                        </Grid>
                        <Grid cols="md-8">
                            {os_servico_card(this.props.os_fatura.servico)}
                            {(this.props.os_fatura.tem_mais) &&
                                <ButtonInfo onClick={() => {
                                    this.props.listar_mais(this.props.os_fatura.page + 1, this.props.os_fatura.dados['id_fatura'] || 0)
                                }}>Carregar mais...</ButtonInfo>
                            }

                            {/**
                             <OSFaturaServicoList
                                     dados={this.props.os_fatura.servico}
                                     obterDetalhe={(id_servico) => { this.props.obterDetalhe(id_servico) }}
                                     />
                             */}
                        </Grid>
                    </Row>
                    <OSFaturaStatus />
                </ContentSection>
                <OSFaturaServicosDetalhe />

                <SendReport
                    title='Encaminhar Relatório de Faturamento'
                    id='fat-cobra-send-modal'
                    param={{
                        route: 'os-fatura-cobra-print',
                        id_fatura: this.props.os_fatura.dados['id_fatura'] || 0
                    }}
                />

                <SendReport
                    title='Encaminhar Relatório de Faturamento INTERNO'
                    id='fat-cobra-interno-send-modal'
                    param={{
                        route: 'os-fatura-cobra-print-interno',
                        id_fatura: this.props.os_fatura.dados['id_fatura'] || 0
                    }}
                />


            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({
    os_fatura: state.os_fatura,
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter, obterDetalhe, listarServicos, listar_mais }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSFaturaFichaShow)
