import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { GroupUnbordered, GroupItem } from '../common/widget/box'
import { LabelSucess, LabelInfo, LabelDanger, LabelDefault } from '../common/ui/labels'

import { date_time_loc, money_loc } from '../sys/normalize-location'

import Modal from '../common/ui/modal'

const os_servico_situacao = (s) => {
    switch (s) {
        case '1': return <LabelSucess title='Programado'/>
        case '2': return <LabelDanger title='Andamento'/>
        case '7': return <LabelInfo title='Finalizado'/>
        case '9': return <LabelDefault title='Cancelado'/>
        default: return <LabelDefault title='Indefinido'/>
    }
}

class OSPagaServicosDetalhe extends Component {
   
    render() {
        return(
            <Modal 
                id='os-paga-servico-detalhe' 
                title={
                    'Programação: ' +
                    date_time_loc((this.props.os_servico.dados['dia_prog'] || '') +' '+ (this.props.os_servico.dados['hora_prog'] || '')) + ' | '+
                    this.props.os_servico.dados['agente'] || ''
                } 
                confirmButtonType="hide"
                confirmLabel={this.props.action === "1" ? "Finalizar Item" : "Cancelar Item"}
                onConfirm={()=>{ return true }}
            >
                {os_servico_situacao(this.props.os_servico.dados['sit_servico'], this.props.os_servico.dados['sit_financeiro'], this.props.os_servico.dados['tipo_pag'])}
                <LabelInfo title={' criado em ' + date_time_loc(this.props.os_servico.dados['dh_inc'])} />
                <LabelInfo title={' por ' + this.props.os_servico.dados['login_inc'] || ''} />
                <GroupUnbordered>
                    <GroupItem label="Descrição" value={(this.props.os_servico.dados['descricao'] || '')+' | '+(this.props.os_servico.dados['ref'] || '') }></GroupItem>
                    <GroupItem label="Preço" value={money_loc(this.props.os_servico.dados['preco']||0)}></GroupItem>
                    <GroupItem label="Preço adicional" value={money_loc(this.props.os_servico.dados['preco_adicional']||0)}></GroupItem>
                    <GroupItem label="Custo" value={money_loc(this.props.os_servico.dados['custo']||0)}></GroupItem>
                    <GroupItem label="Custo Adicional Total" value={money_loc(this.props.os_servico.dados['custo_adicional_total'])}></GroupItem>
                    <GroupItem label="Combustível" value={money_loc(this.props.os_servico.dados['combustivel'])}></GroupItem>
                    <GroupItem label="Estacionamento" value={money_loc(this.props.os_servico.dados['estacionamento'])}></GroupItem>
                    <GroupItem label="Lavagem" value={money_loc(this.props.os_servico.dados['lavagem'])}></GroupItem>
                    <GroupItem label="Pedágio" value={money_loc(this.props.os_servico.dados['pedagio'])}></GroupItem>
                    <GroupItem label="Condução" value={money_loc(this.props.os_servico.dados['conducao'])}></GroupItem>
                    <GroupItem label="Alimentação" value={money_loc(this.props.os_servico.dados['alimentacao'])}></GroupItem>
                    <GroupItem label="Outros" value={money_loc(this.props.os_servico.dados['outros'])}></GroupItem>
                </GroupUnbordered>
                <div className="clearfix"></div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    os_servico: state.os_servico
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSPagaServicosDetalhe)

