import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'os-ref'


export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[ dados['field'] ] = dados['value']
    }
    return transmitir(vo, 'get', ROTA+'-list',
        (payload)=> ({ type: act.OS_REF_LISTADO, payload: payload })
    )
}

export const obter = (id_ref) => {
    let vo = []
    vo['id_ref'] = id_ref
    return transmitir(vo, 'get', ROTA,
        (payload)=> ({ type: act.OS_REF_CARREGADO, payload: payload[0] })
    )
}

export const salvar = (dados) => {
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ([
            { type: act.OS_REF_SALVO, payload: payload },
            listar({}),
            limpar()
        ])
    )

}

export const incluir = (dados) => {
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ({ type: act.OS_REF_INCLUIDO, payload: payload })
    )
}

export const limpar = () => {
    return [{type: act.OS_REF_LIMPAR}]
}

export const init = () => {
    return [
        initialize('osrefEditForm', VALORES_INICIAIS)
    ]
}
