import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { convidar } from './solicitante-action'

import Modal from '../common/ui/modal'
import { CalloutInfo } from '../common/msg/callout'

class SolicitanteEnviar extends Component {

    render() {

        return(
            <Modal 
                id='solicitante-convidar' 
                title='Convidar Solicitante' 
                confirmLabel='Convidar'
                confirmButtonType='submit'
                onConfirm={()=>{ this.props.convidar(this.props.solicitante.dados['id_pessoa']); return true}}
                onClose={()=>{return true}}
            >
                <CalloutInfo title='Convite!'>
                    <p>Está rotina irá encaminhar um convite para o e-mail: 
                        <b>{this.props.solicitante.dados['email']}</b>, com uma codigo para que o solicitante(a) 
                        possa acessar o sistema.
                    </p>
                </CalloutInfo>

            </Modal>
        )
    }
    
}

const mapStateToProps = state => ({
    solicitante: state.solicitante
})
const mapDispatchToProps = dispatch => bindActionCreators({convidar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SolicitanteEnviar)

