import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { convidar } from './agente-action'

import Modal from '../common/ui/modal'
import { CalloutInfo } from '../common/msg/callout'

class AgenteEnviar extends Component {

    render() {

        return(
            <Modal 
                id='agente-convidar' 
                title='Convidar Agente' 
                confirmLabel='Convidar'
                confirmButtonType='submit'
                onConfirm={()=>{ this.props.convidar(this.props.agente.dados['id_pessoa']); return true}}
                onClose={()=>{return true}}
            >
                <CalloutInfo title='Convite!'>
                    <p>Está rotina irá encaminhar um convite para o e-mail: 
                        <b>{this.props.agente.dados['email']}</b>, com uma codigo para que o agente 
                        possa acessar o sistema.
                    </p>
                </CalloutInfo>

            </Modal>
        )
    }
    
}

const mapStateToProps = state => ({
    agente: state.agente
})
const mapDispatchToProps = dispatch => bindActionCreators({convidar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AgenteEnviar)

