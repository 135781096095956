import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import { InputTextAreaGroup, InputGroup, InputGroupAutocompleteAddon, SelectGroup, CheckBox, FormRow } from '../common/form'

import { hora_mask } from '../sys/normalize-mask'

import { init, salvar, limpar } from './os-servico-action'
import { obter as obter_os } from '../os/os-action'
import config from '../main/config'

import Modal from '../common/ui/modal'

const validarFormulario = (values) => {
    const erros = {}

    if (!values.dia_prog || values.dia_prog.length === 0)
        erros.dia_prog = 'Informe o dia'

    if (!values.hora_prog || values.hora_prog.length === 0)
        erros.hora_prog = 'Informe o horário'

    if (!values.ref || values.ref.length === 0)
        erros.ref = 'Informe a referencia'

    if (!values.preco || values.preco.length === 0)
        erros.preco = 'Informe o preço'

    if (!values.custo || values.custo.length === 0)
        erros.custo = 'Informe o custo'

    if (!values.agente || values.agente.length === 0)
        erros.agente = 'Informe o agente'

    return erros
}

const selector = formValueSelector('os_servicoAddForm')

class OSServicoAdd extends Component {

    constructor(props) {
        super(props);
        this.props.init()
        this.agente_onSelected = this.agente_onSelected.bind();
        this.ref_onSelected = this.ref_onSelected.bind();
        this.state = { dia_prog: '' };
    }

    onSubmit(values) {
        let dados = {
            id_os: this.props.os.dados['id_os'],
            descricao: values.descricao,
            ref: values.ref,
            tipo: values.tipo,
            dia_prog: values.dia_prog,
            hora_prog: values.hora_prog,
            preco: values.preco,
            custo: values.custo,
            adiantamento_agente: values.adiantamento_agente,
            dia_adiantamento: values.dia_adiantamento,
            doc_adiantamento: values.doc_adiantamento,
            id_agente: values.id_agente,
            placas: values.placas,
            action: this.props.action,
            atedia: values.atedia,
            incluirFDS: (values.incluirFDS ? 1 : 0)
        }
        const bt_conf_modal = document.getElementById("bt_conf_modal")

        bt_conf_modal.disabled = true
        setTimeout(() => bt_conf_modal.disabled = false, 1000)

        this.setState({ descricao: '' })
        this.props.salvar(dados, obter_os)
        this.props.change("id_agente", 0)
        this.props.change("agente", '.')

    }

    agente_onSelected = (item, rest) => {
        if (item) {
            this.props.change("agente", item['nome'] || '')
            this.props.change("id_agente", item['id_pessoa'] || 0)
        }
        rest.clearSelection()
    }

    ref_onSelected = (item, rest) => {
        if (item) {
            this.props.change("descricao", item['ref_desc'] || '')
            this.props.change("ref", item['ref'] || '')
            this.props.change("preco", item['preco'] || '')
            this.props.change("custo", item['custo'] || '')
            this.props.change("tipo", item['tipo'] || 1)
        }
        rest.clearSelection()
    }



    render() {
        const { handleSubmit } = this.props

        return (
            <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                <Modal
                    id='os-servico-add'
                    title="Novo Serviço"
                    confirmLabel='Adicionar'
                    confirmButtonType='submit'
                    onConfirm={() => { return false }}
                    onClose={() => { this.props.limpar();  }}
                >

                    <FormRow>
                        <Field name="dia_prog" placeholder="Dia" type='date' component={InputGroup} cols='md-4' />
                        <Field name="hora_prog" placeholder="Hora" normalize={hora_mask} component={InputGroup} cols='md-2' />
                        <InputGroupAutocompleteAddon
                            name="pesquisa" icon='fa fa-search' placeholder='Referencia'
                            cols='md-12'
                            url={`${config().API_URL}/os-servico-ref-load&dia_prog=${this.props.dia_prog_par}&query=`}
                            onSelected={(item, rest) => this.ref_onSelected(item, rest)}
                            columnShow="ref_desc"
                        />

                    </FormRow>
                    <FormRow>
                        <Field label='Descrição' name="descricao" required rows={2} component={InputTextAreaGroup} maxLength={500} cols='md-12' />
                        <Field label='Preço' name="preco" placeholder="Preço" type="number" component={InputGroup} cols='md-3' />
                        <Field label='Custo' name="custo" placeholder="Custo" type="number" component={InputGroup} cols='md-3' />
                    </FormRow>
                    <div className="clearfix"></div>
                    <FormRow>
                        <InputGroupAutocompleteAddon
                            name="pesquisa" icon='fa fa-search' placeholder='Pesquisa Agente'
                            cols='md-12'
                            url={`${config().API_URL}//agente-load&dia_prog=${this.props.dia_prog_par}&query=`}
                            onSelected={(item, rest) => this.agente_onSelected(item, rest)}
                            columnShow="agente_show"
                        />
                        <Field label='Agente' name="agente" placeholder="Agente" component={InputGroup} cols='md-12' disabled />
                    </FormRow>
                    <div className="clearfix"></div>
                    <FormRow>
                        <Field label='Adiantamento Agente' name="adiantamento_agente" placeholder="Adiantamento" type="number" component={InputGroup} cols='md-4' />
                        <Field label='Dia Adiantamento' name="dia_adiantamento" placeholder="Dia" type="date" component={InputGroup} cols='md-4' />
                        <Field label='Doc. Adiantamento' name="doc_adiantamento" placeholder="Doc" type="text" component={InputGroup} cols='md-4' />
                    </FormRow>
                    <div className="clearfix"></div>
                    <FormRow>
                        <Field label="Placas" name="placas" component={InputGroup} cols='md-12' />
                    </FormRow>
                    <div className="clearfix"></div>

                    <FormRow>
                        <Field label='Ação' name="action" component={SelectGroup} cols='md-4'>
                            <option />
                            <option value="1">Repetir até</option>
                            <option value="2">Repetir 2 vezes</option>
                            <option value="3">Repetir 3 vezes</option>
                            <option value="4">Repetir 4 vezes</option>
                            <option value="5">Repetir 5 vezes</option>
                            <option value="6">Repetir 6 vezes</option>
                            <option value="7">Repetir 7 vezes</option>
                            <option value="8">Repetir 8 vezes</option>
                            <option value="13">Repetir 13 vezes</option>
                            <option value="21">Repetir 21 vezes</option>
                        </Field>
                        {(this.props.action === "1") &&
                            <>
                                <Field label='Até' name="atedia" type='date' component={InputGroup} cols='md-4' />
                                <Field label='Incluir fim de semana' name="incluirFDS" component={CheckBox} cols='md-4' />
                            </>
                        }
                    </FormRow>
                    <div className="clearfix"></div>



                </Modal>
            </form>
        )
    }

}

OSServicoAdd = reduxForm({
    form: 'os_servicoAddForm',
    validate: validarFormulario,
    /*enableReinitialize : true - está causando bug ao entrar na segunda vez no formulário*/
})(OSServicoAdd)


const mapStateToProps = state => ({
    os_servico: state.os_servico,
    os: state.os,
    initialValues: state.os_servico.dados,
    dia_prog_par: selector(state, 'dia_prog'),
    action: selector(state, 'action'),
})
const mapDispatchToProps = dispatch => bindActionCreators({ init, salvar, limpar, obter_os }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSServicoAdd)

