import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field} from 'redux-form'
import { Redirect } from 'react-router-dom'

import { init, obter, salvar, bloquear_togle, cancelar_togle, gerar_codigo } from './agente-action'
import { cel_mask, cpf_mask } from '../sys/normalize-mask'
import { cpf_valid } from '../sys/valid-doc'
import { date_time_loc } from '../sys/normalize-location'

import AgenteConvidar from './agente-convidar'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import { BoxTitle, BoxBody, BoxFooter, Box } from '../common/widget/box'
import { InputGroup, SelectGroup, CheckBox, FormRow } from '../common/form'
import { ButtonInfo } from '../common/ui/buttons'
import { ButtonPrimary, ButtonDanger, ButtonWarning } from '../common/ui/buttons'

import { modal_open } from '../common/ui/modal'

import EnderecoEdit, { endereco_push } from '../pessoa/endereco-edit'
import ContaCorrenteEdit, { conta_corrente_push } from '../pessoa/conta-corrente-edit'
import DocumentosEdit, { documentos_push } from '../pessoa/documentos-edit'

const validarFormulario  = (values) => {
    const erros = {}

    if ( !values.nome || values.nome.length === 0 )
        erros.nome = 'Informe o nome'
    
        if (!values.documento || values.documento.lenght === 0) {
            erros.documento = 'Informe o CPF'
        } else if(!cpf_valid(values.documento)) {
            erros.documento = 'CPF inválido'
        }


    return erros
}


class AgenteEdit extends Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_pessoa'])
    }

    constructor(props) {
        super(props);
        this.props.init()
        this.salvarFormulario = this.salvarFormulario.bind(this)
        this.bloqueia_desbloqueia = this.bloqueia_desbloqueia.bind(this)
        this.cancelar_descancela = this.cancelar_descancela.bind(this)
        this.gerar_codigo = this.gerar_codigo.bind(this)
    }

    onSubmit(values) {

        let classe = []
        if (values.acesso_chofer) classe.push('chofer')  
        if (values.acesso_manobrista) classe.push('manobrista')  
        if (values.acesso_movifrota) classe.push('movifrota')
        if (values.acesso_transporte) classe.push('transporte')
        if (values.acesso_limpeza) classe.push('limpeza')

        let dados = {
            id_pessoa: this.props.agente.dados['id_pessoa'],
            nome: values.nome,
            apelido: values.apelido,
            documento: values.documento,
            celular: values.celular,
            // email: values.email,
            sexo: values.sexo,
            dt_nascimento: values.dt_nascimento,
            uniforme: values.uniforme,
            classe: classe.join(',')
        }

        endereco_push(values, dados)
        conta_corrente_push(values, dados)
        documentos_push(values, dados)
        this.props.salvar(dados)
    }

    salvarFormulario() {
        const { handleSubmit } = this.props
        handleSubmit( values => this.onSubmit(values))
    }

    bloqueia_desbloqueia() {
        this.props.bloquear_togle(this.props.agente.dados['id_pessoa'])
    }

    cancelar_descancela() {
        this.props.cancelar_togle(this.props.agente.dados['id_pessoa'])
    }

    gerar_codigo() {
        this.props.gerar_codigo(this.props.agente.dados['id_pessoa'])
    }

    renderBotoes() {
        const { pristine, submitting } = this.props
        return (
        <Box>
            <BoxFooter>

                {this.props.agente.dados['sit_pessoa'] === '1' && 
                    <ButtonDanger icon="fa fa-lock" align_class="pull-left" onClick={this.bloqueia_desbloqueia} hint="Bloquear" disabled={!pristine && !submitting}></ButtonDanger>
                }
                {this.props.agente.dados['sit_pessoa'] === '1' && 
                    <ButtonDanger icon="fa  fa-thumbs-down" align_class="pull-left" onClick={this.cancelar_descancela} hint="Cancelar" disabled={!pristine && !submitting}></ButtonDanger>
                }
                {this.props.agente.dados['sit_pessoa'] === '2' && 
                    <ButtonWarning icon="fa fa-unlock-alt" align_class="pull-left" onClick={this.bloqueia_desbloqueia} hint="Desbloquear" disabled={!pristine && !submitting}></ButtonWarning>
                }
                {this.props.agente.dados['sit_pessoa'] === '9' && 
                    <ButtonWarning icon="fa  fa-thumbs-up" align_class="pull-left" onClick={this.cancelar_descancela} hint="Descancelar" disabled={!pristine && !submitting}></ButtonWarning>
                }

                <ButtonPrimary icon="fa fa-user-secret" align_class="pull-left" onClick={this.gerar_codigo} hint="Código de Acesso" disabled={!pristine && !submitting}></ButtonPrimary>

                { this.props.agente.dados['codigo_acesso'] && ` Código: ${this.props.agente.dados['codigo_acesso']} ` }
                { this.props.agente.dados['codigo_acesso'] && ` Gerado em: ${date_time_loc(this.props.agente.dados['dh_codigo_acesso'])}  `}  

                {/*<ButtonDanger icon="fa fa-trash-o" align_class="pull-left" onClick={this.excluirAgente} hint="Excluir Agente" disabled={!pristine && !submitting}></ButtonDanger>*/}
                <ButtonInfo type="submit" align_class="pull-right" icon="fa fa-floppy-o" hint="Salvar dados" disabled={pristine || submitting}></ButtonInfo>
                <ButtonInfo icon="fa fa-send" align_class="pull-right" hint="Convidar para portal" onClick={()=>{ modal_open('agente-convidar') }} disabled={!pristine && !submitting}></ButtonInfo>
            </BoxFooter>
        </Box>
        )
    }

    renderFormulario() {
        const { handleSubmit } = this.props

        return(
            <PageSetup title='Agente' subtitle='Atualização'>
                <ContentSection>

                    <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
                    {this.renderBotoes()}
                    <Row>
                        <Grid cols='md-8'>
                            <BoxTitle title="Dados do Agente">
                                <BoxBody>    
                                    <FormRow>
                                        <Field label='Nome' name="nome" placeholder='Nome completo' required component={InputGroup}  cols='md-6' />
                                        <Field label='C.P.F.' name="documento" placeholder='C.P.F.' component={InputGroup} normalize={cpf_mask} cols='md-3' />
                                        <Field label='Sexo' name="sexo" component={SelectGroup}  cols='md-3'>
                                            <option/>
                                            <option value="M">Masculino</option>
                                            <option value="F">Feminino</option>
                                        </Field>
                                        <Field label='Apelido' name="apelido" placeholder='Apelido / Nome Social' required component={InputGroup}  cols='md-4' />
                                        <Field label='Celular' name="celular" placeholder='Celular' component={InputGroup} normalize={cel_mask} cols='md-3' />
                                        <Field label='Nascimento' name="dt_nascimento" type='date' component={InputGroup}  cols='md-3' />
                                        <Field label='Uniforme' name="uniforme" component={SelectGroup}  cols='md-2'>
                                            <option/>
                                            <option value="XP">XP</option>
                                            <option value="P">P</option>
                                            <option value="M">M</option>
                                            <option value="G">G</option>
                                            <option value="GG">GG</option>
                                            <option value="XG">XG</option>
                                        </Field>
                                    </FormRow>
                                </BoxBody>
                            </BoxTitle>

                            </Grid>
                            <Grid cols='md-4'>

                                <BoxTitle title="Papéis">
                                    <BoxBody>    
                                        <FormRow>
                                            <Field label='Chofer' name="acesso_chofer"  component={CheckBox} />
                                            <Field label='Manobrista' name="acesso_manobrista"  component={CheckBox} />
                                            <Field label='Movi.Frota' name="acesso_movifrota"  component={CheckBox} />
                                            <Field label='Transporte' name="acesso_transporte"  component={CheckBox} />
                                            <Field label='Limpeza' name="acesso_limpeza"  component={CheckBox} />
                                        </FormRow>
                                    </BoxBody>
                                </BoxTitle>

                            </Grid>
                        </Row>
                        <ContaCorrenteEdit change={this.props.change}/>
                        <EnderecoEdit change={this.props.change}/>
                        <DocumentosEdit/>
                        <AgenteConvidar/>
                    </form>
                </ContentSection>
            </PageSetup>

        )
    }

    render() {
        if (this.props.agente.upd_status === 3) {
            return <Redirect to={`/agente`} />
        }
        return this.renderFormulario()
    }
}

AgenteEdit = reduxForm({
    form: 'agenteEditForm',
    validate: validarFormulario,
    enableReinitialize : true
})(AgenteEdit)


const mapStateToProps = state => ({
    agente: state.agente,
    initialValues: state.agente.dados
})
const mapDispatchToProps = dispatch => bindActionCreators({init, obter, salvar, bloquear_togle, cancelar_togle, gerar_codigo}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AgenteEdit)

