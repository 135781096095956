import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import CardSecond, {Item} from '../common/widget/card-second'
import { BadgeYellow } from '../common/ui/badge'
import FloatButton from '../common/ui/float-button'
import PessoaAcessoLabels from '../pessoa/pessoa-acesso-labels'

import {listar} from './operador-action'
import config from '../main/config'


import OperadorFilter from './operador-filter'

class Operador extends Component {

    constructor(props) {
        super(props);
        this.props.listar()
    }

    renderOperadores(dados) {
        const img_perfil_defa = config().IMAGE_PATH + 'profile.jpg'
        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        
        return dados.map(vo => {
            const img_perfil = (vo['foto_file']) ? (cdn_endpoint + vo['foto_file']) : (img_perfil_defa)
            return(
                <CardSecond
                    key={'prof' + vo['id_pessoa']} 
                    title={vo['nome']}
                    image={img_perfil}
                    col_class="col-md-6"
                    color_class={vo['sit_pessoa'] ==='1' ? 'bg-aqua-active' : (vo['sit_pessoa'] ==='9' ? 'bg-black' : 'bg-fuchsia')}
                    minHeightHeader={'110px'}
                    route_edit={`/operador-edit/${vo['id_pessoa']}`} 
                    route={`/ficha/${vo['id_pessoa']}`}
                >
                    <Item text='Celular'><BadgeYellow title={vo['celular']}/></Item>
                    <Item text='Email'><BadgeYellow title={vo['email']}/></Item>
                    <Item text='Documento'><BadgeYellow title={vo['documento']}/></Item>
                    <Item>
                        <PessoaAcessoLabels classe={(vo['classe'] || '')} />
                    </Item>
                </CardSecond>
            )
        })
    }

    render() {
        return(
            <PageSetup title='Operadores(as)' subtitle='Lista de Operadores(as)'>
                <ContentSection>
                    
                    <Row>
                        <Grid cols='md-3'>
                            <OperadorFilter/>
                        </Grid>
                        <Grid cols='md-9'>
                            {this.renderOperadores(this.props.operador.lista)}
                        </Grid>
                    </Row>
                </ContentSection>

                <FloatButton
                    route='/operador-add' icon="fa fa-user-plus" tooltip="Novo Operador"
                />

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({operador: state.operador, login: state.login})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Operador)
