import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const ROTA = 'solicitante-cliente'

export const listar = (id_solicitante) => {
    let vo = []
    vo['id_solicitante'] = id_solicitante
    return transmitir(vo, 'get', ROTA+'-list',
        (payload)=> ({ type: act.SOLICITANTE_CLIENTE_LISTADA, payload: payload })
    )
}


export const salvar = (dados) => {
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ([
            listar(dados['id_solicitante'])
        ])
    )
}

export const remover = (dados) => {
    let vo = []
    vo['id_solicitante'] = dados['id_solicitante'] || 0
    vo['id_cliente'] = dados['id_cliente'] || 0
    return transmitir(vo, 'delete', ROTA, 
        (payload)=> ([
            listar(dados['id_solicitante'])
        ])
    )
}


export const reservar = (dados) => {
    return Promise.resolve().then((payload) => ({ type: act.SOLICITANTE_CLIENTE_RESERVA, payload: dados }))
}
