import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'

import { FormRow, Input, SelectGroup } from '../common/form'
import { BoxSimple } from '../common/widget/box'

import { listar } from '../os-servico/os-servico-action'

class OSServicoFilter extends Component {

    onSubmit(values) {
        let dados = {
            id_os: this.props.os?.dados['id_os'],
            field: values.pesqcampo,
            value: values.pesqvalor,
            sit_servico: values.sit_servico
        }
        this.props.listar(dados)
    }


    render() {

        const { handleSubmit } = this.props

        return (
            <BoxSimple>
                <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                    <FormRow>
                        <Field name="sit_servico" component={SelectGroup} defaultValue="" cols='md-2'>
                            <option value="">Todos</option>
                            <option value="1">Programados</option>
                            <option value="7">Finalizado</option>
                            <option value="9">Cancelados</option>
                        </Field>


                        <Field name="pesqcampo" component={SelectGroup} defaultValue="agente" cols='md-2'>
                            <option value="agente">Agente</option>
                            <option value="placa">Placa</option>
                        </Field>

                        <div className="input-group input-group-sm">
                            <Field name="pesqvalor" component={Input} className="form-control" />
                            <span className="input-group-btn">
                                <button type="submit" className="btn btn-info btn-flat">GO!</button>
                            </span>
                        </div>
                    </FormRow>
                </form>
            </BoxSimple>

        )
    }

}

OSServicoFilter = reduxForm({
    form: 'osServicoFilterForm',
    initialValues: { pesqcampo: 'agente' }
})(OSServicoFilter)

const mapStateToProps = state => ({ os: state.os })
const mapDispatchToProps = dispatch => bindActionCreators({ listar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSServicoFilter)
