import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from '../home/home'
import PerfilShow from '../perfil/perfil-show'
import FichaShow from '../ficha/ficha-show'

import Operador from '../operador/operador'
import OperadorAdd from '../operador/operador-add'
import OperadorEdit from '../operador/operador-edit'

import Solicitante from '../solicitante/solicitante'
import SolicitanteAdd from '../solicitante/solicitante-add'
import SolicitanteEdit from '../solicitante/solicitante-edit'
import SolicitanteShow from '../solicitante/solicitante-show'

import Cliente from '../cliente/cliente'
import ClienteAdd from '../cliente/cliente-add'
import ClienteEdit from '../cliente/cliente-edit'

import OS from '../os/os'
import OSAdd from '../os/os-add'
import OSEdit from '../os/os-edit'
import OSFichaShow from '../os/os-ficha-show'

import OSAprovadas from '../os-aprovadas/os-aprovadas'

import OSRef from '../os-ref/os-ref'

import OSFatura from '../os-fatura/os-fatura'
import OSFaturaAdd from '../os-fatura/os-fatura-add'
import OSFaturaFichaShow from '../os-fatura/os-fatura-ficha-show'

import OSPaga from '../os-paga/os-paga'
import OSPagaAdd from '../os-paga/os-paga-add'
import OSPagaFichaShow from '../os-paga/os-paga-ficha-show'

import Agente from '../agente/agente'
import AgenteAdd from '../agente/agente-add'
import AgenteEdit from '../agente/agente-edit'

import Radar from '../radar/radar'
import LoteCnab from '../lote-cnab/lote-cnab'
import LoteCnabFichaShow from '../lote-cnab/lote-cnab-ficha-show'

export default props => (

    <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/home' component={Home} />
        <Route exact path='/perfil-show' component={PerfilShow} />
        <Route exact path='/ficha/:id_pessoa' component={FichaShow} />

        <Route exact path='/operador' component={Operador} />
        <Route exact path='/operador-edit/:id_pessoa' component={OperadorEdit} />
        <Route exact path='/operador-add' component={OperadorAdd} />

        <Route exact path='/solicitante' component={Solicitante} />
        <Route exact path='/solicitante-edit/:id_pessoa' component={SolicitanteEdit} />
        <Route exact path='/solicitante-add' component={SolicitanteAdd} />
        <Route exact path='/solicitante/:id_pessoa' component={SolicitanteShow} />

        <Route exact path='/agente' component={Agente} />
        <Route exact path='/agente-edit/:id_pessoa' component={AgenteEdit} />
        <Route exact path='/agente-add' component={AgenteAdd} />

        <Route exact path='/cliente' component={Cliente} />
        <Route exact path='/cliente-edit/:id_pessoa' component={ClienteEdit} />
        <Route exact path='/cliente-add' component={ClienteAdd} />

        <Route exact path='/missao' component={OS} />
        <Route exact path='/missao-edit/:id_os' component={OSEdit} />
        <Route exact path='/missao-add' component={OSAdd} />
        <Route exact path='/missao-ficha/:id_os' component={OSFichaShow} />
        <Route exact path='/missao/:act' component={OS} />

        <Route exact path='/aprovadas' component={OSAprovadas} />
        <Route exact path='/radar' component={Radar} />
        <Route exact path='/radar/:act' component={Radar} />

        <Route exact path='/fatura' component={OSFatura} />
        <Route exact path='/fatura-add' component={OSFaturaAdd} />
        <Route exact path='/fatura-ficha/:id_fatura' component={OSFaturaFichaShow} />

        <Route exact path='/paga' component={OSPaga} />
        <Route exact path='/paga-add' component={OSPagaAdd} />
        <Route exact path='/paga-ficha/:id_paga' component={OSPagaFichaShow} />

        <Route exact path='/cnab' component={LoteCnab} />
        <Route exact path='/cnab-ficha/:id_lote' component={LoteCnabFichaShow} />


        <Route exact path='/os-ref' component={OSRef} />

        <Redirect from='*' to="/" />
    </Switch>

)
