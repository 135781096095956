import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { FormRow, SelectGroup, InputTextAreaGroup, InputGroup } from '../common/form'

import { negociar_os } from './os-action'

import Modal from '../common/ui/modal'

const validarFormulario = (values) => {
    const erros = {}

    if (!values.sit_negociacao)
        erros.nome = 'Informe a situação da Negociação'

    return erros
}

class OSNegociacao extends Component {

    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        let dados = {
            id_os: this.props.os.dados['id_os'],
            comentario: values.comentario,
            dt_contatar: values.dt_contatar
        }
        this.props.negociar_os(dados)
    }

    handleStateChange = () => {
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                <Modal
                    id='os-negociacao'
                    title="Negociação"
                    confirmLabel='Confirmar'
                    confirmButtonType='submit'
                    onConfirm={() => { return true }}
                    onClose={() => { this.props.limpar() }}
                >

                    <FormRow>
                        <Field label='Situacao' name="sit_negociacao" component={SelectGroup} cols='md-12'>
                            <option />
                            <option value="10">Negociação Pendente</option>
                            <option value="15">Negociação Quente</option>
                            <option value="20">Negociação Fria</option>
                            <option value="25">Negociação Fechada</option>
                            <option value="50">Perdida por preço</option>
                            <option value="55">Perdida por falta de recurso</option>
                            <option value="60">Perdida por falta de agenda</option>
                            <option value="70">Perdida por desistência do cliente</option>
                        </Field>
                        <Field label='Comentário' name="comentario" required rows={2} component={InputTextAreaGroup} maxLength={250} cols='md-12' />
                        <Field label='Próximo Contato' name="dt_contatar" type='date' required component={InputGroup} cols='md-6' />
                    </FormRow>
                    <div className="clearfix"></div>

                </Modal>
            </form>
        )
    }

}

OSNegociacao = reduxForm({
    form: 'os_negociacaoForm',
    validate: validarFormulario,
    enableReinitialize: true
})(OSNegociacao)


const mapStateToProps = state => ({
    os_servico: state.os_servico,
    os: state.os,
    initialValues: state.os_servico.dados
})
const mapDispatchToProps = dispatch => bindActionCreators({ negociar_os }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSNegociacao)

