import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'agente'


export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[ dados['field'] ] = dados['value']
    }
    return transmitir(vo, 'get', 'agente-list',
        (payload)=> ({ type: act.AGENTE_LISTADO, payload: payload })
    )
}


export const obter = (id_pessoa) => {
    let vo = []
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'get', ROTA,
        (payload)=> ({ type: act.AGENTE_CARREGADO, payload: payload[0] })
    )
}

export const salvar = (dados) => {
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ({ type: act.AGENTE_SALVO, payload: payload })
    )
}

export const convidar = (id_agente) => {
    let vo = {}
    vo['id_pessoa'] = id_agente
    return transmitir(vo, 'post', ROTA+'-convite', 
        (payload)=> ({ type: act.AGENTE_CONVIDADO, payload: payload })
    )
}


export const incluir = (dados) => {
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ({ type: act.AGENTE_INCLUIDO, payload: payload })
    )
}


export const bloquear_togle = (id_pessoa) => {
    let vo = {}
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'post', ROTA + '-bloqueio', 
        (payload)=> ({ type: act.AGENTE_SALVO, payload: payload })
    )
}

export const cancelar_togle = (id_pessoa) => {
    let vo = {}
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'post', ROTA + '-cancelamento', 
        (payload)=> ({ type: act.AGENTE_SALVO, payload: payload })
    )
}

export const gerar_codigo = (id_pessoa) => {
    let vo = []
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'get', ROTA + '-codigo',
        (payload)=> ({ type: act.AGENTE_CARREGADO, payload: payload[0] })
    )
}

export const pesquisar = (dados) => {
    let vo = []
    vo['nome'] = dados['nome']
    vo['documento'] = dados['documento']
    vo['celular'] = dados['celular']
    vo['email'] = dados['email']
    return transmitir(vo, 'get', ROTA, (payload)=> ({ type: act.AGENTE_PESQUISADO, payload: payload[0] }))
}


export const init = () => {
    return [
        initialize('agenteEditForm', VALORES_INICIAIS)
    ]
}
