import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'cliente'


export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[ dados['field'] ] = dados['value']
    }
    if (dados && dados['sit_pessoa']) {
        vo['sit_pessoa'] = dados['sit_pessoa']
    }
    return transmitir(vo, 'get', 'cliente-list',
        (payload)=> ({ type: act.CLIENTE_LISTADO, payload: payload })
    )
}


export const obter = (id_pessoa) => {
    let vo = []
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'get', ROTA,
        (payload)=> ({ type: act.CLIENTE_CARREGADO, payload: payload[0] })
    )
}

export const salvar = (dados) => {
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ({ type: act.CLIENTE_SALVO, payload: payload })
    )
}

export const incluir = (dados) => {
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ({ type: act.CLIENTE_INCLUIDO, payload: payload })
    )
}

export const bloquear_togle = (id_pessoa) => {
    let vo = {}
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'post', ROTA + '-bloqueio', 
        (payload)=> ({ type: act.CLIENTE_SALVO, payload: payload })
    )
}

export const cancelar_togle = (id_pessoa) => {
    let vo = {}
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'post', ROTA + '-cancelamento', 
        (payload)=> ({ type: act.CLIENTE_SALVO, payload: payload })
    )
}

export const pesquisar = (dados) => {
    let vo = []
    if (dados['nome']) vo['nome'] = dados['nome']
    if (dados['documento']) vo['documento'] = dados['documento']
    if (dados['celular']) vo['celular'] = dados['celular']
    if (dados['email']) vo['email'] = dados['email']
    return transmitir(vo, 'get', ROTA, (payload)=> ({ type: act.CLIENTE_PESQUISADO, payload: payload[0] }))
}


export const init = () => {
    return [
        initialize('clienteEditForm', VALORES_INICIAIS)
    ]
}


export const gerar_codigo = (id_pessoa) => {
    let vo = []
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'get', ROTA + '-codigo',
        (payload)=> ({ type: act.CLIENTE_CARREGADO, payload: payload[0] })
    )
}

