import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { obter_timeline } from './os-action'
import { date_time_loc } from '../sys/normalize-location'

import config from '../main/config'

class OsTimeLine extends Component {

    render_timeline(list) {
        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        const img_profile = (config().IMAGE_PATH + 'profile.jpg')
        return list?.map(vo => {
            const img_perfil = list['foto_file'] ? (cdn_endpoint + list['foto_file']) : img_profile
            return (
                <div className="box-comment" key={vo['id_tl']}>
                    <img className="img-circle img-sm" src={img_perfil} alt="Usuário" />
                    <div className="comment-text">
                        <span className="username">
                            {vo['nome'] || vo['login_inc']}
                            <span className="text-muted pull-right">{date_time_loc(vo['dh_inc'])}</span>
                        </span>
                        {vo['msg']}
                    </div>
                </div>
            )
        }
        )
    }
    render() {
        return (
            <div className="box-footer box-comments">
                {this.render_timeline(this.props.os.timeline)}

            </div>
        )
    }

}

const mapStateToProps = state => ({
    os: state.os,
    login: state.login,
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter_timeline }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OsTimeLine)

