import axios from 'axios'

import { setCookie } from '../common/helper'
import act from '../main/action-const'
import config from '../main/config'

export const login = (values) => {
    const payload = {
        'login': values['usuario'],
        'senha': values['senha']
    }

    return dispatch => (
        axios.post(`${config().API_URL}/auth`,  payload)
            .then(resp => {
                const data = {
                    nome:  payload['usuario'],
                    token: resp.data['acess_token'],
                    classe: resp.data['classe'],
                    recuperar: resp.data['recuperar'],
                    status: resp.data['status'],
                    username: resp.data['username'],
                    cdn_endpoint: resp.data['cdn_endpoint'],
                    fotofile: resp.data['foto_file'],
                    dtlogin: resp.data['dtlogin']
                }
                dispatch([
                    { type: act.USUARIO_CARREGADO, payload: data }
                ])
            })
            .catch(e => {
                dispatch([
                    { type: act.USUARIO_INVALIDO }
                ])
            })
    )
}

export const logout = () => {

    return dispatch => (
        axios.delete(`${config().API_URL}/auth`)
            .then(resp => {
                dispatch([
                    { type: act.TOKEN_VALIDADO, payload: false }
                ])
            })
            .catch(e => {
                dispatch([
                    { type: act.TOKEN_VALIDADO, payload: false }
                ])                
            })
    )
}

export const validarToken = (token) => {
    return dispatch => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            axios.post(`${config().API_URL}/token`)
                .then(resp => {
                    dispatch({ type: act.TOKEN_VALIDADO, payload: true })
                })
                .catch(e => dispatch({ type: act.TOKEN_VALIDADO, payload: false }))

        } else {
            dispatch({ type: act.TOKEN_VALIDADO, payload: false})
        }

    }
}


export const change_password = (values) => {
    const payload = {
        'nova_senha': values['nova_senha'],
        'confirma_senha': values['confirma_senha']
    }

    return dispatch => (
        axios.post(`${config().API_URL}/auth-change`,  payload)
            .then(resp => {
                dispatch([
                    { type: act.TOKEN_VALIDADO, payload: false }
                ])
            })
            .catch(e => {
                let msg = []
                if(e.response && e.response.data && e.response.data['message'])
                    msg = Array.isArray(e.response.data['message']) ? e.response.data['message'] : [e.response.data['message']]
                
                dispatch([
                    { type: act.USUARIO_TROCA_INVALIDA, payload: msg[0] }
                ])
            })
    )

}

export const go_esqueci = (value) => {
    return dispatch => (
        dispatch([
            { type: act.GO_LOGIN_ESQUECI, payload: value }
        ])
    )

}


export const go_pwd_change = () => {
    return dispatch => (
        dispatch([
            { type: act.GO_USER_STATUS_CHANGE, payload: 5 }
        ])
    )
}

export const out_pwd_change = () => {
    return dispatch => (
        dispatch([
            { type: act.GO_USER_STATUS_CHANGE, payload: 1 }
        ])
    )
}



export const recover_password = (values) => {
    const payload = {
        'email': values['email'],
    }

    return dispatch => (
        axios.post(`${config().API_URL}/auth-recover`,  payload)
            .then(resp => {
                dispatch([
                    { type: act.GO_LOGIN_ESQUECI, payload: 2 }
                ])
            })
            .catch(e => {
                dispatch([
                    { type: act.GO_LOGIN_ESQUECI, payload: 3 }
                ])
            })
    )

}


export const agree = () => {
    setCookie('__agree_terms__', 1, 90)
    return dispatch => (
        dispatch([
            { type: act.GO_AGREE, payload: 1 }
        ])
    )
}

export const agree_lgpd = () => {
    setCookie('__agree_lgpd__', 1, 90)
    return dispatch => (
        dispatch([
            { type: act.GO_AGREE_LGPD, payload: 1 }
        ])
    )
}
