import React from 'react'

import SimpleTable from '../common/tables/simple-table'
import { modal_open } from '../common/ui/modal'

import { Anchor } from '../common/helper'
import { BadgeGreen, BadgeYellow, BadgeRed } from '../common/ui/badge'

import { date_time_loc, money_loc } from '../sys/normalize-location'

import { os_servico_situacao } from '../os-servico/os-servico-labels'


const os_servico_detalhe_link = (obterDetalhe, limpar, row) => {
    return (
        <Anchor onClick={() => { limpar(); obterDetalhe(row['id_servico'] || 0); modal_open('os-servico-detalhe') }}> {date_time_loc(row['dia_prog'] + ' ' + row['hora_prog']) || ''} </Anchor>
    )
}

const os_servico_edit_link = (obterDetalhe, limpar, row) => {
    return (
        <Anchor onClick={() => { limpar(); obterDetalhe(row['id_servico'] || 0); modal_open('os-servico-edit') }}> {'INDEFINIDO'} </Anchor>
    )
}


const agente = (row) => {
    if (row['sit_servico'] !== "9" && row['qtd_servico_agente'] > "2") return <>{row['agente']} <BadgeRed title={row['qtd_servico_agente']} /> </>
    if (row['sit_servico'] !== "9" && row['qtd_servico_agente'] > "1") return <>{row['agente']} <BadgeYellow title={row['qtd_servico_agente']} /></>
    if (row['sit_servico'] !== "9" && row['qtd_servico_agente'] === "1") return <>{row['agente']} <BadgeGreen title={row['qtd_servico_agente']} /></>
    return <>{row['agente']}</>
}

const servico = (row) => {

    if (row['sit_servico'] !== "9" && row['qtd_servico_ref'] > "2") return <>{row['servico']} <BadgeRed title={row['qtd_servico_ref']} /> </>
    if (row['sit_servico'] !== "9" && row['qtd_servico_ref'] > "1") return <>{row['servico']} <BadgeYellow title={row['qtd_servico_ref']} /></>
    if (row['sit_servico'] !== "9" && row['qtd_servico_ref'] === "1") return <>{row['servico']} <BadgeGreen title={row['qtd_servico_ref']} /></>
    return <>{row['servico'] + ' ' + (row['placas'] ?? '')}</>
}


export default props => (
    <SimpleTable
        title="Serviços"
        columns={[
            { name: 'dia_prog', title: 'Dia', value: ((row) => { return os_servico_detalhe_link(props.obterDetalhe, props.limpar, row) }) },
            { name: 'sit_servico', title: 'Situação', value: ((row) => { return os_servico_situacao(row['sit_servico'], row['sit_financeiro'], row['tipo_pag']) }) },
            { name: 'servico', title: 'Descricao/Placas', value: ((row) => { return servico(row) }) },
            { name: 'preco', title: 'Preço', value: ((row) => { return money_loc(row['preco']) }) },
            { name: 'tipo_pag', title: 'Pag', value: ((row) => { return row['tipo_pag'] === '1' ? '(Banco)' : '(Caixa)' }) },
            { name: 'custo', title: 'Custo', value: ((row) => { return money_loc(row['custo']) }) },
            { name: 'custo_adicional_total', title: 'Adicional', value: ((row) => { return money_loc(row['custo_adicional_total']) }) },
            {
                name: 'agente', title: 'Agente', value: ((row) => {
                    return (row['id_agente'] !== '-99' || row['sit_servico'] === "9") ? agente(row) : os_servico_edit_link(props.obterDetalhe, props.limpar, row)
                })
            },
        ]}
        data={props.dados}
        row_key={(row) => ('os_servico' + row['id_servico'])}
    >
    </SimpleTable>
)
