import React from 'react'

import SimpleTable from '../common/tables/simple-table'

import { modal_open } from '../common/ui/modal'
import { Anchor } from '../common/helper'
import { date_time_loc } from '../sys/normalize-location'

const remover_link = (row, reservar) => {
        return (
        <Anchor onClick={() => {reservar(row); modal_open('solicitante-cliente-remover')}}> 
            {row['nome']} 
        </Anchor>
    )
}


export default  props => (
    <SimpleTable
        title="Clientes"
        columns={[
            { name: 'nome', title: 'Nome', value: ((row)=>{return remover_link(row, props.reservar)})},
            { name: 'documento', title: 'Documento'},
            { name: 'dh_inc', title: 'Inclusão', value: ((row)=>{return date_time_loc(row['dh_inc'])}) },
            { name: 'login_inc', title: 'resp.'}
        ]}
        data={props.dados}
        row_key={(row) => ('cliente' + row['id_cliente'])}
    >
    </SimpleTable>
)
