import act from '../main/action-const'

const INITIAL_STATE = {
    dados: {},
    lista: [],
    simula: [],
    servico: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
    page: 1,
    tem_mais: false
}

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.OS_FATURA_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0
            }

        case act.OS_FATURA_SERVICO_LISTADO:
            return {
                ...state, servico: action.payload,
                page: 1,
                tem_mais: action.payload.length > 0
            }


        case act.OS_FATURA_SERVICO_LISTADO_MAIS:
            return {
                ...state, servico: [...state.servico, ...action.payload],
                tem_mais: action.payload.length > 0
            }

        case act.OS_FATURA_SERVICO_LISTADO_ADD_PAGE:
            return {
                ...state, page: action.payload
            }

        case act.OS_FATURA_CARREGADO:
            return { ...state, dados: action.payload, upd_status: 4 }

        case act.OS_FATURA_SALVO:
            return {
                ...state, dados: action.payload,
                upd_status: 3
            }

        case act.OS_FATURA_INCLUIDO:
            return {
                ...state, dados: action.payload,
                upd_status: 5
            }

        case act.OS_FATURA_SIMULADO:
            return {
                ...state,
                simula: action.payload,
                upd_status: (typeof (action.payload) === 'object' && action.payload['id_cliente']) ? 1 : 2
            }


        default:
            return state
    }
}
