import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import { InputGroup, InputGroupAutocompleteAddon, FormRow } from '../common/form'

import { hora_mask } from '../sys/normalize-mask'

import { init, convidar, limpar} from './os-servico-action'
import { obter as obter_os} from '../os/os-action'
import config from '../main/config'

import Modal from '../common/ui/modal'

const validarFormulario  = (values) => {
    const erros = {}

    if ( !values.dia_prog || values.dia_prog.length === 0 )
        erros.nome = 'Informe a data programada'

    if ( !values.hora_prog || values.hora_prog.length === 0 )
        erros.nome = 'Informe a data programada'


    if ( !values.regiao || values.regiao.length === 0 )
        erros.nome = 'Informe a Região'

    return erros
}

const selector = formValueSelector('os_servicoConviteForm')

class OSServicoConvite extends Component {

    constructor(props) {
        super(props);
        this.props.init()
        this.ref_onSelected = this.ref_onSelected.bind();
        this.handleStateChange = this.handleStateChange.bind();
        this.state = { dia_prog: '' };
    }

    onSubmit(values) {
        let dados = {
            id_os: this.props.os.dados['id_os'],
            regiao: values.regiao,
            quantidade: values.quantidade,
            ref: values.ref,
            descricao: values.descricao,
            tipo: values.tipo,
            dia_prog: values.dia_prog,
            hora_prog: values.hora_prog,
            preco: values.preco,
            custo: values.custo,
            tempo: values.tempo
        }
        this.setState({descricao:'' })
        this.props.convidar(dados, obter_os)
    }

    handleStateChange = () => {
    }

    ref_onSelected = (item, rest) => {
        if (item) {
            this.props.change("descricao", item['descricao'] || '')
            this.props.change("ref", item['ref'] || '')
            this.props.change("ref_desc", item['ref_desc'] || '')
            this.props.change("preco", item['preco'] || '')
            this.props.change("custo", item['custo'] || '')
            this.props.change("tipo", item['tipo'] || 1)
        }
        rest.clearSelection()
    }



    render() {
        const { handleSubmit } = this.props

        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
            <Modal 
                id='os-servico-convite' 
                title="Convite" 
                confirmLabel='Convidar'
                confirmButtonType='submit'
                onConfirm={()=>{ return true }}
                onClose={()=>{this.props.limpar()}}
            >

            <FormRow>
                <Field name="dia_prog" placeholder="Dia" type='date' component={InputGroup}  cols='md-4' />
                <Field name="hora_prog" placeholder="Hora" normalize={hora_mask} component={InputGroup}  cols='md-2' />
                <InputGroupAutocompleteAddon
                    name="pesquisa" icon='fa fa-search' placeholder='Referencia' 
                    cols='md-12'
                    url={`${config().API_URL}/os-servico-ref-load&dia_prog=${this.props.dia_prog_par}&query=`}
                    onSelected={(item,rest) => this.ref_onSelected(item,rest)}
                    columnShow="ref_desc"
                />

            </FormRow>
            <FormRow>
                <Field label='Serviço' name="ref_desc" placeholder="Descrição" component={InputGroup}  cols='md-12' disabled/>
                <Field label='Preço' name="preco" placeholder="Preço" type="number" component={InputGroup}  cols='md-3' />
                <Field label='Custo' name="custo" placeholder="Custo" type="number" component={InputGroup}  cols='md-3' />
            </FormRow>

            <div className="clearfix"></div>
            <FormRow>
                <Field label='Região' name="regiao" placeholder="Região" component={InputGroup}  cols='md-3' />
                <Field label='Qtd.Convites' name="quantidade" type="number" component={InputGroup}  cols='md-3' />
                <Field label='Validade em Horas' name="tempo" type="number" component={InputGroup}  cols='md-4' />
            </FormRow>

            <div className="clearfix"></div>

            </Modal>
            </form>
        )
    }

}

OSServicoConvite = reduxForm({
    form: 'os_servicoConviteForm',
    validate: validarFormulario,
    enableReinitialize : true
})(OSServicoConvite)


const mapStateToProps = state => ({
    os_servico: state.os_servico,
    os: state.os,
    initialValues: state.os_servico.dados,
    dia_prog_par: selector(state, 'dia_prog'),
})
const mapDispatchToProps = dispatch => bindActionCreators({init, convidar, limpar, obter_os}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSServicoConvite)

