import { initialize } from 'redux-form'
import { transmitir, download, recuperar_dados_sync } from '../sys/transmitir'
import { toastr } from 'react-redux-toastr'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'os'


export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[dados['field']] = dados['value']
    }
    if (dados && dados['sit_os']) {
        vo['sit_os'] = dados['sit_os']
    }
    if (dados && dados['sit_negociacao']) {
        vo['sit_negociacao'] = dados['sit_negociacao']
    }
    if (dados && dados['dia']) {
        vo['dia'] = dados['dia']
    }
    if (dados && dados['ate']) {
        vo['ate'] = dados['ate']
    }
    if (dados && dados['periodo_tipo']) {
        vo['periodo_tipo'] = dados['periodo_tipo']
    }
    if (dados && dados['ordem']) {
        vo['ordem'] = dados['ordem']
    }
    if (dados && dados['modalidade']) {
        vo['modalidade'] = dados['modalidade']
    }

    if (dados && dados['origem']) {
        vo['origem'] = dados['origem']
    }

    return transmitir(vo, 'get', 'os-list',
        (payload) => ([
            { type: act.OS_LISTADO_SAVE_FILTRO, payload: vo },
            { type: act.OS_LISTADO, payload: payload }
        ])
    )
}

export const listar_mais = (page, filtro) => {
    filtro['page'] = page;
    return transmitir(filtro, 'get', 'os-list',
        (payload) => ([
            { type: act.OS_LISTADO_MAIS, payload: payload },
            { type: act.OS_LISTADO_ADD_PAGE, payload: page }
        ])
    )
}


export const obter = (id_os) => {
    let vo = []
    vo['id_os'] = id_os
    return transmitir(vo, 'get', ROTA,
        (payload) => ({ type: act.OS_CARREGADO, payload: payload[0] })
    )
}

export const gerar_doc = (id_os) => {
    let vo = []
    vo['id_os'] = id_os
    return transmitir(vo, 'get', ROTA + '-gerar-doc',
        (payload) => ({ type: act.OS_NOTHING, payload: payload[0] })
    )
}

export const download_os = (id_os, os_numero) => {
    let vo = []
    vo['id_os'] = id_os

    recuperar_dados_sync(vo, 'get', ROTA + '-download-os-ok').then(ret => {
        if (ret['ok']) {

            toastr.info('Pronto', ret['message'])
            let vo_download = {}
            vo_download['id_os'] = id_os
        
            return download(vo_download, ROTA + '-download-os', ret['filename'])
        }
        if (ret['message']) {
            return toastr.warning('Ops!', ret['message'])
        }
        return toastr.warning('Ops!', 'algo errado ocorreu. Tente novamente.')

    });
}

export const gerar_orc = (id_os) => {
    let vo = []
    vo['id_os'] = id_os
    return transmitir(vo, 'get', ROTA + '-gerar-orc',
        (payload) => ({ type: act.OS_NOTHING, payload: payload[0] })
    )
}

export const download_orc = (id_os) => {
    let vo = []
    vo['id_os'] = id_os

    recuperar_dados_sync(vo, 'get', ROTA + '-download-orc-ok').then(ret => {
        if (ret['ok']) {

            toastr.info('Pronto', ret['message'])
            let vo_download = {}
            vo_download['id_os'] = id_os
        
            return download(vo_download, ROTA + '-download-orc', ret['filename'])
        }
        if (ret['message']) {
            return toastr.warning('Ops!', ret['message'])
        }
        return toastr.warning('Ops!', 'algo errado ocorreu. Tente novamente.')

    });
}

export const obter_timeline = (id_os) => {
    let vo = []
    vo['id_os'] = id_os
    return transmitir(vo, 'get', ROTA + '-timeline',
        (payload) => ({ type: act.OS_TIMELINE_CARREGADO, payload: payload })
    )
}

export const salvar = (dados) => {
    return transmitir(dados, 'post', ROTA,
        (payload) => ({ type: act.OS_SALVO, payload: payload })
    )
}

export const incluir = (dados) => {
    return transmitir(dados, 'post', ROTA,
        (payload) => ({ type: act.OS_INCLUIDO, payload: payload })
    )
}

export const colocar_em_tramite = (id_os) => {
    let vo = {}
    vo['id_os'] = id_os
    return transmitir(vo, 'post', ROTA + '-colocar-em-tramite',
        (payload) => ([
            { type: act.OS_SALVO, payload: payload },
            obter(id_os),
            obter_timeline(id_os),
        ])
    )
}

export const solicitar_autorizacao = (id_os) => {
    let vo = {}
    vo['id_os'] = id_os
    return transmitir(vo, 'post', ROTA + '-solicitar-autorizacao',
        (payload) => ([
            { type: act.OS_SALVO, payload: payload },
            obter(id_os),
            obter_timeline(id_os),
        ])
    )
}

export const cancelar_os = (id_os) => {
    let vo = {}
    vo['id_os'] = id_os
    return transmitir(vo, 'post', ROTA + '-cancelar',
        (payload) => ([
            { type: act.OS_SALVO, payload: payload },
            obter(id_os),
            obter_timeline(id_os),
        ])
    )
}

export const negociar_os = (dados) => {
    let vo = {}
    vo['id_os'] = dados['id_os']
    vo['sit_negociacao'] = dados['sit_negociacao']
    vo['comentario'] = dados['comentario']
    vo['dt_contatar'] = dados['dt_contatar']
    return transmitir(vo, 'post', ROTA + '-negociar',
        (payload) => ([
            { type: act.OS_SALVO, payload: payload },
            obter(vo['id_os']),
            obter_timeline(vo['id_os']),
        ])
    )
}

export const anotar_os = (dados) => {
    let vo = {}
    vo['id_os'] = dados['id_os']
    vo['comentario'] = dados['comentario']
    return transmitir(vo, 'post', ROTA + '-anotar',
        (payload) => ([
            { type: act.OS_SALVO, payload: payload },
            obter(vo['id_os']),
            obter_timeline(vo['id_os']),
        ])
    )
}


export const pesquisar = (dados) => {
    let vo = []
    if (dados['cliente']) vo['nome'] = dados['cliente']
    if (dados['contato'] && !vo['nome']) vo['nome'] = vo['contato']
    if (dados['documento']) vo['documento'] = dados['documento']
    if (dados['celular']) vo['celular'] = dados['celular']
    if (dados['email']) vo['email'] = dados['email']
    return transmitir(vo, 'get', ROTA + '-cliente-existente', (payload) => ({ type: act.OS_PESQUISADO, payload: payload[0] }))
}


export const init = () => {
    return [
        initialize('osEditForm', VALORES_INICIAIS)
    ]
}


export const obterContadores = () => {
    let vo = []
    return transmitir(vo, 'get', 'os-contadores',
        (payload)=> ({ type: act.OS_CONTADOR_CARREGADO, payload: payload || {} })
    )
}
