import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import CardSecond, {Item} from '../common/widget/card-second'
import {BadgeYellow, BadgeBlue} from '../common/ui/badge'
import FloatButton from '../common/ui/float-button'

import { date_loc, money_loc } from '../sys/normalize-location'

import {listar} from './os-fatura-action'
import config from '../main/config'


import OSFaturaFilter from './os-fatura-filter'

class OSFatura extends Component {

    constructor(props) {
        super(props);
        this.props.listar()
    }

    renderOSFatura(dados) {
        const img_perfil_defa = config().IMAGE_PATH + 'fatura.png'

        return dados.map(vo => {
            const img_perfil =  (img_perfil_defa)
            return(
                <CardSecond
                    key={'os_fat' + vo['id_fatura']} 
                    title={vo['cliente']}
                    image={img_perfil}
                    col_class="col-md-6"
                    color_class={vo['sit_fatura'] ==='3' ? 'bg-aqua' : (vo['sit_fatura'] ==='9' ? 'bg-black' : 'bg-fuchsia')}
                    minHeightHeader={'110px'}
                    route={`/fatura-ficha/${vo['id_fatura']}`}
                >
                    <Item text='Período'> <BadgeBlue title={vo['sit_fatura_label']}/> <BadgeYellow title={'Inicio | '+date_loc(vo['dia_inicio'])}/><BadgeYellow title={'Final | '+date_loc(vo['dia_final'])}/> </Item>
                    <Item text='Referência'> <BadgeYellow title={vo['documento']}/> <BadgeYellow title={vo['referencia']}/></Item>
                    <Item text="Valores"> 
                        <BadgeYellow title={'Custo | '+money_loc(vo['custo']||0)}/>
                        <BadgeYellow title={'Preço adicional | '+money_loc(vo['preco_adicional']||0)}/>
                        <BadgeYellow title={'Preço | '+money_loc(vo['preco']||0)}/>                        
                    </Item>
                </CardSecond>
            )
        })
    }

    render() {
        return(
            <PageSetup title='Faturamento' subtitle='Consulta'>
                <ContentSection>
                    
                    <Row>
                        <Grid cols='md-3'>
                            <OSFaturaFilter/>
                        </Grid>
                        <Grid cols='md-9'>
                            {this.renderOSFatura(this.props.os_fatura.lista)}
                        </Grid>
                    </Row>
                </ContentSection>

                <FloatButton
                    route='/fatura-add' icon="fa fa-plus" tooltip="Novo Faturamento"
                />

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({os_fatura: state.os_fatura, login: state.login})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSFatura)
