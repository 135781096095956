import { transmitir } from '../sys/transmitir'
import act from '../main/action-const'

const ROTA = 'ficha'

export const obter = (id_pessoa) => {
    let vo = []
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'get', ROTA,
        (payload)=> ([
            { type: act.FICHA_CARREGADA, payload: payload },
        ])
    )
}

export const trocaremail = (vo) => {
    return transmitir(vo, 'post', 'agente-trocaemail',
        (payload)=> ({ type: act.FICHA_CARREGADA, payload: payload })
    )
}
