import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'os-fatura'


export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[ dados['field'] ] = dados['value']
    }
    if (dados && dados['sit_fatura']) {
        vo['sit_fatura'] = dados['sit_fatura']
    }
    return transmitir(vo, 'get', 'os-fatura-list',
        (payload)=> ({ type: act.OS_FATURA_LISTADO, payload: payload })
    )
}


export const obter = (id_fatura) => {
    let vo = []
    vo['id_fatura'] = id_fatura
    return transmitir(vo, 'get', ROTA,
        (payload)=> ({ type: act.OS_FATURA_CARREGADO, payload: payload[0] })
    )
}

export const gerar = (dados) => {
    return transmitir(dados, 'post', ROTA+'-gerar', 
        (payload)=> ({ type: act.OS_FATURA_INCLUIDO, payload: payload })
    )
}

export const simular = (dados) => {
    let vo = []
    if (dados['id_cliente']) vo['id_cliente'] =  dados['id_cliente']
    if (dados['dia_inicio']) vo['dia_inicio'] =  dados['dia_inicio']
    if (dados['dia_final']) vo['dia_final'] =  dados['dia_final']
    return transmitir(vo, 'get', ROTA + '-simular', (payload)=> ({ type: act.OS_FATURA_SIMULADO, payload: payload[0] }))
}

export const cancelar = (vo) => {
    return transmitir(vo, 'post', ROTA+'-cancelar',
        (payload)=> ([
            { type: act.OS_FATURA_SALVO, payload: payload || {} },
            obter(vo['id_fatura']),
            listarServicos({id_fatura: vo['id_fatura']}),
        ])
    )
}

export const finalizar = (vo) => {
    return transmitir(vo, 'post', ROTA+'-finalizar',
        (payload)=> ([
            { type: act.OS_FATURA_SALVO, payload: payload || {} },
            obter(vo['id_fatura']),
            listarServicos({id_fatura: vo['id_fatura']}),
        ])
    )
}

export const listarServicos = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[ dados['field'] ] = dados['value']
    }
    if (dados && dados['sit_fatura']) {
        vo['sit_fatura'] = dados['sit_fatura']
    }
    return transmitir(vo, 'get', 'os-fatura-servico-list',
        (payload)=> ({ type: act.OS_FATURA_SERVICO_LISTADO, payload: payload })
    )
}

export const listar_mais = (page, id_fatura) => {
    let vo = []
    vo['page'] = page;
    vo['id_fatura'] = id_fatura
    return transmitir(vo, 'get', 'os-fatura-servico-list',
        (payload) => ([
            { type: act.OS_FATURA_SERVICO_LISTADO_MAIS, payload: payload },
            { type: act.OS_FATURA_SERVICO_LISTADO_ADD_PAGE, payload: page }
        ])
    )
}




export const init = () => {
    return [
        initialize('osFaturaEditForm', VALORES_INICIAIS)
    ]
}
