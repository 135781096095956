import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'

import { init, cancelar_convite, limpar} from './os-servico-action'
import { obter as obter_os} from '../os/os-action'

import Modal from '../common/ui/modal'

const validarFormulario  = (values) => {
    const erros = {}

    return erros
}

class OSServicoConviteCancelar extends Component {

    // constructor(props) {
    //     super(props);
    // }

    onSubmit() {
        let dados = {
            id_os: this.props.os.dados['id_os'],
        }
        this.props.cancelar_convite(dados, obter_os)
        return true
    }

    render() {
        return(
            <Modal 
                id='os-servico-convite-cancelar' 
                title="Cancelar Convite" 
                confirmLabel='Cancelar Convite'
                confirmButtonType='submit'
                onConfirm={()=>{ return this.onSubmit() }}
                onClose={()=>{this.props.limpar()}}
            >
                <p>Cancelar Convite?</p>
                <div className="clearfix"></div>
            </Modal>
        )
    }

}

OSServicoConviteCancelar = reduxForm({
    form: 'os_servicoConviteCancelarForm',
    validate: validarFormulario,
    enableReinitialize : true
})(OSServicoConviteCancelar)


const mapStateToProps = state => ({
    os: state.os
})
const mapDispatchToProps = dispatch => bindActionCreators({init, cancelar_convite, limpar, obter_os}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSServicoConviteCancelar)

