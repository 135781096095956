import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Redirect } from 'react-router-dom'

import { init, obter, salvar, bloquear_togle, cancelar_togle, gerar_codigo } from './cliente-action'
import { cel_mask, tel_mask, cpf_cnpj_mask } from '../sys/normalize-mask'
import { cpf_valid, cnpj_valid } from '../sys/valid-doc'

import config from '../main/config'


import PageSetup from '../main/page-setup'
import { ContentSection } from '../common/layout'
import { BoxTitle, BoxBody, BoxFooter, Box } from '../common/widget/box'
import { InputGroup, SelectGroup, InputGroupAutocompleteAddon, FormRow } from '../common/form'
import { ButtonInfo, ButtonDanger, ButtonWarning } from '../common/ui/buttons'

import EnderecoEdit, { endereco_push } from '../pessoa/endereco-edit'

const selector = formValueSelector('clienteEditForm')

const validarFormulario = (values) => {
    const erros = {}

    if (!values.nome || values.nome.length === 0)
        erros.nome = 'Informe o nome'

    if (values.documento && values.documento.length > 0) {
        if (values.documento.length <= 14 && !cpf_valid(values.documento)) {
            erros.documento = 'CPF inválido'
        }
        if (values.documento.length > 14 && !cnpj_valid(values.documento)) {
            erros.documento = 'CNPJ inválido'
        }


    }

    return erros
}



class ClienteEdit extends Component {

    constructor(props) {
        super(props);
        this.props.init()
        const { match: { params } } = this.props;
        this.props.obter(params['id_pessoa'])
        this.salvarFormulario = this.salvarFormulario.bind(this)
        this.bloqueia_desbloqueia = this.bloqueia_desbloqueia.bind(this)
        this.cancelar_descancela = this.cancelar_descancela.bind(this)
        this.gerar_codigo = this.gerar_codigo.bind(this)
    }

    onSubmit(values) {
        let dados = {
            id_pessoa: this.props.cliente.dados['id_pessoa'],
            nome: values.nome,
            id_pessoa_link: values.id_pessoa_link,
            documento: values.documento,
            telefone: values.telefone,
            celular: values.celular,
            email: values.email,
            sexo: values.sexo,
            dt_nascimento: values.dt_nascimento
        }
        endereco_push(values, dados)
        this.props.salvar(dados)
    }

    salvarFormulario() {
        const { handleSubmit } = this.props
        handleSubmit(values => this.onSubmit(values))
    }

    bloqueia_desbloqueia() {
        this.props.bloquear_togle(this.props.cliente.dados['id_pessoa'])
    }

    cancelar_descancela() {
        this.props.cancelar_togle(this.props.cliente.dados['id_pessoa'])
    }

    gerar_codigo() {
        this.props.gerar_codigo(this.props.cliente.dados['id_pessoa'])
    }

    pessoa_onSelected = (item) => {
        this.props.change("fatupara", item['nome'] || '')
        this.props.change("id_pessoa_link", item['id_pessoa'] || 0)
    }


    renderBotoes() {
        const { pristine, submitting } = this.props
        return (
            <Box>
                <BoxFooter>
                    {this.props.cliente.dados['sit_pessoa'] === '1' &&
                        <ButtonDanger icon="fa fa-lock" align_class="pull-left" onClick={this.bloqueia_desbloqueia} hint="Bloquear" disabled={!pristine && !submitting}></ButtonDanger>
                    }
                    {this.props.cliente.dados['sit_pessoa'] === '1' &&
                        <ButtonDanger icon="fa  fa-thumbs-down" align_class="pull-left" onClick={this.cancelar_descancela} hint="Cancelar" disabled={!pristine && !submitting}></ButtonDanger>
                    }
                    {this.props.cliente.dados['sit_pessoa'] === '2' &&
                        <ButtonWarning icon="fa fa-unlock-alt" align_class="pull-left" onClick={this.bloqueia_desbloqueia} hint="Desbloquear" disabled={!pristine && !submitting}></ButtonWarning>
                    }
                    {this.props.cliente.dados['sit_pessoa'] === '9' &&
                        <ButtonWarning icon="fa  fa-thumbs-up" align_class="pull-left" onClick={this.cancelar_descancela} hint="Descancelar" disabled={!pristine && !submitting}></ButtonWarning>
                    }
                    {/**
                <ButtonPrimary icon="fa fa-user-secret" align_class="pull-left" onClick={this.gerar_codigo} hint="Código de Acesso" disabled={!pristine && !submitting}></ButtonPrimary>

                { this.props.cliente.dados['codigo_acesso'] && ` Código: ${this.props.cliente.dados['codigo_acesso']} ` }
                { this.props.cliente.dados['codigo_acesso'] && ` Gerado em: ${date_time_loc(this.props.cliente.dados['dh_codigo_acesso'])}  `}  
 */}

                    <ButtonInfo type="submit" align_class="pull-right" icon="fa fa-floppy-o" hint="Salvar dados" disabled={pristine || submitting}></ButtonInfo>

                </BoxFooter>
            </Box>
        )
    }

    renderFormulario() {

        const { handleSubmit } = this.props
        const endpoint_cliente_load = `${config().API_URL}/pessoa-load&query=`

        return (
            <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                <PageSetup title='Clientes' subtitle='Atualização'>
                    <ContentSection>
                        {this.renderBotoes()}
                        <BoxTitle title="Dados do Cliente">
                            <BoxBody>
                                <FormRow>
                                    <Field label='Nome' name="nome" placeholder='Nome completo' required component={InputGroup} cols='md-4' />
                                    <Field label='Telefone' name="telefone" placeholder='Telefone' component={InputGroup} normalize={tel_mask} cols='md-4' />
                                    <Field label='Celular' name="celular" placeholder='Celular' component={InputGroup} normalize={cel_mask} cols='md-4' />

                                    <Field label='C.P.F./C.N.P.J' name="documento" placeholder='C.P.F./C.N.P.J' component={InputGroup} normalize={cpf_cnpj_mask} cols='md-4' />
                                    <Field label='Sexo' name="sexo" component={SelectGroup} cols='md-4'>
                                        <option />
                                        <option value="M">Masculino</option>
                                        <option value="F">Feminino</option>
                                    </Field>
                                    <Field label='Email' name="email" type='email' placeholder='Email' required component={InputGroup} cols='md-4' />
                                    {this.props?.documento && this.props?.documento.length <= 14 &&
                                        <Field label='Nascimento' name="dt_nascimento" type='date' component={InputGroup} cols='md-4' />
                                    }
                                    <Field label='Faturar Para' name="fatupara" component={InputGroup} cols='md-4' disabled />

                                    <div className="clearfix"></div>
                                    <Field label='Pesquisa Faturar Para' name="fatuparapesq" icon='fa fa-search' placeholder='Pesquisa faturar para'
                                        url={endpoint_cliente_load}
                                        onSelected={(item) => this.pessoa_onSelected(item)}
                                        columnShow="nome"
                                        component={InputGroupAutocompleteAddon}
                                        cols='md-3'
                                    />

                                </FormRow>
                            </BoxBody>

                        </BoxTitle>

                        <EnderecoEdit change={this.props.change} />

                    </ContentSection>

                </PageSetup>
            </form>

        )
    }

    render() {
        if (this.props.cliente.upd_status === 3) {
            return <Redirect to={`/cliente`} />
        }
        return this.renderFormulario()
    }
}

ClienteEdit = reduxForm({
    form: 'clienteEditForm',
    validate: validarFormulario,
    enableReinitialize: true
})(ClienteEdit)


const mapStateToProps = state => ({
    cliente: state.cliente,
    initialValues: state.cliente.dados,
    documento: selector(state, 'documento'),
})
const mapDispatchToProps = dispatch => bindActionCreators({ init, obter, salvar, bloquear_togle, cancelar_togle, gerar_codigo }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ClienteEdit)

