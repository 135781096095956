import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Anchor } from '../common/helper'

import { agree } from '../login/login-actions'

import config from '../main/config'

class AgreePage extends Component {

    componentDidMount() {
        document.body.classList.remove(config().THEME_NAME)
        document.body.classList.remove('sidebar-mini')
        document.body.classList.add('login-page')
    }

    componentWillUnmount() {
        document.body.classList.remove('login-page')
        document.body.classList.add(config().THEME_NAME)
        document.body.classList.add('sidebar-mini')
    }

    render() {

        return (
            <div className="login-box">
                <div className="login-logo">
                    <Anchor href="/">{config().NOME_SISTEMA || 'Nome Sistema'}</Anchor>
                </div>
                <div className="login-box-body">

                    <p className="login-box-msg">Ao clicar em <b>Eu Concordo</b> você estará concorda e aceitando nosso <b>Termo LGPD</b>, nossa <b>Política de Privacidade</b>, nossos <b>Termos de Uso</b> e na <b>Utilização de Cookies</b>. </p>
                    <p className="login-box-msg">Usamos cookies exenciais para o funcionamento da aplicação e para tornar sua experiência a mais agradável possível. Para entender mais sobre os cookies, leia nossa política de privacidade.</p>

                    <button type="submit" className="btn btn-primary btn-block btn-flat" onClick={() => { this.props.agree() }}>Eu Concordo</button>
                    <br /><br />
                    <Anchor href="/termos-de-aceite-lgpd.html" target="frame-termo">Termo LGPD</Anchor>&nbsp;|&nbsp;
                    <Anchor href="/termos-e-condicoes.html" target="frame-termo">Termo de uso</Anchor>&nbsp;|&nbsp;
                    <Anchor href="/politica-de-privacidade.html" target="frame-termo">Política de Privacidade</Anchor>

                    <br /><br />
                    <iframe name="frame-termo" src="/termos-de-aceite-lgpd.html" style={{width: 'auto', height: "300px", border: "1px solid black", overflow: "auto"}}></iframe>


                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({ login_info: state.login })
const mapDispatchToProps = (dispatch) => bindActionCreators({ agree }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AgreePage)
