/**
 * https://www.npmjs.com/package/react-floating-action-button
 */

import React from 'react'
import { Container, Link, Button } from 'react-floating-action-button'

export default props => (

    <Container>

        {props.children}

        { props.route &&
            <Link
                href={props.route && `#${props.route}`}
                tooltip={props.tooltip}
                icon={props.icon || 'fa fa-plus'} 
                rotate={true}
            />
        }
        { !props.route &&
            <Button
            tooltip={props.tooltip}
            icon={props.icon || 'fa fa-plus'} 
            rotate={true}
            onClick = { props.onClick }
            />
        }

    </Container>

)

export const FloatLink = props => { 
    return(
    <Link 
        href={ props.route && `#${props.route}`}
        tooltip={props.tooltip}
        icon={props.icon} 
        className="btn btn-link btn-lg text-white"
    />
)}

export const FloatButtonLink = props => { 
    return(
    <Button 
        tooltip={props.tooltip}
        icon={props.icon} 
        className="btn btn-link btn-lg text-white"
        onClick = { props.onClick }
    />
)}