import act from '../main/action-const'

const INITIAL_STATE = { 
    dados: {},
    lista: [],
    pesquisa: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
}


export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.OPERADOR_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0

            }

        case act.OPERADOR_CARREGADO:
            return { ...state, dados: acesso_prepare(action.payload), upd_status: 4 }

            
        case act.OPERADOR_SALVO:
        case act.OPERADOR_CONVIDADO:
            return {
                ...state, dados: action.payload,
                upd_status: 3
            }
            
        case act.OPERADOR_INCLUIDO:
            return {
                ...state, dados: action.payload,
                upd_status: 5
            }

        case act.OPERADOR_PESQUISADO:
            return { 
                ...state, 
                pesquisa: action.payload, 
                upd_status: (typeof(action.payload) === 'object' && action.payload['id_pessoa']) ? 1 : 2 
            }
    
        default:
            return state
    }
}


const acesso_prepare = (dados) => {
    let classe = (dados['classe'] || '').toLowerCase()
    if (classe.includes('operador')) dados['acesso_operador'] = 1
    if (classe.includes('admin')) dados['acesso_admin'] = 1
    if (classe.includes('apoio')) dados['acesso_apoio'] = 1
    if (classe.includes('comercial')) dados['acesso_comercial'] = 1
    if (classe.includes('finan')) dados['acesso_finan'] = 1
    return dados
}
