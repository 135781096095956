import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Row, Grid } from '../common/layout'
import { ContentSection } from '../common/layout'
import { BoxTitle, BoxBody } from '../common/widget/box'
import { ButtonInfo, ButtonWarning, ButtonDanger } from '../common/ui/buttons'

import Modal, { modal_open } from '../common/ui/modal'
import { Icon, TexMuted } from '../common/ui'
import { Link } from 'react-router-dom'

import OSServicosDetalhe from '../os-servico/os-servico-detalhe'
import OsServicoFilter from '../os-servico/os-servico-filter'
import OSServicoAdd from '../os-servico/os-servico-add'
import OSServicoEdit from '../os-servico/os-servico-edit'
import OsNegociacao from './os-negociacao'
import OsAnotacao from './os-anotacao'
import OSServicoConvite from '../os-servico/os-servico-convite'
import OSServicoConviteCancelar from '../os-servico/os-servico-convite-cancelar'
import OSServicoList from '../os-servico/os-servico-list'
import OsTimeLine from './os-time-line'
import { os_situacao } from './os-labels'

import { date_time_loc, date_loc, money_loc } from '../sys/normalize-location'

import {
    obter,
    obter_timeline,
    cancelar_os,
    solicitar_autorizacao,
    colocar_em_tramite,
    gerar_doc,
    download_os,
    gerar_orc,
    download_orc
} from './os-action'

import {
    listar as listar_servicos,
    limpar as limpar_servico,
    obter as obterDetalhe
} from '../os-servico/os-servico-action'

import config from '../main/config'


class OSFichaShow extends Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_os'])
        this.props.listar_servicos({ field: 'id_os', value: params['id_os'] })
        this.props.obter_timeline(params['id_os'])
    }

    render_sobre() {
        const img_missao = config().IMAGE_PATH + 'missao.png'
        const os_do_usuario = (this.props.os?.dados['login_inc'] === this.props.os?.dados['login_atual'])
        const os_em_edicao = parseInt(this.props.os?.dados['sit_os']) === 1
        const os_em_aprovacao = parseInt(this.props.os?.dados['sit_os']) === 3
        const os_aprovada = parseInt(this.props.os?.dados['sit_os']) === 4
        const os_em_tramite = parseInt(this.props.os?.dados['sit_os']) === 6
        let botoes = []
        if (os_em_edicao || os_em_aprovacao || os_aprovada) {
            botoes.push(<ButtonInfo key={'6neg'} align_class="btn-block" onClick={() => { modal_open('os-negociacao') }}>Negociação</ButtonInfo>)
        }
        if (os_em_tramite) {
            botoes.push(<ButtonInfo key={'6anot'} align_class="btn-block" onClick={() => { modal_open('os-anotacao') }}>Anotação</ButtonInfo>)
        }
        if (os_em_edicao) {
            botoes.push(<Link key={'1e'} to={`/missao-edit/${this.props.os?.dados['id_os']}`} className="btn btn-primary btn-block">Editar Missão</Link>)
            botoes.push(<ButtonInfo key={'2ok'} align_class="btn-block" onClick={() => { modal_open('os-sol-autorizacao') }}>Pedir OK do Regional</ButtonInfo>)
        }
        if ((os_em_edicao || os_em_aprovacao || os_aprovada) && (os_do_usuario)) {
            botoes.push(<ButtonDanger key={'5c'} align_class="btn-block" onClick={() => { modal_open('os-cancelar') }}>Cancelar OS</ButtonDanger>)
        }
        if ((os_aprovada || os_em_edicao)) {
            botoes.push(<ButtonWarning key={'4t'} align_class="btn-block" onClick={() => { modal_open('os-colocar-em-tramite') }}>Colocar Em Tramite</ButtonWarning>)
        }
        if (os_em_tramite) {
            botoes.push(<ButtonInfo key={'6t'} align_class="btn-block" onClick={() => { this.props.limpar_servico(); modal_open('os-servico-add') }}>Adicionar Serviços</ButtonInfo>)
        }

        botoes.push(<ButtonWarning key={'10g'} align_class="btn-block" onClick={() => { this.props.gerar_doc(this.props.os?.dados['id_os']) }}>Gerar Doc da O.S.</ButtonWarning>)
        botoes.push(<ButtonWarning key={'11g'} align_class="btn-block" onClick={() => {
            download_os(this.props.os?.dados['id_os'], this.props.os?.dados['os_numero'])
        }}>Baixar O.S.</ButtonWarning>)

        botoes.push(<ButtonInfo key={'20g'} align_class="btn-block" onClick={() => { this.props.gerar_orc(this.props.os?.dados['id_os']) }}>Gerar Orçamento.</ButtonInfo>)
        botoes.push(<ButtonInfo key={'21g'} align_class="btn-block" onClick={() => {
            download_orc(this.props.os?.dados['id_os'], this.props.os?.dados['os_numero'])
        }}>Baixar Orçamento</ButtonInfo>)



        function botoes_show() {
            if (botoes.length < 1) return <></>
            return botoes.map(c => { return c })
        }

        return (
            <div className="box box-primary">
                <div className="box-header with-border">
                    <div className="user-block">
                        <img className="img-circle" src={img_missao} alt="Missão" />
                        <span className="username">{(this.props.os?.dados['cliente'] || '')} </span>
                        <span className="description">{this.props.os?.dados['documento'] + ' | ' + this.props.os?.dados['os_numero']}</span>
                    </div>

                </div>

                <div className="box-body">
                    <span className="pull-right">{os_situacao(this.props.os?.dados['sit_os'])}</span>
                    <strong>
                        <Icon icon="fa fa-commenting" /> Descrição
                    </strong>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{this.props.os?.dados['descricao']}</p>

                    <strong>
                        <Icon icon="fa fa-calendar" /> Programação
                    </strong>
                    <TexMuted>{date_loc(this.props.os?.dados['dt_prog'])} | {date_loc(this.props.os?.dados['dt_ate'])}</TexMuted>
                    <strong>
                        <Icon icon="fa fa-user-secret" /> Agentes
                    </strong>
                    <TexMuted>{this.props.os?.dados['qtd_agentes_solicitados'] || 0} Solicitados</TexMuted>

                    <strong>
                        <Icon icon="fa fa-user-secret" /> Agentes
                    </strong>
                    <TexMuted>{this.props.os?.dados['qtd_agentes_calculado'] || 0} Operando</TexMuted>

                    <strong>
                        <Icon icon="fa fa-rocket" /> Atividades
                    </strong>
                    <TexMuted>{this.props.os?.dados['qtd_servicos_calculado'] || 0} Serviços</TexMuted>


                    <strong>
                        <Icon icon="fa fa-money" /> Preço Orçado
                    </strong>
                    <TexMuted>{money_loc(this.props.os?.dados['preco_comercial'] || 0)}</TexMuted>
                    <strong>
                        <Icon icon="fa fa-money" /> Previsão de Pagamento
                    </strong>
                    <TexMuted>{date_loc(this.props.os?.dados['dt_prev_pag'] || '')}</TexMuted>

                    <strong>
                        <Icon icon="fa fa-dollar" /> Preço Calculado
                    </strong>
                    <TexMuted>{money_loc(this.props.os?.dados['Preço'] || 0)}</TexMuted>

                    <strong>
                        <Icon icon="fa fa-dollar" /> Custo Calculado
                    </strong>
                    <TexMuted>{money_loc(this.props.os?.dados['custo_calculado'] || 0)}</TexMuted>

                    <strong>
                        <Icon icon="fa fa-map-marker" /> Local
                    </strong>
                    <TexMuted>{this.props.os?.dados['endereco'] || ''}</TexMuted>
                    {this.props.os?.dados['referencia'] && <TexMuted>{this.props.os?.dados['referencia']}</TexMuted>}
                    {this.props.os?.dados['endereco_destino'] &&
                        <span>
                            <strong>
                                <Icon icon="fa fa-map-marker" /> Destino
                            </strong>
                            <TexMuted>{this.props.os?.dados['endereco_destino'] || ''}</TexMuted>
                            {this.props.os?.dados['referencia_destino'] && <TexMuted>{this.props.os?.dados['referencia_destino']}</TexMuted>}
                        </span>
                    }
                    <TexMuted>{this.props.os?.dados['regiao'] || ''}</TexMuted>

                    <strong>
                        <Icon icon="fa fa-mobile-phone" /> Celular
                    </strong>
                    <TexMuted>{this.props.os?.dados['celular'] + ' ' + (this.props.os?.dados['contato'] || '')}</TexMuted>
                    <strong>
                        <Icon icon="fa fa-envelope" /> Email
                    </strong>
                    <TexMuted>{this.props.os?.dados['email']}</TexMuted>


                    {botoes_show()}
                </div>
                <OsTimeLine />
            </div>
        )
    }

    render_sol_autoricacao() {
        return (
            <Modal
                id='os-sol-autorizacao'
                title="Autorização do Regional"
                confirmLabel='Solicitar'
                onConfirm={() => { this.props.solicitar_autorizacao(this.props.os?.dados['id_os']); return true }}
            >
                <p>Deseja solicitar o OK do Regional para dar continuidade a está Missão?</p>
            </Modal>
        )
    }

    render_cancelar() {
        return (
            <Modal
                id='os-cancelar'
                title="Cancelar a Missão"
                confirmLabel='Cancelar OS'
                onConfirm={() => { this.props.cancelar_os(this.props.os?.dados['id_os']); return true }}
            >
                <p>Deseja cancelar a OS?</p>
            </Modal>
        )
    }

    render_colocar_em_tramite() {
        return (
            <Modal
                id='os-colocar-em-tramite'
                title="Colocar Missão Em Tramite"
                confirmLabel='Colocar Em Tramite'
                onConfirm={() => { this.props.colocar_em_tramite(this.props.os?.dados['id_os']); return true }}
            >
                <p>Confirma colocar a Missão Em Tramite?</p>
            </Modal>
        )
    }

    render_convite(convite) {
        if (convite['quantidade']) {
            return (
                <BoxTitle title="Convite" add_class="box-primary">
                    <BoxBody>
                        <p>
                            <strong>
                                <Icon icon="fa fa-bullhorn" /> {convite['convites_aceitos']}/{convite['quantidade']}  Convites aceitos | {convite['regiao'] || ''}
                            </strong>
                        </p>
                        <strong>Serviço:</strong>
                        <TexMuted> {convite['ref']} - {convite['descricao']}</TexMuted>
                        <strong>Preço/Custo</strong>
                        <TexMuted> {convite['preco']} / {convite['custo']}</TexMuted>
                        <strong>Dia</strong>
                        <TexMuted> {date_time_loc(convite['dia_prog'] + ' ' + convite['hora_prog'])} </TexMuted>
                        <ButtonDanger align_class="btn-block" onClick={() => { modal_open('os-servico-convite-cancelar') }}> <Icon icon="fa fa-close" /> Cancelar</ButtonDanger>
                    </BoxBody>

                </BoxTitle>
            )

        } else {
            return (
                <BoxTitle title="Convite" add_class="box-primary">
                    <BoxBody>
                        <ButtonWarning align_class="btn-block" onClick={() => { this.props.limpar_servico(); modal_open('os-servico-convite') }}> <Icon icon="fa fa-bullhorn" /> Convidar Agentes</ButtonWarning>
                    </BoxBody>
                </BoxTitle>
            )
        }
    }

    render() {

        return (
            <PageSetup title='Ficha da Missão'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.render_sobre()}
                            {this.render_convite(this.props.os?.dados['convite'] ?? [])}
                        </Grid>
                        <Grid cols="md-8">
                            <OsServicoFilter />
                            <OSServicoList
                                dados={this.props.os_servico.lista}
                                obterDetalhe={(id_servico) => { this.props.obterDetalhe(id_servico) }}
                                limpar={() => { this.props.limpar_servico() }}
                            />
                        </Grid>
                    </Row>

                </ContentSection>
                <OSServicoAdd />
                <OSServicoEdit />
                <OSServicoConvite />
                <OSServicoConviteCancelar />
                <OSServicosDetalhe />
                <OsNegociacao />
                <OsAnotacao />

                {this.render_sol_autoricacao()}
                {this.render_cancelar()}
                {this.render_colocar_em_tramite()}

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({
    os: state.os,
    os_servico: state.os_servico,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    obter,
    listar_servicos,
    limpar_servico,
    obterDetalhe,
    obter_timeline,
    cancelar_os,
    solicitar_autorizacao,
    colocar_em_tramite,
    gerar_doc,
    download_os,
    gerar_orc,
    download_orc
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OSFichaShow)
