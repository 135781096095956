import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

import appReducer from '../main/app-reducer'
import LoginReducer from '../login/login-reducer'
import PerfilReducer from '../perfil/perfil-reducer'
import operadorReducer from '../operador/operador-reducer'
import solicitanteReducer from '../solicitante/solicitante-reducer'
import solicitanteClienteReducer from '../solicitante-cliente/solicitante-cliente-reducer'
import agenteReducer from '../agente/agente-reducer'
import FichaReducer from '../ficha/ficha-reducer'
import clienteReducer from '../cliente/cliente-reducer'
import osReducer from '../os/os-reducer'
import osAprovadasReducer from '../os-aprovadas/os-aprovadas-reducer'
import os_servicoReducer from '../os-servico/os-servico-reducer'
import os_refReducer from '../os-ref/os-ref-reducer'
import osFaturaReducer from '../os-fatura/os-fatura-reducer'
import osPagaReducer from '../os-paga/os-paga-reducer'
import dashReducer from '../dashboard/dashboard-reducer'
import radarReducer from '../radar/radar-reducer'
import loteCnabReducer from '../lote-cnab/lote-cnab-reducer'


const rootReducer = combineReducers({
    app: appReducer,
    login: LoginReducer,
    perfil: PerfilReducer,
    ficha: FichaReducer,
    toastr: toastrReducer,
    form: formReducer,
    operador: operadorReducer,
    solicitante: solicitanteReducer,
    solicitante_cliente: solicitanteClienteReducer,
    agente: agenteReducer,
    cliente: clienteReducer,
    os: osReducer,
    os_servico: os_servicoReducer,
    os_ref: os_refReducer,
    os_fatura: osFaturaReducer,
    os_paga: osPagaReducer,
    dash: dashReducer,
    os_aprovadas: osAprovadasReducer,
    radar: radarReducer,
    lote_cnab: loteCnabReducer
})

export default rootReducer