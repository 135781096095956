import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { appDataUpdate } from './app-action'

class PageSetup extends Component {

    constructor(props) {
        super(props);
        this.props.appDataUpdate({
            pageTitle: this.props.title || ' ',
            pageSubtitle: this.props.subtitle || ' '
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({ app: state.app })
const mapDispatchToProps = dispatch => bindActionCreators({ appDataUpdate }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(PageSetup)

