import React, { Component } from 'react'
import { Field } from 'redux-form'

import { BoxTitle, BoxBody } from '../common/widget/box'
import { InputGroup, SelectGroup, InputGroupAutocompleteAddon, FormRow } from '../common/form'

import config from '../main/config'

export const conta_corrente_push  = (values, dados) => {
    dados['banco'] = values.banco
    dados['agencia'] = values.agencia
    dados['agencia_dig'] = values.agencia_dig
    dados['conta_corrente'] = values.conta_corrente
    dados['conta_corrente_dig'] = values.conta_corrente_dig
    dados['tipo_conta'] = values.tipo_conta
    dados['documento_conta'] = values.documento_conta
    dados['chave_pix'] = values.chave_pix
}

class ContaCorrenteEdit extends Component {
    
    constructor(props) {
        super(props);
        this.banco_onSelected = this.banco_onSelected.bind(this)
    }


    banco_onSelected = (item) => {
        this.props.change("banco_nome", item['nome'] || '')
        this.props.change("banco", item['banco'] || '')
    }

    render() {
        const endpoint_banco_load = `${config().API_URL}/banco-load&query=`
        return(
            <BoxTitle title="Conta Bancária">
                <BoxBody>    
                    <FormRow>
                        <Field label='Pesquisar Banco' name="bancopesq" icon='fa fa-search' placeholder='Pesquise o banco aqui' 
                            url={endpoint_banco_load}
                            onSelected={(item) => this.banco_onSelected(item)}
                            columnShow="nome"
                            component={InputGroupAutocompleteAddon}  
                            cols='md-3' 
                        />
                        <div className="clearfix"></div>
                        <Field label='Banco' name="banco_nome"  placeholder='Banco' component={InputGroup}  cols='md-6' disabled/>
                        <Field label='Agência' name="agencia"  placeholder='Agência' component={InputGroup}  cols='md-2' />
                        <Field label='Conta Corrente' name="conta_corrente" placeholder='Conta Corrente' component={InputGroup} cols='md-2' />
                        <Field label='Dig.C/C' name="conta_corrente_dig" placeholder='Conta Corrente' component={InputGroup} cols='md-1' />
                        <Field label='Tipo' name="tipo_conta" component={SelectGroup}  cols='md-2'>
                            <option/>
                            <option value="1">Conta Corrente</option>
                            <option value="2">Conta Poupança</option>
                        </Field>
                        <div className="clearfix"></div>
                        <Field label='Documento Conta' name="documento_conta"  placeholder='Documento da Conta' component={InputGroup}  cols='md-4' />
                        <Field label='Chave Pix' name="chave_pix"  placeholder='Chave Pix' component={InputGroup}  cols='md-4' />

                    </FormRow>
                </BoxBody>
            </BoxTitle>
        )
    }
}

export default ContaCorrenteEdit

