import act from '../main/action-const'

const INITIAL_STATE = { 
    lista: [],
}

export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.OS_APROVADAS_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0
            }
        default:
            return state
    }
}
