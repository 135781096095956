import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { Redirect } from 'react-router-dom'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid  } from '../common/layout'
import { BoxTitle, BoxBody, BoxFooter } from '../common/widget/box'
import { InputGroupAddon, FormRow } from '../common/form'
import { ButtonDefault, ButtonInfo } from '../common/ui/buttons'
import { CalloutWarning, CalloutSucess } from '../common/msg/callout'

import { init, incluir, pesquisar} from './operador-action'
import { cel_mask, cpf_mask } from '../sys/normalize-mask'
import { cpf_valid } from '../sys/valid-doc'


const validarFormulario  = (values) => {
    const erros = {}

    if ( !values.nome || values.nome.length === 0 )
        erros.nome = 'Informe o nome'
    
    if (!values.documento || values.documento.lenght === 0) {
        erros.documento = 'Informe o CPF'
    } else if(!cpf_valid(values.documento)) {
        erros.documento = 'CPF inválido'
    }

    if ( !values.email || values.email.length === 0 )
        erros.email = 'Informe o e-mail'

    return erros
}


class OperadorAdd extends Component {

    //state = this.props.init()

    onSubmit(values) {
        let dados = {
            nome: values.nome,
            documento: values.documento,
            celular: values.celular,
            email: values.email
        }

        if (values.incluir) {
            this.props.incluir(dados)
        } else {
            this.props.pesquisar(dados)
        }
    }


    renderFormulario() {
        const { handleSubmit, pristine, submitting } = this.props
        return(
            <BoxTitle title="Novo(a) Operador(a)">
                <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
                <BoxBody>      
                    <FormRow>
                        <Field label='Nome' name="nome" icon='fa fa-user' placeholder='Nome completo' required component={InputGroupAddon}  cols='md-4' />
                        <br/>
                        <Field label='Celular' name="celular" icon='fa fa-mobile' placeholder='Celular' component={InputGroupAddon}  normalize={cel_mask} cols='md-4' />
                        <br/>
                        <Field label='C.P.F.' name="documento" icon='fa fa-file-photo-o' placeholder='C.P.F.' component={InputGroupAddon}  normalize={cpf_mask} cols='md-4' />
                        <br/>
                        <Field label='Email' name="email" type='email' icon='fa fa-envelope' placeholder='Email' required component={InputGroupAddon}  cols='md-4' />
                    </FormRow>
                </BoxBody>

                <BoxFooter>
                    <ButtonDefault type="reset" disabled={submitting}>Limpar</ButtonDefault>
                    <ButtonInfo type="submit" align_class="pull-right" disabled={pristine || submitting}>Pesquisar</ButtonInfo>
                    <br/><br/>

                    {this.props.operador.upd_status === 1 &&  
                        <CalloutWarning title='Operador(a) já cadastrado(a)'>
                            <p>{this.props.operador.pesquisa['nome']}</p>
                            <p>{this.props.operador.pesquisa['documento']}</p>
                            <p>{this.props.operador.pesquisa['celular']}</p>
                            <br /><br />
                        </CalloutWarning>
                    }

                    {this.props.operador.upd_status === 2 &&  
                        <CalloutSucess title='Novo Operador(a)'>
                            <div className="pull-left">
                                <ButtonDefault 
                                    onClick={handleSubmit(values => 
                                        this.onSubmit({ 
                                            ...values,
                                            incluir: true
                                        }))} 
                                    align_class="pull-right" disabled={pristine || submitting}>Cadastrar Novo Operador(a)
                                </ButtonDefault>
                            </div>
                            <br /><br />
                        </CalloutSucess>
                    }                    
                </BoxFooter>

                </form>
            </BoxTitle>

        )

    }

    renderPagina() {
        return(
            <PageSetup title='Novo Operador'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.renderFormulario()}
                        </Grid>
                   </Row>
                </ContentSection>
            </PageSetup>
        )
    }


    render() {
        if (this.props.operador.upd_status === 5) {
            return <Redirect to={`/operador-edit/${this.props.operador.dados['id_pessoa']}`} />
        }
        return this.renderPagina()
    }

}

OperadorAdd = reduxForm({
    form: 'operadorAddForm',
    validate: validarFormulario
})(OperadorAdd)


const mapStateToProps = state => ({
    operador: state.operador
})
const mapDispatchToProps = dispatch => bindActionCreators({init, incluir, pesquisar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OperadorAdd)

