import { transmitir } from '../sys/transmitir'
import act from '../main/action-const'

const ROTA = 'radar'

export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[dados['field']] = dados['value']
    }
    if (dados && dados['field'] && dados['field_value']) {
        vo[dados['field']] = dados['field_value']
    }
    if (dados && dados['sit_servico']) {
        vo['sit_servico'] = dados['sit_servico']
    }
    if (dados && dados['tipo_pag']) {
        vo['tipo_pag'] = dados['tipo_pag']
    }
    if (dados && dados['dia']) {
        vo['dia'] = dados['dia']
    }
    if (dados && dados['ate']) {
        vo['ate'] = dados['ate']
    }
    if (dados && dados['periodo_tipo']) {
        vo['periodo_tipo'] = dados['periodo_tipo']
    }
    if (dados && dados['ordem']) {
        vo['ordem'] = dados['ordem']
    }
    if (dados && dados['modalidade']) {
        vo['modalidade'] = dados['modalidade']
    }

    if (dados && dados['send_report_pdf']) {
        vo['send_report_pdf'] = dados['send_report_pdf']

        return transmitir(vo, 'get', ROTA + '-send-pdf',
            (payload) => ([])
        )
    }

    if (dados && dados['send_report_csv']) {
        vo['send_report_csv'] = dados['send_report_csv']

        return transmitir(vo, 'get', ROTA + '-send-csv',
            (payload) => ([])
        )
    }

    return transmitir(vo, 'get', ROTA + '-list',
        (payload) => ([
            { type: act.RADAR_LISTADO_SAVE_FILTRO, payload: vo },
            { type: act.RADAR_LISTADO, payload: payload }
        ])
    )
}

export const listar_mais = (page, filtro) => {
    filtro['page'] = page;
    return transmitir(filtro, 'get', ROTA + '-list',
        (payload) => ([
            { type: act.RADAR_LISTADO_MAIS, payload: payload },
            { type: act.RADAR_ADD_PAGE, payload: page }
        ])
    )
}

export const set_servico_atual = (id_servico) => {
    return (dispatch) => {
        dispatch({ type: act.RADAR_SERVICO_ATUAL, payload: id_servico })
    }
}
