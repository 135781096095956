const CDM_OS_FILTER = '__cdm_os_filter__'


export function os_filter_save(vo) {
    localStorage.setItem(CDM_OS_FILTER, JSON.stringify(vo))
}

export function os_filter_read() {
    let vo = localStorage.getItem(CDM_OS_FILTER)
    if (!vo) {
        return {}
    }
    return JSON.parse(vo)
}