import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Input2, SelectGroup2, CheckBox2 } from '../common/form'
import { BoxSimple } from '../common/widget/box'

import { listar } from './radar-action'
import { radar_filter_read, radar_filter_save } from './radar-filter-persist'

const filter_init = {
    dia: '',
    ate: '',
    periodo_tipo: '',
    ordem: 'data',
    modalidade: '',
    field: 'cliente',
    field_value: '',
    sit_servico: '',
    tipo_pag: '',
    send_report_pdf: 0,
    send_report_csv: 0
}

function get_filter_predefinido(tipo) {
    if (tipo === 'progamados_semana')
        return {
            dia: '',
            ate: '',
            periodo_tipo: '4',
            ordem: 'data',
            modalidade: '',
            field: 'cliente',
            field_value: '',
            sit_servico: '-2',
            tipo_pag: '',
            send_report_pdf: 0,
            send_report_csv: 0
        }

    if (tipo === 'finalizados_semana')
        return {
            dia: '',
            ate: '',
            periodo_tipo: '4',
            ordem: 'data',
            modalidade: '',
            field: 'cliente',
            field_value: '',
            sit_servico: '7',
            tipo_pag: '',
            send_report_pdf: 0,
            send_report_csv: 0
        }

    if (tipo === 'indefinidos')
        return {
            dia: '',
            ate: '',
            periodo_tipo: '',
            ordem: 'data',
            modalidade: '',
            field: 'agente',
            field_value: 'INDEFINIDO',
            sit_servico: '-9',
            tipo_pag: '',
            send_report_pdf: 0,
            send_report_csv: 0
        }

    if (tipo === 'cancelados_semana')
        return {
            dia: '',
            ate: '',
            periodo_tipo: '4',
            ordem: 'data',
            modalidade: '',
            field: 'cliente',
            field_value: '',
            sit_servico: '9',
            tipo_pag: '',
            send_report_pdf: 0,
            send_report_csv: 0
        }

    if (tipo === 'loja_semana') {
        const filtro_saved = radar_filter_read()

        return {
            dia: '',
            ate: '',
            periodo_tipo: '4',
            ordem: 'data',
            modalidade: '',
            field: 'cliente',
            field_value: filtro_saved?.field_value ?? '',
            sit_servico: '-9',
            tipo_pag: '',
            send_report_pdf: 0,
            send_report_csv: 0
        }
    }

    return null
}


export default function RadarFilter() {
    const dispatch = useDispatch()
    const [data, set_data] = useState(filter_init)
    const routeParams = useParams();


    useEffect(() => {
        let filtro = get_filter_predefinido(routeParams?.act)
        let go = true
        if (!filtro) {
            filtro = radar_filter_read()
            filtro['send_report_pdf'] = ''
            filtro['send_report_csv'] = ''
            go = false
        }
        set_data({ ...data, ...filtro })
        if (go) {
            dispatch(listar(filtro))
            radar_filter_save(filtro)
        }

    }, [])


    const handleChange = (ev) => {
        const vo = {}
        if (ev.target?.type === 'checkbox') {
            vo[ev.target.name] = ev.target.checked ? ev.target.value : ''
        } else {
            vo[ev.target.name] = ev.target.value
        }
        set_data({ ...data, ...vo })
    }

    const onSubmit = (ev) => {

        ev.preventDefault()
        let vo = {
            dia: data.dia,
            ate: data.ate,
            periodo_tipo: data.periodo_tipo,
            ordem: data.ordem,
            modalidade: data.modalidade,
            field: data.field,
            field_value: data.field_value,
            sit_servico: data.sit_servico,
            tipo_pag: data.tipo_pag,            
            send_report_pdf: data.send_report_pdf,
            send_report_csv: data.send_report_csv
            
        }

        dispatch(listar(vo))
        radar_filter_save(vo)
    }


    return (
        <BoxSimple>
            <form onSubmit={onSubmit}>
                <SelectGroup2
                    name="periodo_tipo"
                    value={data?.periodo_tipo}
                    onChange={handleChange}
                >
                    <option value="">Qualquer Período</option>
                    <option value="1">Hoje</option>
                    <option value="2">Amanhã</option>
                    <option value="3">Ontem</option>
                    <option value="4">Esta Semana</option>
                    <option value="5">Proxima Semana</option>
                    <option value="6">Última Semana</option>
                    <option value="7" default>Está Quinzena</option>
                    <option value="8">Próxima Quinzena</option>
                    <option value="9">Quinzena Anterior</option>
                    <option value="10">Este Mês</option>
                    <option value="11">Proxima Mês</option>
                    <option value="12">Último Mês</option>
                    <option value="13">Últimos 15 dias</option>
                    <option value="14">Próximos 15 dias</option>
                    <option value="0">Informar Período</option>
                </SelectGroup2>
                < span style={{ display: data?.periodo_tipo === "0" ? 'block' : 'none' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px' }}>Dia</span>
                        <input
                            className='form-control'
                            name="dia"
                            defaultValue={data?.dia}
                            type="date"
                            onBlur={handleChange}
                        />
                    </span>
                    <br />
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px' }}>Até</span>
                        <input
                            className='form-control'
                            name="ate"
                            defaultValue={data?.ate}
                            type="date"
                            onBlur={handleChange}
                        />
                    </span>
                    <br />
                </span>

                <SelectGroup2
                    name="sit_servico"
                    onChange={handleChange}
                    value={data?.sit_servico}
                >
                    <option value="">Todas as Situações</option>
                    <option value="1">Programado</option>
                    <option value="2">Em Andamento</option>
                    <option value="-2">Programado/Em Andamento</option>
                    <option value="7">Finalizada</option>
                    <option value="8">Contestado</option>
                    <option value="9">Cancelado</option>
                    <option value="-9">Não Cancelado</option>
                </SelectGroup2>

                <SelectGroup2
                    name="tipo_pag"
                    onChange={handleChange}
                    value={data?.tipo_pag}
                >
                    <option value="">Todos os Tipos de Pagamento</option>
                    <option value="1">Pagamento Normal</option>
                    <option value="2">Pagamento com Adiantamento</option>
                </SelectGroup2>

                <SelectGroup2
                    name="ordem"
                    onChange={handleChange}
                    value={data?.ordem}
                >
                    <option value="natural">ordem Natural</option>
                    <option value="ultima">primeiro as Últimas</option>
                    <option value="por-agente">por Agente</option>
                    <option value="por-cliente">por Cliente</option>
                    <option value="data">por Data</option>
                </SelectGroup2>

                <SelectGroup2
                    name="modalidade"
                    onChange={handleChange}
                    value={data?.modalidade}
                >
                    <option value="">Todas as Modalidades</option>
                    <option value="10">Fixo</option>
                    <option value="20">Pontual</option>
                    <option value="30">Sob Demanda</option>
                </SelectGroup2>

                <SelectGroup2
                    name="field"
                    onChange={handleChange}
                    value={data?.field}
                >
                    <option value="cliente">Loja</option>
                    <option value="os_numero">OS</option>
                    <option value="placa">Placa</option>
                    <option value="agente">Agente</option>
                    <option value="origem">Origem</option>
                    <option value="faturarPara">Faturar Para</option>
                </SelectGroup2>
                <div className="input-group input-group-sm">
                    <Input2
                        name="field_value"
                        onChange={handleChange}
                        value={data?.field_value}
                        className="form-control"
                    />
                    <span className="input-group-btn">
                        <button type="submit" className="btn btn-info btn-flat">GO!</button>
                    </span>
                </div>
                <CheckBox2
                    label='Enviar PDF por e-mail'
                    name="send_report_pdf"
                    checked={data?.send_report_pdf}
                    value="1"
                    onChange={handleChange}
                />
                <CheckBox2
                    label='Enviar CSV por e-mail'
                    name="send_report_csv"
                    value="1"
                    checked={data?.send_report_csv}
                    onChange={handleChange}
                />

            </form>
        </BoxSimple >
    )

}

