import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { InputGroup, FormRow } from '../common/form'
import { LabelInfo } from '../common/ui/labels'
import { date_loc } from '../sys/normalize-location'

import Modal from '../common/ui/modal'

import { init, gerar_lote } from './os-paga-action'

const validarFormulario = (values) => {
    const erros = {}
    return erros
}

class OSPagaGerarLote extends Component {

    constructor(props) {
        super(props);
        this.props.init()
    }

    onSubmit(values) {
        let dados = {
            id_paga: this.props.os_paga.dados['id_paga'],
            dia_prog_quitacao:values.dia_prog_quitacao,
        }
        this.props.gerar_lote(dados)
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                <Modal
                    id='os-paga-gerar-lote'
                    title="Gerar Lote de Pagamento Bancário"
                    confirmButtonType="submit"
                    confirmLabel="Gerar"
                    onConfirm={() => { return true }}
                >
                    <LabelInfo title={this.props.os_paga.dados['sit_paga_label']} align_class="pull-right" />
                    <LabelInfo title={date_loc(this.props.os_paga.dados['dia_inicio']) + ' à ' + date_loc(this.props.os_paga.dados['dia_final'])} align_class="pull-right" />
                    <LabelInfo title={(this.props.os_paga.dados['cliente'] || '')} align_class="pull-right" />
                    <FormRow>
                        <Field label='Dia Pagamento' type="date" name="dia_prog_quitacao" placeholder="Dia Pagamento" component={InputGroup} cols='md-8' />
                    </FormRow>
                    <div className="clearfix"></div>
                </Modal>
            </form>
        )
    }

}

OSPagaGerarLote = reduxForm({
    form: 'os_pagaGerarLoteForm',
    validate: validarFormulario,
    enableReinitialize: true
})(OSPagaGerarLote)

const mapStateToProps = state => ({
    os_paga: state.os_paga,
    initialValues: state.os_paga.dados
})
const mapDispatchToProps = dispatch => bindActionCreators({ init, gerar_lote }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSPagaGerarLote)

