import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { InputGroup, SelectGroup, FormRow } from '../common/form'
import { LabelInfo } from '../common/ui/labels'
import { date_loc } from '../sys/normalize-location'

import Modal from '../common/ui/modal'

import { init, cancelar, finalizar} from './os-paga-action'

const selector = formValueSelector('os_pagaStatusForm')

const validarFormulario  = (values) => {
    const erros = {}
    return erros
}

class OSPagaStatus extends Component {

    constructor(props) {
        super(props);
        this.props.init()
        this.handleStateChange = this.handleStateChange.bind();
    }

    onSubmit(values) {
        let dados = {
            id_paga: this.props.os_paga.dados['id_paga'],
            referencia: values.referencia,
            observacao: values.observacao,
        }

        if(this.props.action === "1") {
            this.props.finalizar(dados)

        }

        if(this.props.action === "2") {
            this.props.cancelar(dados)
        }

        this.props.change("action", '')
    }

    handleStateChange = () => {
    }

    render() {
        const { handleSubmit } = this.props

        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
            <Modal 
                id='os-paga-status' 
                title="Mudar Status do Lote"                 
                confirmButtonType={this.props.action === "1" || this.props.action === "2" ? "submit" : "hide"}
                confirmLabel={this.props.action === "1" ? "Finalizar Lote" : "Cancelar Lote"}
                onConfirm={()=>{ return true }}
            >
             <LabelInfo title={this.props.os_paga.dados['sit_paga_label']} align_class="pull-right"/>
             <LabelInfo title={date_loc(this.props.os_paga.dados['dia_inicio']) +' à '+date_loc(this.props.os_paga.dados['dia_final'])} align_class="pull-right"/>
             <LabelInfo title={(this.props.os_paga.dados['cliente'] || '')} align_class="pull-right"/>
            <FormRow>
                <Field label='Referencia' name="referencia" placeholder="Referência" component={InputGroup}  cols='md-12'/>
                <Field label='Observação' name="observacao" placeholder="Observação" component={InputGroup}  cols='md-12'/>
            </FormRow>
            <div className="clearfix"></div>
            <Field label='Ação' name="action" component={SelectGroup}  cols='md-3'>
                <option/>
                <option value="1">Finalizar</option>
                <option value="2">Cancelar</option>
            </Field>
            <div className="clearfix"></div>
            </Modal>
            </form>
        )
    }

}

OSPagaStatus = reduxForm({
    form: 'os_pagaStatusForm',
    validate: validarFormulario,
    enableReinitialize : true
})(OSPagaStatus)

const mapStateToProps = state => ({
    os_paga: state.os_paga,    
    initialValues: state.os_paga.dados,
    action: selector(state, 'action')
})
const mapDispatchToProps = dispatch => bindActionCreators({init, cancelar, finalizar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSPagaStatus)

