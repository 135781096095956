import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { obterContadores } from './os-action'

import Smallbox from '../common/widget/small-box'

class OSContadores extends Component {

    componentDidMount() {
        this.props.obterContadores()
    }


    render() {
        return (
            <>
                    <Smallbox
                        icon="fa fa-feed"
                        value={this.props?.os.contadores?.total_contatos_para_hoje || 0}
                        sup=""
                        title="Contatos para Hoje"
                        add_class="bg-yellow"
                        cols="md-3"
                        route="/missao/contatos_para_hoje"
                    />
                    <Smallbox
                        icon="fa fa-feed"
                        value={this.props?.os.contadores?.total_contatos_vencidos || 0}
                        sup=""
                        title="Contatos Vencidos"
                        add_class="bg-maroon"
                        cols="md-3"
                        route="/missao/contatos_vencidos"
                    />
                    <Smallbox
                        icon="fa fa-feed"
                        value={this.props?.os.contadores?.total_criados_hoje || 0}
                        sup=""
                        title="Comercial Hoje"
                        add_class="bg-green"
                        cols="md-3"
                        route="/missao/criados_hoje"
                    />
                    <Smallbox
                        icon="fa fa-feed"
                        value={this.props?.os.contadores?.total_criados_neste_mes || 0}
                        sup=""
                        title="Comercial no Mês"
                        add_class="bg-blue"
                        cols="md-3"
                        route="/missao/criados_neste_mes"
                    />

            </>

        )
    }

}

const mapStateToProps = state => ({ os: state.os })
const mapDispatchToProps = dispatch => bindActionCreators({obterContadores}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSContadores)

