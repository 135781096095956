import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Row, Grid } from '../common/layout'
import { ContentSection } from '../common/layout'
import { ButtonWarning, ButtonDanger } from '../common/ui/buttons'
import { LabelInfo } from '../common/ui/labels'

import LoteCnabList from './lote-cnab-items-list'
import CardProfile, { Item } from '../common/widget/card-profile'
import Modal, { modal_open } from '../common/ui/modal'

import { date_loc, date_time_loc, money_loc } from '../sys/normalize-location'

import { obter, obter_items, download_arquivo, cancelar } from './lote-cnab-action'

import config from '../main/config'

class LoteCnabFichaShow extends Component {


    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_lote'])
        this.props.obter_items(params['id_lote'])
    }

    render_perfil() {
        const img_perfil_defa = config().IMAGE_PATH + 'lote-cnab.png'
        return (
            <CardProfile
                image={img_perfil_defa}
                name={(this.props.lote_cnab.dados['arquivo'] || '')
                    + ' | ' + this.props.lote_cnab.dados['referencia']
                    + ' | ' + this.props.lote_cnab.dados['origem']
                    + ':' + this.props.lote_cnab.dados['id_origem']}
            >
                <Item label="Dia Programado" value={date_loc(this.props.lote_cnab.dados['dia_prog_quitacao'])}>
                    <LabelInfo title={this.props.lote_cnab.dados['sit_lote_label']} align_class="pull-right" />
                </Item>
                <Item label="Referência" value={this.props.lote_cnab.dados['referencia'] || ''}></Item>
                <Item label="Valor do Lote" value={money_loc(this.props.lote_cnab.dados['valor_lote'] || 0)}></Item>
                <Item label="Valor Quitado" value={money_loc(this.props.lote_cnab.dados['valor_quitado'] || 0)}></Item>
                <Item label="Total de Registros" value={(this.props.lote_cnab.dados['total_registros'] || 0)}></Item>

                <Item label="Banco" value={(this.props.lote_cnab.dados['banco'] || '')}></Item>
                <Item label="Agencia" value={(this.props.lote_cnab.dados['agencia'] || '')}></Item>
                <Item label="C/C" value={(this.props.lote_cnab.dados['conta_corrente'] || '') + '-' + (this.props.lote_cnab.dados['conta_corrente_dig'] || '')}></Item>
                <Item label="Inscrição" value={(this.props.lote_cnab.dados['inscricao'])}></Item>
                <Item label="Convênio" value={(this.props.lote_cnab.dados['convenio'])}></Item>
                <Item label="Arquivo" value={(this.props.lote_cnab.dados['arquivo'])}></Item>
                <Item label="Sequencia" value={(this.props.lote_cnab.dados['sequencia'])}></Item>

                <Item label="Inclusão" value={date_time_loc(this.props.lote_cnab.dados['dh_inc'] || '') + ' ' + (this.props.lote_cnab.dados['login_inc'] || '')}></Item>
                <Item label="Atualização" value={date_time_loc(this.props.lote_cnab.dados['dh_alt'] || '') + ' ' + (this.props.lote_cnab.dados['login_alt'] || '')}></Item>
                <ButtonWarning align_class="btn-block" onClick={() => {
                    download_arquivo(this.props.lote_cnab.dados['arquivo'],
                        this.props.lote_cnab.dados['id_lote'])
                }}>Baixar Arquivo</ButtonWarning>
                {<ButtonDanger align_class="btn-block" onClick={() => { modal_open('lote-cnab-cancelar') }}>Cancelar Lote</ButtonDanger>}

            </CardProfile>
        )
    }

    render_cancelar() {
        return (
            <Modal
                id='lote-cnab-cancelar'
                title="Cancelar Lote CNAB"
                confirmLabel='Cancelar'
                onConfirm={() => { this.props.cancelar(this.props.lote_cnab.dados['id_lote'] || '0'); return true }}
            >
                <p>Deseja cancelar este Lote?</p>
            </Modal>
        )
    }



    render() {

        return (
            <PageSetup title='Ficha do Lote CNAB'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.render_perfil()}
                        </Grid>
                        <Grid cols="md-8">
                            <LoteCnabList
                                dados={this.props.lote_cnab.items}
                            />
                        </Grid>
                    </Row>
                </ContentSection>
                {this.render_cancelar()}
            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({
    lote_cnab: state.lote_cnab,
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter, obter_items, download_arquivo, cancelar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoteCnabFichaShow)
