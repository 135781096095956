import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

export const obterContadores = () => {
    let vo = []
    return transmitir(vo, 'get', 'dash-contador',
        (payload)=> ({ type: act.DASH_CONTADOR_CARREGADO, payload: payload || {} })
    )
}

