import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'

import { InputGroup, InputGroupAutocompleteAddon, FormRow } from '../common/form'

import { salvar } from './solicitante-cliente-action'
import config from '../main/config'

import Modal from '../common/ui/modal'

const validarFormulario  = (values) => {
    const erros = {}
    
    if ( !values.cliente || values.cliente.length === 0 )
        erros.cliente = 'Informe o Cliente'

    return erros
}

class SolicitanteClienteAdd extends Component {

    constructor(props) {
        super(props);
        this.cliente_onSelected = this.cliente_onSelected.bind();
    }

    onSubmit(values) {
        console.log(this.props.ficha)
        let dados = {            
            id_solicitante: this.props.ficha['id_pessoa'],
            id_cliente: values.id_cliente
        }
        const bt_conf_modal = document.getElementById("bt_conf_modal")

        bt_conf_modal.disabled = true
        setTimeout(()=> bt_conf_modal.disabled = false, 1000)

        this.props.salvar(dados)
        this.props.change("id_cliente",  0)
        this.props.change("cliente", '.')      
    }

    cliente_onSelected = (item, rest) => {
        if (item) {
            this.props.change("cliente", item['nome'] || '')
            this.props.change("id_cliente", item['id_pessoa'] || 0)
        }
        rest.clearSelection()
    }

    render() {
        const { handleSubmit } = this.props

        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
                <Modal 
                    id='solicitante-cliente-add' 
                    title="Adicionar Cliente ao Solicitante" 
                    confirmLabel='Adicionar'
                    confirmButtonType='submit'
                    onConfirm={()=>{ return false }}
                >

                    <FormRow>
                        <InputGroupAutocompleteAddon
                                name="pesquisa" icon='fa fa-search' placeholder='Pesquisar Cliente' 
                                cols='md-12'
                                url={`${config().API_URL}/cliente-load&query=`}
                                onSelected={(item,rest) => this.cliente_onSelected(item,rest)}
                                columnShow="nome"
                                />
                        <Field label='Cliente' name="cliente" placeholder="Cliente" component={InputGroup}  cols='md-12' disabled/>
                    </FormRow>
                    <div className="clearfix"></div>
                </Modal>
            </form>
        )
    }

}

SolicitanteClienteAdd = reduxForm({
    form: 'solicitanteClienteAddAddForm',
    validate: validarFormulario
})(SolicitanteClienteAdd)


const mapStateToProps = state => ({
    ficha: state.ficha
})
//const mapDispatchToProps = dispatch => bindActionCreators({init, salvar, limpar, obter_os}, dispatch)
const mapDispatchToProps = dispatch => bindActionCreators({salvar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SolicitanteClienteAdd)

