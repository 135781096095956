import React from 'react'
import { LabelSucess, LabelDanger, LabelDefault, LabelInfo, LabelWarning} from '../common/ui/labels' 
export const os_situacao = (s) => {
    switch (s) {
        case '1': return <LabelInfo title='Em Edição'/>
        case '2': return <LabelDanger title='Bloqueado'/>
        case '3': return <LabelWarning title='em Aprovação'/>
        case '4': return <LabelSucess title='Aprovado'/>
        case '5': return <LabelDanger title='Reprovado'/>
        case '6': return <LabelSucess title='em Tramite'/>
        case '7': return <LabelSucess title='Finalizado'/>
        case '9': return <LabelDanger title='Cancelado'/>
        default: return <LabelDefault title='Indefinido'/>
    }

}