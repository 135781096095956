import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import CardSecond, {Item} from '../common/widget/card-second'
import {BadgeYellow, BadgeBlue} from '../common/ui/badge'
import FloatButton from '../common/ui/float-button'

import {listar} from './cliente-action'
import config from '../main/config'


import ClienteFilter from './cliente-filter'

class Cliente extends Component {

    constructor(props) {
        super(props);
        this.props.listar()
    }

    renderClientes(dados) {
        const img_perfil_defa = config().IMAGE_PATH + 'cliente.png'
        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']


        return dados.map(vo => {
            const img_perfil = (vo['foto_file']) ? (cdn_endpoint + vo['foto_file']) : (img_perfil_defa)
            return(
                <CardSecond
                    key={'cliente' + vo['id_pessoa']} 
                    title={vo['nome']}
                    image={img_perfil}
                    col_class="col-md-6"
                    color_class={vo['sit_pessoa'] ==='1' ? 'bg-aqua' : (vo['sit_pessoa'] ==='9' ? 'bg-black' : 'bg-fuchsia')}
                    minHeightHeader={'110px'}
                    route={`/ficha/${vo['id_pessoa']}`}
                >
                    <Item text='Celular'><BadgeYellow title={vo['celular']}/></Item>
                    <Item text='Email'><BadgeYellow title={vo['email']}/></Item>
                    <Item text='Documento'>
                        <BadgeBlue title={vo['sit_pessoa_label']}/>
                        <BadgeYellow title={vo['documento']}/> 
                    </Item>
                </CardSecond>
            )
        })
    }

    render() {
        return(
            <PageSetup title='Clientes' subtitle='Lista de Clientes'>
                <ContentSection>
                    
                    <Row>
                        <Grid cols='md-3'>
                            <ClienteFilter/>
                        </Grid>
                        <Grid cols='md-9'>
                            {this.renderClientes(this.props.cliente.lista)}
                        </Grid>
                    </Row>
                </ContentSection>

                <FloatButton
                    route='/cliente-add' icon="fa fa-user-plus" tooltip="Novo Cliente"
                />

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({cliente: state.cliente, login: state.login})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Cliente)
