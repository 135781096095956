import act from '../main/action-const'

const INITIAL_STATE = { 
    dados: {},
    lista: [],
    pesquisa: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
}


export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.SOLICITANTE_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0

            }

        case act.SOLICITANTE_CARREGADO:
            return { ...state, dados: acesso_prepare(action.payload), upd_status: 4 }

            
        case act.SOLICITANTE_SALVO:
        case act.SOLICITANTE_CONVIDADO:
            return {
                ...state, dados: action.payload,
                upd_status: 3
            }
            
        case act.SOLICITANTE_INCLUIDO:
            return {
                ...state, dados: action.payload,
                upd_status: 5
            }

        case act.SOLICITANTE_PESQUISADO:
            return { 
                ...state, 
                pesquisa: action.payload, 
                upd_status: (typeof(action.payload) === 'object' && action.payload['id_pessoa']) ? 1 : 2 
            }
    
        default:
            return state
    }
}


const acesso_prepare = (dados) => {
    let classe = (dados['classe'] || '').toLowerCase()
    if (classe.includes('solicitante')) dados['acesso_solicitante'] = 1
    if (classe.includes('gerente')) dados['acesso_gerente'] = 1
    return dados
}
