import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'

import { InputGroup, SelectGroup, FormRow } from '../common/form'
import { init, salvar, limpar} from './os-ref-action'

import Modal from '../common/ui/modal'

const validarFormulario  = (values) => {
    const erros = {}

    if ( !values.ref || values.ref.length === 0 )
        erros.ref = 'Informe a referência'

    if ( !values.descricao || values.descricao.length === 0 )
        erros.descricao = 'Informe a descrição'

    if ( !values.preco || values.preco.length === 0 )
        erros.preco = 'Informe o preço'

    if ( !values.custo || values.custo.length === 0 )
        erros.custo = 'Informe o custo'

    if ( !values.tipo || values.tipo.length === 0 )
        erros.custo = 'Informe o tipo'

    return erros
}


class OSRefEdit extends Component {

    constructor(props) {
        super(props);
        this.props.init()
        this.handleStateChange = this.handleStateChange.bind();
    }

    onSubmit(values) {
        let dados = {
            id_ref: this.props.os_ref.dados['id_ref'],
            ref: values.ref,
            descricao: values.descricao,
            tipo: values.tipo,
            preco: values.preco,
            custo: values.custo
        }
        this.setState({descricao:'' })
        this.props.salvar(dados)
    }

    handleStateChange = () => {
    }

    render() {
        const { handleSubmit } = this.props

        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
            <Modal 
                id='os-ref-edit' 
                title="Tabelas" 
                confirmLabel='Salvar'
                confirmButtonType='submit'
                onConfirm={()=>{ return false }}
                onClose={()=>{this.props.limpar()}}
            >

            <FormRow>
                <Field label='Ref.' name="ref" placeholder="Referência" component={InputGroup}  cols='md-4'/>
                <Field label='Descricao' name="descricao" placeholder="Descrição" component={InputGroup}  cols='md-8'/>
                <Field label='Preço' name="preco" placeholder="Preço" type="number" component={InputGroup}  cols='md-4' />
                <Field label='Custo' name="custo" placeholder="Custo" type="number" component={InputGroup}  cols='md-4' />
                <Field label='Tipo' name="tipo" component={SelectGroup}  cols='md-3'>
                            <option/>
                            <option value="1">1 - Serviço</option>
                            <option value="2">2 - Recurso</option>
                            <option value="3">3 - Gente</option>
                </Field>
            </FormRow>

            <div className="clearfix"></div>
            </Modal>
            </form>
        )
    }

}

OSRefEdit = reduxForm({
    form: 'os_refEditForm',
    validate: validarFormulario,
    enableReinitialize : true
})(OSRefEdit)


const mapStateToProps = state => ({
    os_ref: state.os_ref,
    initialValues: state.os_ref.dados
})
const mapDispatchToProps = dispatch => bindActionCreators({init, salvar, limpar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSRefEdit)

