import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { Redirect } from 'react-router-dom'

import { init, gerar, simular} from './os-paga-action'
import { money_loc } from '../sys/normalize-location'
import config from '../main/config'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid  } from '../common/layout'
import { BoxTitle, BoxBody, BoxFooter } from '../common/widget/box'
import { InputGroupAddon, InputGroupAutocompleteAddon, FormRow } from '../common/form'
import { ButtonDefault, ButtonInfo } from '../common/ui/buttons'
import { CalloutWarning, CalloutSucess } from '../common/msg/callout'


const validarFormulario  = (values) => {
    const erros = {}
    return erros
}


class OSPagaAdd extends Component {

    //state = this.props.init()

    onSubmit(values) {
        let dados = {
            id_cliente: values.id_cliente,
            dia_inicio: values.dia_inicio,
            dia_final: values.dia_final
        }
        if (this.props.os_paga.simula && this.props.os_paga.simula['id_cliente'])
            dados['id_cliente'] = this.props.os_paga.simula['id_cliente'];

        if (values.incluir) {
            this.props.gerar(dados)
        } else {
            this.props.simular(dados)
        }
    }


    pessoa_onSelected = (item) => {
        this.props.change("fatupara", item['nome'] || '')
        this.props.change("id_cliente", item['id_pessoa'] || 0)
    }

    renderPagina() {
        return(
            <PageSetup title='Novo Lote de Pagamento'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.renderFormulario()}
                        </Grid>
                   </Row>
                </ContentSection>
            </PageSetup>
        )
    }

    renderFormulario() {
        const endpoint_cliente_load = `${config().API_URL}/pessoa-load&query=`
        const { handleSubmit, pristine, submitting } = this.props
        return(
            <BoxTitle title="Paramentros para o Lote de Pagamento">
                <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
                <BoxBody>      
                    <FormRow>
                        <Field name="fatuparapesq" icon='fa fa-search' placeholder='Lote para' 
                                    url={endpoint_cliente_load}
                                    onSelected={(item) => this.pessoa_onSelected(item)}
                                    columnShow="nome"
                                    component={InputGroupAutocompleteAddon}  
                                    cols='md-3' 
                                />
                        <br/>
                        <Field label='Dia Inicial' name="dia_inicio" icon='fa fa-calendar' type="date" required component={InputGroupAddon}  cols='md-4' />
                        <br/>
                        <Field label='Dia Final' name="dia_final" icon='fa fa-calendar' type="date" required component={InputGroupAddon}  cols='md-4' />
                    </FormRow>
                </BoxBody>

                <BoxFooter>
                    <ButtonDefault type="reset" disabled={submitting}>Limpar</ButtonDefault>
                    <ButtonInfo type="submit" align_class="pull-right" disabled={pristine || submitting}>Pesquisar</ButtonInfo>
                    <br/><br/>
                    {this.props.os_paga.upd_status === 1 &&  
                        <CalloutSucess title='Simulação do Lote de Pagamento'>
                            <p>{this.props.os_paga.simula['nome']} | {this.props.os_paga.simula['documento']} | {this.props.os_paga.simula['celular']}</p>
                            <p>Total a Pagar: {money_loc(this.props.os_paga.simula['total_pagar'])} | Total a Cobrar: {money_loc(this.props.os_paga.simula['total_cobrar'])}</p>
                            <p>Custo: {money_loc(this.props.os_paga.simula['custo'])} | Preço: {money_loc(this.props.os_paga.simula['preco'])}</p>
                            <p>Custo Adicional Total: {money_loc(this.props.os_paga.simula['custo_adicional_total'])}</p>
                            <p>Combustível: {money_loc(this.props.os_paga.simula['combustivel'])} | Estacionamento: {money_loc(this.props.os_paga.simula['estacionamento'])}</p>
                            <p>Lavagem: {money_loc(this.props.os_paga.simula['lavagem'])} | Pedágio: {money_loc(this.props.os_paga.simula['pedagio'])}</p>
                            <p>Condução: {money_loc(this.props.os_paga.simula['conducao'])} | {money_loc(this.props.os_paga.simula['alimentacao'])}</p>
                            <p>Outros: {money_loc(this.props.os_paga.simula['outros'])}</p>
                            <p>HE Cobrar: {money_loc(this.props.os_paga.simula['he_cobrar'])}</p>
                            <p>Abatimento: {money_loc(this.props.os_paga.simula['preco_abater'])}</p>
                            <p>Adiantamento Agente: {money_loc(this.props.os_paga.simula['adiantamento_agente'])}</p>
                            <p>Reembolso Agente: {money_loc(this.props.os_paga.simula['reembolsar_agente'])}</p>
                            <p>HE Agente: {money_loc(this.props.os_paga.simula['he_pagar'])}</p>
                            <p>Desconto Agente: {money_loc(this.props.os_paga.simula['descontar_agente'])}</p>                 
                            

                            <div className="pull-left">
                                <ButtonDefault 
                                    onClick={handleSubmit(values => 
                                        this.onSubmit({ 
                                            ...values,
                                            incluir: true
                                        }))} 
                                    align_class="pull-right" disabled={pristine || submitting}>Processar Lote de Pagamento
                                </ButtonDefault>
                            </div>
                            <br /><br />
                        </CalloutSucess>
                        
                    }

                    {this.props.os_paga.upd_status === 2 &&  
                        <CalloutWarning title='Não existe nada para pagar com estes parâmetros!'>
                            <br /><br />
                        </CalloutWarning>
                    }                    
                </BoxFooter>

                </form>
            </BoxTitle>

        )

    }

    render() {
        if (this.props.os_paga.upd_status === 5) {
            return <Redirect to={`/paga-ficha/${this.props.os_paga.dados['id_paga']}`} />
        }
        return this.renderPagina()
    }
}

OSPagaAdd = reduxForm({
    form: 'osPagaAddForm',
    validate: validarFormulario
})(OSPagaAdd)


const mapStateToProps = state => ({
    os_paga: state.os_paga
})
const mapDispatchToProps = dispatch => bindActionCreators({init, gerar, simular}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSPagaAdd)

