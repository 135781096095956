import act from '../main/action-const'

const INITIAL_STATE = { 
    dados: {},
    lista: [],
    pesquisa: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
}


export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.AGENTE_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0

            }

        case act.AGENTE_CARREGADO:
            return { ...state, dados: acesso_prepare(action.payload), upd_status: 4 }

            
        case act.AGENTE_SALVO:
        case act.AGENTE_CONVIDADO:
            return {
                ...state, dados: action.payload,
                upd_status: 3
            }
            
        case act.AGENTE_INCLUIDO:
            return {
                ...state, dados: action.payload,
                upd_status: 5
            }

        case act.AGENTE_PESQUISADO:
            return { 
                ...state, 
                pesquisa: action.payload, 
                upd_status: (typeof(action.payload) === 'object' && action.payload['id_pessoa']) ? 1 : 2 
            }
    
        default:
            return state
    }
}


const acesso_prepare = (dados) => {
    let classe = (dados['classe'] || '').toLowerCase()
    if (classe.includes('agente')) dados['acesso_agente'] = 1
    if (classe.includes('chofer')) dados['acesso_chofer'] = 1
    if (classe.includes('manobrista')) dados['acesso_manobrista'] = 1
    if (classe.includes('movifrota')) dados['acesso_movifrota'] = 1
    if (classe.includes('transporte')) dados['acesso_transporte'] = 1
    if (classe.includes('limpeza')) dados['acesso_limpeza'] = 1
    return dados
}
