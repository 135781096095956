import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Row, Grid } from '../common/layout'
import { ContentSection } from '../common/layout'
import { ButtonInfo, ButtonWarning } from '../common/ui/buttons'
import { LabelInfo } from '../common/ui/labels'

import CardProfile, { Item } from '../common/widget/card-profile'
import { modal_open } from '../common/ui/modal'
import OSPagaStatus from './os-paga-status'
import OSPagaGerarLote from './os-paga-gerar-lote'
import OSPagaServicosDetalhe from './os-paga-servico-detalhe';
import OsPagaServicoFilterForm from './os-paga-servico-filter'
import SendReport from '../tools/send-report'

import os_servico_card from '../os-servico/os-servico-card'

import { date_loc, money_loc } from '../sys/normalize-location'

import { obter, listarServicos, listar_mais, criticar } from './os-paga-action'
import { obter as obterDetalhe } from '../os-servico/os-servico-action'


import config from '../main/config'

class OSPagaFichaShow extends Component {


    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_paga'])
        this.props.listarServicos({ id_paga: params['id_paga'] })
    }

    render_perfil() {
        const img_perfil_defa = config().IMAGE_PATH + 'paga.png'
        return (
            <CardProfile
                image={img_perfil_defa}
                name={(this.props.os_paga.dados['cliente'] || '') + ' ' + this.props.os_paga.dados['documento']}
            >
                <Item label="Período" value={date_loc(this.props.os_paga.dados['dia_inicio']) + ' à ' + date_loc(this.props.os_paga.dados['dia_final'])}>
                    <LabelInfo title={this.props.os_paga.dados['sit_paga_label']} align_class="pull-right" />
                </Item>
                <Item label="Referência" value={this.props.os_paga.dados['referencia'] || ''}></Item>
                <Item label="Preço" value={money_loc(this.props.os_paga.dados['preco'] || 0)}></Item>
                <Item label="Preço adicional" value={money_loc(this.props.os_paga.dados['preco_adicional'] || 0)}></Item>
                <Item label="Preço abater" value={money_loc(this.props.os_paga.dados['preco_abater'] || 0)}></Item>
                <Item label="Cobrar HE" value={money_loc(this.props.os_paga.dados['he_cobrar'] || 0)}></Item>

                <Item label="Custo" value={money_loc(this.props.os_paga.dados['custo'] || 0)}></Item>
                <Item label="Custo Adicional Total" value={money_loc(this.props.os_paga.dados['custo_adicional_total'])}></Item>
                <Item label="Combustível" value={money_loc(this.props.os_paga.dados['combustivel'])}></Item>
                <Item label="Estacionamento" value={money_loc(this.props.os_paga.dados['estacionamento'])}></Item>
                <Item label="Lavagem" value={money_loc(this.props.os_paga.dados['lavagem'])}></Item>
                <Item label="Pedágio" value={money_loc(this.props.os_paga.dados['pedagio'])}></Item>
                <Item label="Condução" value={money_loc(this.props.os_paga.dados['conducao'])}></Item>
                <Item label="Alimentação" value={money_loc(this.props.os_paga.dados['alimentacao'])}></Item>
                <Item label="Outros" value={money_loc(this.props.os_paga.dados['outros'])}></Item>

                <Item label="Adiantamento Ag." value={money_loc(this.props.os_paga.dados['adiantamento_agente'])}></Item>
                <Item label="Reembolsar Ag." value={money_loc(this.props.os_paga.dados['reembolsar_agente'])}></Item>
                <Item label="Descontar Ag." value={money_loc(this.props.os_paga.dados['descontar_agente'])}></Item>
                <Item label="Pagar HE Ag." value={money_loc(this.props.os_paga.dados['he_pagar'])}></Item>
                <Item label="Total a Pagar" value={money_loc(this.props.os_paga.dados['total_pagar'])}></Item>
                <Item label="Total a Cobrar" value={money_loc(this.props.os_paga.dados['total_cobrar'])}></Item>

                <ButtonInfo align_class="btn-block" onClick={() => {
                    this.props.criticar(this.props.os_paga.dados['id_paga'])
                }}>Criticar</ButtonInfo>
                <ButtonInfo align_class="btn-block" onClick={() => { modal_open('os-paga-status') }}>Mudar Status</ButtonInfo>
                <ButtonInfo align_class="btn-block" onClick={() => { modal_open('paga-custo-send-modal') }}>Relatório de Pagamentos</ButtonInfo>
                <ButtonWarning align_class="btn-block" onClick={() => { modal_open('os-paga-gerar-lote') }}>Gerar Lote de Pagamento</ButtonWarning>
            </CardProfile>
        )
    }


    render() {

        return (
            <PageSetup title='Ficha do Lote'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.render_perfil()}
                        </Grid>
                        <Grid cols="md-8">
                            <OsPagaServicoFilterForm/>
                            {os_servico_card(this.props.os_paga.servico)}

                            {(this.props.os_paga.tem_mais) &&
                                <ButtonInfo onClick={() => {
                                    this.props.listar_mais(this.props.os_paga.page + 1, this.props.os_paga.dados['id_paga'] || 0, this.props.os_paga.filtro_servico)
                                }}>Carregar mais...</ButtonInfo>
                            }

                            {/**
                        <OSPagaServicoList
                                dados={this.props.os_paga.servico}
                                obterDetalhe={(id_servico) => { this.props.obterDetalhe(id_servico) }}
                                />
 */}
                        </Grid>
                    </Row>
                    <OSPagaStatus />
                    <OSPagaGerarLote />
                </ContentSection>
                <OSPagaServicosDetalhe />

                <SendReport
                    title='Encaminhar Relatório de Pagamentos'
                    id='paga-custo-send-modal'
                    param={{
                        route: 'os-paga-custo-print',
                        id_paga: this.props.os_paga.dados['id_paga'] || 0
                    }}
                />

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({
    os_paga: state.os_paga,
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter, obterDetalhe, listarServicos, listar_mais, criticar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSPagaFichaShow)
