import React from 'react'
import { ResponsiveCirclePacking } from '@nivo/circle-packing'
import { useHistory } from 'react-router-dom';
import { radar_filter_save } from '../../radar/radar-filter-persist'

export function CirclePacking({ data, context }) {
    // const [zoomedId, setZoomedId] = useState(null)
    const history = useHistory()
    return (
        <div style={{ width: 800, height: 800, margin: "auto" }}>
            <ResponsiveCirclePacking
                data={data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                id="name"
                value="qtd"
                valueFormat={value =>
                    `${Number(value).toLocaleString('pt-BR', {
                        minimumFractionDigits: 0,
                    })} `
                }
                colors={{ scheme: 'nivo' }}
                colorBy='id'
                childColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'brighter',
                            0.4
                        ]
                    ]
                }}
                padding={4}
                Xtooltip={({ id, value, color, data }) => (
                    <strong>
                        {data?.name}
                    </strong>
                )}
                enableLabels={true}
                label={(d) => d.data?.name}
                labelsFilter={function (n) { return 1 === n.node.depth }}
                labelsSkipRadius={10}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.5
                        ]
                    ]
                }}
                defs={[
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'none',
                        color: 'inherit',
                        rotation: -45,
                        lineWidth: 5,
                        spacing: 8
                    }
                ]}
                motionConfig="slow"
                onClick={node => {
                    //console.log(node.data?.name);
                    radar_filter_save({
                        field: 'cliente',
                        field_value: node.data?.name
                    })
                    history.push('/radar/loja_semana')

                    ///setZoomedId(zoomedId === node.id ? null : node.id)
                }}
            />
        </div>

    )
}

