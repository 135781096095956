import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'

import { Input, SelectGroup } from '../common/form'
import { BoxSimple } from '../common/widget/box'

import {listar} from './os-fatura-action'

class OSFaturaFilter extends Component {

    onSubmit(values) {
        let dados = {
            field: values.pesqcampo,
            value: values.pesqvalor,
            sit_fatura: values.pesq_sit_fatura
        }
        this.props.listar(dados)
    }


    render() {

        const { handleSubmit } = this.props

        return(
            <BoxSimple>
                <form onSubmit={handleSubmit( values => this.onSubmit(values))}>

                    <Field name="pesq_sit_fatura" component={SelectGroup} defaultValue="sit_fatura">
                        <option value="">Todos</option>
                        <option value="3">Processando</option>
                        <option value="7">Finalizado</option>
                        <option value="9">Cancelado</option>
                    </Field>

                    <Field name="pesqcampo" component={SelectGroup} defaultValue="cliente">
                        <option value="cliente">Cliente</option>
                        <option value="documento">Documento</option>
                        <option value="referencia">Referencia</option>
                    </Field>

                    <div className="input-group input-group-sm">
                        <Field name="pesqvalor" component={Input} className="form-control"/>
                        <span className="input-group-btn">
                            <button type="submit" className="btn btn-info btn-flat">GO!</button>
                        </span>
                    </div>

                </form>
            </BoxSimple>

        )
    }

}

OSFaturaFilter = reduxForm({
    form: 'osFaturaPesquisaForm',
    initialValues: {pesqcampo: 'cliente'}
})(OSFaturaFilter)

const mapStateToProps = state => ({os_fatura: state.os_fatura})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSFaturaFilter)
