import React from 'react'

import SimpleTable from '../common/tables/simple-table'
import { date_loc, money_loc } from '../sys/normalize-location'

export default  props => (
    <SimpleTable
        title="Pagamentos"
        columns={[
            { name: 'nome', title: 'Nome'},
            { name: 'cpf_cnpj', title: 'Documento'},
            { name: 'vl_pag', title: 'Valor', value: ((row)=>{return money_loc(row['vl_pag'])}) },
            { name: 'dt_pag', title: 'Data Prog.', value: ((row)=>{return date_loc(row['dt_pag'])}) },
            { name: 'tipo', title: 'Tipo'},
            { name: 'banco', title: 'Banco'},
            { name: 'agencia', title: 'Agencia'},
            { name: 'conta', title: 'Conta'},
            { name: 'conta_dig', title: 'Dig.'},
            { name: 'doc', title: 'Origem'},
            { name: 'ocorrencias', title: 'Ocorrencias' },
        ]}
        data={props.dados}
        row_key={(row) => ('lote_cnab_items' + row['doc'])}
    >
    </SimpleTable>
)
