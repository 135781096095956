import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { logout } from '../login/login-actions'

import MenuItem from '../common/template/menu-item'

class MenuPrincipal extends Component {

    tem_acesso(acesso) {
        let classe = ((this.props.login.usuario['classe'] || '').split(',') || []).filter(i => i)
        for (let i in classe) {
            if (acesso.includes(classe[i])) {
                return true
            }
        }
        return false
    }

    render() {
        return (
            <React.Fragment>
                <MenuItem route="/home" label="Home" icon="fa fa-home" />
                <MenuItem route="/perfil-show" label="Meu Perfil" icon="glyphicon glyphicon-user" />
                {this.tem_acesso('admin,comercial,apoio') &&
                    <MenuItem route="/missao" label="Missões" icon="fa fa-rocket" />
                }
                {this.tem_acesso('admin,operador,apoio') &&
                    <MenuItem route="/radar" label="Radar de Serviços" icon="fa fa-feed" />
                }
                {this.tem_acesso('admin,operador,apoio') &&
                    <MenuItem route="/aprovadas" label="Aprovadas" icon="glyphicon glyphicon-exclamation-sign" />
                }               
                {this.tem_acesso('admin') &&
                    <MenuItem route="/operador" label="Operadores" icon="fa fa-user" />
                }
                {this.tem_acesso('admin') &&
                    <MenuItem route="/solicitante" label="Solicitante" icon="glyphicon glyphicon-sunglasses" />
                }
                {this.tem_acesso('admin,apoio') &&
                    <MenuItem route="/agente" label="Agentes" icon="fa fa-group" />
                }
                {this.tem_acesso('admin,comercial,apoio') &&
                    <MenuItem route="/cliente" label="Clientes" icon="fa fa-male" />
                }
                {this.tem_acesso('admin,finan') &&
                    <MenuItem route="/paga" label="Pagamentos" icon="fa fa-money" />
                }
                {this.tem_acesso('admin,finan') &&
                    <MenuItem route="/fatura" label="Faturamento" icon="fa fa-dollar" />
                }
                {this.tem_acesso('admin,finan') &&
                    <MenuItem route="/cnab" label="CNAB" icon="fa fa-bank" />
                }
                {this.tem_acesso('admin,apoio') &&
                    <MenuItem route="/os-ref" label="Tabelas" icon="fa fa-table" />
                }
                
                <MenuItem label="Sair" icon="fa fa-close" onClick={this.props.logout} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({ login: state.login })
const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MenuPrincipal)
