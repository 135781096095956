export default {
    APP_DATA_UPDATE: 'app_data_update',
    TOKEN_VALIDADO: 'token_valido',
    USUARIO_CARREGADO: 'usuario_carregado',
    USUARIO_INVALIDO: 'usuario_invalido',
    USUARIO_TROCA_INVALIDA: 'usuario_troca_invalida',
    GO_LOGIN_ESQUECI: 'go_login_esqueci',
    GO_USER_STATUS_CHANGE: 'go_user_status_change',
    GO_AGREE: 'go_agree',
    GO_AGREE_LGPD: 'go_agree_lgpd',

    PERFIL_FOTO_CROP: 'perfil_foto_crop',
    PERFIL_CARREGADO: 'perfil_carregado',
    FICHA_CARREGADA: 'ficha_carregada',
    FICHA_QUEST_CARREGADOS: 'ficha_quest_carregados',  
    FICHA_PERGUNTAS_CARREGADAS: 'ficha_perguntas_carregadas',  
    FICHA_AVALIACAO_CARREGADAS: 'ficha_avaliacao_carregadas',  

    OPERADOR_CARREGADO: 'operador_carregado',
    OPERADOR_PESQUISADO: 'operador_pesquisado',
    OPERADOR_LISTADO: 'operador_listado',
    OPERADOR_SALVO: 'operador_salvo',
    OPERADOR_INCLUIDO: 'operador_incluido',
    OPERADOR_CONVIDADO: 'operador_convidado',

    SOLICITANTE_CARREGADO: 'solicitante_carregado',
    SOLICITANTE_PESQUISADO: 'solicitante_pesquisado',
    SOLICITANTE_LISTADO: 'solicitante_listado',
    SOLICITANTE_SALVO: 'solicitante_salvo',
    SOLICITANTE_INCLUIDO: 'solicitante_incluido',
    SOLICITANTE_CONVIDADO: 'solicitante_convidado',

    SOLICITANTE_CLIENTE_LISTADA: 'solicitante_cliente_listada',
    SOLICITANTE_CLIENTE_RESERVA: 'solicitante_cliente_reserva',

    AGENTE_CARREGADO: 'agente_carregado',
    AGENTE_PESQUISADO: 'agente_pesquisado',
    AGENTE_LISTADO: 'agente_listado',
    AGENTE_SALVO: 'agente_salvo',
    AGENTE_INCLUIDO: 'agente_incluido',
    AGENTE_CONVIDADO: 'agente_convidado',

    CLIENTE_CARREGADO: 'cliente_carregado',
    CLIENTE_PESQUISADO: 'cliente_pesquisado',
    CLIENTE_LISTADO: 'cliente_listado',
    CLIENTE_SALVO: 'cliente_salvo',    
    CLIENTE_INCLUIDO: 'cliente_incluido',

    OS_CARREGADO: 'os_carregado',
    OS_PESQUISADO: 'os_pesquisado',
    OS_LISTADO: 'os_listado',
    OS_LISTADO_MAIS: 'os_listado_mais',
    OS_LISTADO_ADD_PAGE: 'os_listado_add_page',
    OS_LISTADO_SAVE_FILTRO: 'os_listado_save_filtro',
    OS_SALVO: 'os_salvo',    
    OS_INCLUIDO: 'os_incluido',
    OS_TIMELINE_CARREGADO: 'os_timeline_carregado',
    OS_CONTADOR_CARREGADO: 'os_contador_carregado',
    OS_NOTHING: 'os_nothing',

    OS_SERVICO_CARREGADA: 'os_servico_carregado',
    OS_SERVICO_PESQUISADA: 'os_servico_pesquisado',
    OS_SERVICO_LISTADA: 'os_servico_listado',
    OS_SERVICO_SALVA: 'os_servico_salvo',
    OS_SERVICO_CANCELADO: 'os_servico_cancelado',
    OS_SERVICO_FINALIZADO: 'os_servico_finalizado',
    OS_SERVICO_LIMPAR: 'os_servico_limpar',
    OS_SERVICO_DADOS_ATUALIZAR: 'os_servico_atualizar',

    OS_APROVADAS_LISTADO: 'os_aprovadas_listado',

    OS_SERVICO_RECIBO_LISTADA: 'os_servico_recibo_listado',

    OS_REF_CARREGADO: 'os_ref_carregado',
    OS_REF_LISTADO: 'os_ref_listado',
    OS_REF_SALVO: 'os_ref_salvo',    
    OS_REF_INCLUIDO: 'os_ref_incluido',
    OS_REF_LIMPAR: 'os_ref_limpar',

    OS_FATURA_CARREGADO: 'os_fatura_carregado',
    OS_FATURA_SIMULADO: 'os_fatura_simulado',
    OS_FATURA_LISTADO: 'os_fatura_listado',
    OS_FATURA_SERVICO_LISTADO: 'os_fatura_servico_listado',
    OS_FATURA_SERVICO_LISTADO_MAIS: 'os_fatura_servico_listado_mais',
    OS_FATURA_SERVICO_LISTADO_ADD_PAGE: 'os_fatura_servico_listado_add_page',    

    OS_FATURA_SALVO: 'os_fatura_salvo',    
    OS_FATURA_INCLUIDO: 'os_fatura_incluido',

    OS_PAGA_CARREGADO: 'os_paga_carregado',
    OS_PAGA_SIMULADO: 'os_paga_simulado',
    OS_PAGA_LISTADO: 'os_paga_listado',
    OS_PAGA_SERVICO_LISTADO: 'os_paga_servico_listado',
    OS_PAGA_SERVICO_SAVE_FILTRO: 'os_paga_servico_save_filtro',
    OS_PAGA_SERVICO_LISTADO_MAIS: 'os_paga_servico_listado_mais',
    OS_PAGA_SERVICO_ADD_PAGE: 'os_paga_servico_add_page',    

    OS_PAGA_SALVO: 'os_paga_salvo',    
    OS_PAGA_INCLUIDO: 'os_paga_incluido',

    DASH_CONTADOR_CARREGADO: 'dash_contador_carregado',

    INSCRICAO_CARREGADA: 'inscricao_carregada',
    INSCRICAO_PESQUISADA: 'inscricao_pesquisada',
    INSCRICAO_LISTADA: 'inscricao_listada',
    INSCRICAO_SALVA: 'inscricao_salva',
    INSCRICAO_INCLUIDA: 'inscricao_incluida',

    MENSAGENS_CARREGADAS: 'mensagens_carregadas',
    MENSAGEM_SET_ID_MENSAGEM: 'mensagem_set_id_mensagem',

    RADAR_LISTADO: 'RADAR_listado',
    RADAR_LISTADO_SAVE_FILTRO: 'RADAR_listado_save_filtro',
    RADAR_LISTADO_MAIS: 'RADAR_listado_mais',
    RADAR_ADD_PAGE: 'RADAR_add_page',
    RADAR_SERVICO_ATUAL: 'RADAR_servico_atual',
    RADAR_SALVO: 'RADAR_salvo',

    LOTE_CNAB_LISTADO: 'lote_cnab_listado',
    LOTE_CNAB_ITEMS_LISTADO: 'lote_cnab_items_listado',
    LOTE_CNAB_ITEMS_LISTADO_MAIS: 'lote_cnab_items_listado_mais',
    LOTE_CNAB_ITEMS_ADD_PAGE: 'lote_cnab_items_add_page',
    LOTE_CNAB_CARREGADO: 'lote_cnab_carregado',
    LOTE_CNAB_SALVO: 'lote_cnab_salvo',
    LOTE_CNAB_INCLUIDO: 'lote_cnab_incluido',

}
