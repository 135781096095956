import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'

import { FormRow, Input, SelectGroup } from '../common/form'
import { BoxSimple } from '../common/widget/box'

import { listarServicos } from './os-paga-action'
class OSPagaServicoFilter extends Component {

    onSubmit(values) {
        let dados = {
            id_paga: this.props.os_paga?.dados?.id_paga,
            field: values.pesqcampo,
            value: values.pesqvalor,
            sit_servico: values.sit_servico,
        }
        this.props.listarServicos(dados)
    }

    render() {

        const { handleSubmit } = this.props

        return (
            <BoxSimple>
                <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                    <FormRow>
                        <Field name="sit_servico" component={SelectGroup} defaultValue="" cols='md-2'>
                            <option value="">Todos</option>
                            <option value="-7">Criticados</option>
                        </Field>


                        <Field name="pesqcampo" component={SelectGroup} defaultValue="agente" cols='md-2'>
                            <option value="agente">Agente</option>
                            <option value="cliente">Loja</option>
                            <option value="os_numero">OS</option>
                        </Field>

                        <div className="input-group input-group-sm">
                            <Field name="pesqvalor" component={Input} className="form-control" />
                            <span className="input-group-btn">
                                <button type="submit" className="btn btn-info btn-flat">GO!</button>
                            </span>
                        </div>
                    </FormRow>
                </form>
            </BoxSimple>

        )
    }

}

OSPagaServicoFilter = reduxForm({
    form: 'osPagaServicoFilterForm',
    initialValues: { pesqcampo: 'agente' }
})(OSPagaServicoFilter)

const mapStateToProps = state => ({ os_paga: state.os_paga })
const mapDispatchToProps = dispatch => bindActionCreators({ listarServicos }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSPagaServicoFilter)
