import act from '../main/action-const'

const INITIAL_STATE = {
}
/*
const colors = [
    'hsl(235, 70%, 50%)',
    'hsl(67, 70%, 50%)',
    'hsl(248, 70%, 50%)',
    'hsl(214, 70%, 50%)',
    'hsl(3, 70%, 50%)',
    'hsl(124, 70%, 50%)',
    'hsl(42, 70%, 50%)',
    'hsl(120, 70%, 50%)',
    'hsl(155, 70%, 50%)',
    'hsl(346, 70%, 50%)',
    'hsl(329, 70%, 50%)',
    'hsl(197, 70%, 50%)',
    'hsl(152, 70%, 50%)',
    'hsl(40, 70%, 50%)',
    'hsl(17, 70%, 50%)',
    'hsl(201, 70%, 50%)',
    'hsl(337, 70%, 50%)',
    'hsl(206, 70%, 50%)',
    'hsl(60, 70%, 50%)',
    'hsl(202, 70%, 50%)',
    'hsl(26, 70%, 50%)',
    'hsl(122, 70%, 50%)'
]
*/
function work_pack(data) {
    if(data['total_srv_pacote']) {
        const pacote = data['total_srv_pacote']
        data['total_srv_pacote'] = {
            name: "Serviços da Semana",
            color: "hsl(290, 70%, 50%)",
            children: pacote
        }
    }
    return data
}

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.DASH_CONTADOR_CARREGADO:
            return { ...state, ...work_pack(action.payload) }

        default:
            return state
    }
}
