import React, { Component } from 'react'
import { Field } from 'redux-form'

import { BoxTitle, BoxBody } from '../common/widget/box'
import { InputGroup, SelectGroup, FormRow } from '../common/form'
import { toastr } from 'react-redux-toastr'

import { recuperar_dados_sync } from '../sys/transmitir'
import { cep_mask } from '../sys/normalize-mask'

export const endereco_push  = (values, dados) => {
    dados['regiao'] = values.regiao
    dados['cep'] = values.cep
    dados['logradouro'] = values.logradouro
    dados['numero'] = values.numero
    dados['complemento'] = values.complemento
    dados['uf'] = values.uf
    dados['bairro'] = values.bairro
    dados['cidade'] = values.cidade
}


class EnderecoEdit extends Component {

    constructor(props) {
        super(props);
        this.cep_onGet = this.cep_onGet.bind(this)
        this.cep_onChange = this.cep_onChange.bind(this)
    }

    mudouCep = false;

    cep_onGet = (e,valor) => {
        
        if (this.mudouCep) {
            let data = []
            data['cep'] = valor;
            recuperar_dados_sync(data, 'get', 'cep').then(ret => {
                if (ret['logradouro']) {
                    this.props.change('logradouro', ret['logradouro'])
                    if (ret['bairro'])
                        this.props.change('bairro', ret['bairro'])
                    if (ret['cidade'])
                        this.props.change('cidade', ret['cidade'])
                    if (ret['uf'])
                        this.props.change('uf', ret['uf'])

                    return toastr.info('Pronto', 'Dados do CEP carregados!')
                }
                return toastr.warning('Ops!', 'Não foi encontrado dados para o CEP!')
            });
        }
        this.mudouCep = false
    
    }
    
    cep_onChange = (e) => {
        this.mudouCep = true
    }


    render() {

        return(
            <BoxTitle title="Endereço">
                <BoxBody>    
                    <FormRow>
                        <Field label='CEP' name="cep"  placeholder='CEP' component={InputGroup}  normalize={cep_mask} cols='md-2' onChange={(e) => this.cep_onChange(e)} onBlur={(e,novo)=>this.cep_onGet(e,novo)}/>
                        <Field label='Logradouro' name="logradouro"  placeholder='Logradouro' component={InputGroup}  cols='md-8' />
                        <Field label='Número' name="numero" placeholder='Número' component={InputGroup} cols='md-2' />
                    </FormRow>
                    <FormRow>
                        <Field label='Complemento' name="complemento" placeholder='Complemento do endereço' component={InputGroup}  cols='md-8' />
                        <Field label='Região' name="regiao" placeholder='Região' component={InputGroup}  cols='md-4' />
                    </FormRow>
                    <FormRow>
                        <Field label='Bairro' name="bairro" placeholder='Bairro' component={InputGroup}  cols='md-5' />
                        <Field label='Estado' name="uf" component={SelectGroup}  cols='md-2'>
                            <option/>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Brasília</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                            <option value="EX">Exterior</option>
                        </Field>
                        
                        <Field label='Cidade' name="cidade" placeholder='Cidade' component={InputGroup}  cols='md-5' />
                    </FormRow>
                </BoxBody>

            </BoxTitle>

        )
    }

}

export default EnderecoEdit

