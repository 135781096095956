import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { FormRow, InputTextAreaGroup } from '../common/form'

import { anotar_os } from './os-action'

import Modal from '../common/ui/modal'

const validarFormulario = (values) => {
    const erros = {}

    return erros
}

class OSAnotacao extends Component {

    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        let dados = {
            id_os: this.props.os.dados['id_os'],
            comentario: values.comentario
        }
        this.props.anotar_os(dados)
    }

    handleStateChange = () => {
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                <Modal
                    id='os-anotacao'
                    title="Anotação"
                    confirmLabel='Confirmar'
                    confirmButtonType='submit'
                    onConfirm={() => { return true }}
                    onClose={() => { this.props.limpar() }}
                >

                    <FormRow>
                        <Field label='Comentário' name="comentario" required rows={2} component={InputTextAreaGroup} maxLength={250} cols='md-12' />
                    </FormRow>
                    <div className="clearfix"></div>

                </Modal>
            </form>
        )
    }

}

OSAnotacao = reduxForm({
    form: 'os_anotacaoForm',
    validate: validarFormulario,
    enableReinitialize: true
})(OSAnotacao)


const mapStateToProps = state => ({
    os_servico: state.os_servico,
    os: state.os,
    initialValues: state.os_servico.dados
})
const mapDispatchToProps = dispatch => bindActionCreators({ anotar_os }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSAnotacao)

