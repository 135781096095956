import act from '../main/action-const'

const INITIAL_STATE = { 
    dados: {},
    lista: [],
    pesquisa: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
}

const DADOS_CLEAR = {
    id_ref: 0,
    ref: '',
    descricao: '',
    preco: 0.0,
    custo: 0.0,
    tipo: 1
}

export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.OS_REF_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0

            }

        case act.OS_REF_CARREGADO:
            return { ...state, dados: action.payload, upd_status: 4 }

            
        case act.OS_REF_SALVO:
            return {
                ...state, dados: action.payload,
                upd_status: 3
            }

        case act.OS_REF_INCLUIDO:
            return {
                ...state, dados: action.payload,
                upd_status: 5
            }
    
        case act.OS_REF_LIMPAR:
            return  { ...state, dados: DADOS_CLEAR }
    
    
        default:
            return state
    }
}
