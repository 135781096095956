import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'

import { remover } from './solicitante-cliente-action'

import Modal from '../common/ui/modal'


class SolicitanteClienteRemover extends Component {

    onSubmit(values) {
        let vo = {
            id_solicitante: this.props.sc.data['id_solicitante'], 
            id_cliente: this.props.sc.data['id_cliente']
        }
        this.props.remover(vo)
    }


    render() {
        const { handleSubmit } = this.props
        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
            <Modal 
                id='solicitante-cliente-remover' 
                title="Remover" 
                confirmLabel="Remover"
                confirmButtonType='submit'
                onConfirm={()=>{ return true }}
            >
                <p>Remover o cliente {this.props.sc.data['nome'] || ''} da lista do Solicitante?</p>

                <div className="clearfix"></div>

            </Modal>
            </form>
        )
    }
}

SolicitanteClienteRemover = reduxForm({
    form: 'solicitante_clienteRemoverForm',
})(SolicitanteClienteRemover)


const mapStateToProps = state => ({
    sc: state.solicitante_cliente
})
const mapDispatchToProps = dispatch => bindActionCreators({remover}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SolicitanteClienteRemover)

