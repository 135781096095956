import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'

import FloatButton from '../common/ui/float-button'
import { modal_open } from '../common/ui/modal'

import OSRefList from '../os-ref/os-ref-list'
import {listar, limpar, obter as obterOSRef} from './os-ref-action'


import OSRefFilter from './os-ref-filter'
import OSRefEdit from './os-ref-edit'

class OSRef extends Component {

    constructor(props) {
        super(props);
        this.props.listar()
    }

    render() {
        return(
            <PageSetup title='Tabela de Referências' subtitle='Consulta'>
                <ContentSection>
                    
                    <Row>
                        <Grid cols='md-3'>
                            <OSRefFilter/>
                        </Grid>
                        <Grid cols='md-9'>
                            <OSRefList
                                dados={this.props.os_ref.lista}
                                obterOSRef={(id_ref) => { this.props.obterOSRef(id_ref) }}
                            />
                        </Grid>
                    </Row>
                    <OSRefEdit/>
                </ContentSection>

                <FloatButton
                    onClick={() => {this.props.limpar(); modal_open('os-ref-edit')} } icon="fa fa-plus" tooltip={"Nova Referência"}
                />

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({os_ref: state.os_ref})
const mapDispatchToProps = dispatch => bindActionCreators({listar, limpar, obterOSRef}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSRef)
