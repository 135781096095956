import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { toastr } from 'react-redux-toastr'

import { InputGroup, FormRow } from '../common/form'
import Modal from '../common/ui/modal'
import { recuperar_dados_sync } from '../sys/transmitir'

const validarFormulario  = (values) => {
    const erros = {}

    if ( !values.nome || values.nome.length === 0 )
        erros.nome = 'Informe o nome de quem vai receber'

    if ( !values.email || values.email.length === 0 )
        erros.email = 'Informe o email de quem vai receber'

    return erros
}


class SendReport extends Component {

    constructor(props) {
        super(props);
        this.props.change("nome", this.props.perfil['nome'] || '')
        this.props.change("email", this.props.perfil['email'] || '')
    }

    onSubmit(values) {
        let vo = []
        vo['dest_nome'] = values.nome
        vo['dest_email'] = values.email
        let param = this.props.param || []
        let route = param['route'] || ''
        for ( let i in param) {
            vo[i] = param[i]
        }      
        if (vo['route']) {
            delete vo.route
        }
        recuperar_dados_sync(vo, 'get', route).then(ret =>
                toastr.info('Pronto', 'Preparamos o documento e encaminhamos, logo chega no e-mail!')
        );
    }

    render() {

        const { handleSubmit } = this.props

        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
            <Modal 
                id={this.props.id || ''}
                title={this.props['title'] || 'Encaminhar Relatório'} 
                confirmLabel='Enviar'
                confirmButtonType='submit'
                onConfirm={()=>{ return true }}
                onClose={()=>{return true}}
            >
                
                <FormRow>
                    <Field label='Nome' name="nome" placeholder="Nome" required disabled={false}  component={InputGroup}  cols='md-12' />
                </FormRow>
                <FormRow>
                    <Field label='Email' name="email" type="email" required placeholder="email" disabled={false}  component={InputGroup}  cols='md-12' />
                </FormRow>
                <div className="clearfix"></div>

            </Modal>
            </form>
        )
    }

}

SendReport = reduxForm({
    form: 'SendReportForm',
    validate: validarFormulario
})(SendReport)


const mapStateToProps = state => ({
    perfil: state.perfil,
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SendReport)

