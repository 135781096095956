import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import CardSecond, { Item } from '../common/widget/card-second'
import { BadgeYellow, BadgeBlue } from '../common/ui/badge'
import { FileUpload } from '../common/ui/fileupload'

import { date_loc, money_loc } from '../sys/normalize-location'

import { listar, carregar_arquivo_retorno } from './lote-cnab-action'
import config from '../main/config'


import LoteCnabFilter from './lote-cnab-filter'
import { BoxSimple } from '../common/widget/box'

class LoteCnab extends Component {

    constructor(props) {
        super(props);
        this.handle_salvar_arquivo = this.handle_salvar_arquivo.bind(this)
        this.props.listar()
    }

    renderLoteCnab(dados) {
        const img_perfil_defa = config().IMAGE_PATH + 'lote-cnab.png'

        return dados.map(vo => {
            const img_perfil = (img_perfil_defa)
            return (
                <CardSecond
                    key={'lote_cnab' + vo['id_lote']}
                    title={vo['referencia'] + ' | ' + vo['origem'] + ':' + (vo['id_origem'] || '')}
                    image={img_perfil}
                    col_class="col-md-6"
                    color_class={vo['sit_lote'] === '7' ? 'bg-aqua' : (vo['sit_lote'] === '9' ? 'bg-black' : 'bg-fuchsia')}
                    minHeightHeader={'110px'}
                    route={`/cnab-ficha/${vo['id_lote']}`}
                >
                    <Item text='Referência'> <BadgeBlue title={vo['sit_lote_label']} /> <BadgeYellow title={vo['referencia']} /></Item>
                    <Item text='Dia Programado'> <BadgeYellow title={'Dia | ' + date_loc(vo['dia_prog_quitacao'])} /></Item>
                    <Item text="Valores">
                        <BadgeYellow title={'Registros | ' + (vo['total_registros'] || 0)} />
                        <BadgeYellow title={'Quitado | ' + money_loc(vo['valor_quitado'] || 0)} />
                        <BadgeYellow title={'Lote | ' + money_loc(vo['valor_lote'] || 0)} />
                    </Item>
                    <Item text='Arquivo'> <BadgeBlue title={vo['arquivo']} /></Item>
                </CardSecond>
            )
        })
    }

    handle_salvar_arquivo(data) {        
        this.props.carregar_arquivo_retorno(data)
    }


    render_upload() {
        return (
            <BoxSimple>
                <FileUpload btn_class="info pull-right" label='Carregar Novo Arquivo CNAB de Retorno'
                    mimeType={['text/plain', 'text/*']}
                    capture='environment'
                    maxFileSize={5}
                    afterSelectFile={(data) => { this.handle_salvar_arquivo(data) }}
                />
            </BoxSimple>
        )
    }

    render() {
        return (
            <PageSetup title='Lote CNAB' subtitle='Consulta'>
                <ContentSection>
                    <Row>
                        <Grid cols='md-3'>
                            <LoteCnabFilter />
                            {this.render_upload()}
                        </Grid>
                        <Grid cols='md-9'>
                            {this.renderLoteCnab(this.props.lote_cnab.lista)}
                        </Grid>
                    </Row>
                </ContentSection>

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({ lote_cnab: state.lote_cnab })
const mapDispatchToProps = dispatch => bindActionCreators({ listar, carregar_arquivo_retorno }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoteCnab)
