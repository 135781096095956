import act from '../main/action-const'

const INITIAL_STATE = { 
    dados: {},
    lista: [],
    pesquisa: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
}

export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.CLIENTE_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0

            }

        case act.CLIENTE_CARREGADO:
            return { ...state, dados: action.payload, upd_status: 4 }

            
        case act.CLIENTE_SALVO:
            return {
                ...state, dados: action.payload,
                upd_status: 3
            }

        case act.CLIENTE_INCLUIDO:
            return {
                ...state, dados: action.payload,
                upd_status: 5
            }
    
        case act.CLIENTE_PESQUISADO:
            return { 
                ...state, 
                pesquisa: action.payload, 
                upd_status: (typeof(action.payload) === 'object' && action.payload['id_pessoa']) ? 1 : 2 
            }
    
        default:
            return state
    }
}
