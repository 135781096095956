import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Row, Grid } from '../common/layout'
import { ContentSection } from '../common/layout'
import { ButtonPrimary, ButtonInfo } from '../common/ui/buttons'
import { Link } from 'react-router-dom'
import { modal_open } from '../common/ui/modal'

import CardProfile, { Item } from '../common/widget/card-profile'

import PessoaAcessoLabels from '../pessoa/pessoa-acesso-labels'
import PessoaTrocaEmail from '../pessoa/pessoa-troca-email'
import SolicitanteClienteList from '../solicitante-cliente/solicitante-cliente-list'
import SolicitanteClienteAdd from '../solicitante-cliente/solicitante-cliente-add'
import SolicitanteClienteRemover from '../solicitante-cliente/solicitante-cliente-remover'

import { obter } from '../ficha/ficha-action'
import { listar as listar_clientes, reservar as reservar_cliente } from '../solicitante-cliente/solicitante-cliente-action'

import config from '../main/config'

class SolicitanteShow extends Component {


    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_pessoa'])        
        this.props.listar_clientes(params['id_pessoa'])
    }

    render_perfil() {
        const img_perfil = (this.props.ficha['foto_file']) ? (this.props.login.usuario['cdn_endpoint'] + this.props.ficha['foto_file']) : (config().IMAGE_PATH + 'profile.jpg')
        return (
            <CardProfile
                image={img_perfil}
                name={this.props.ficha['nome'] || ''}
            >
                <Item label="Documento" value={this.props.ficha['documento']}></Item>
                <Item label="Celular" value={this.props.ficha['celular']}></Item>
                <Item label="E-mail" value={this.props.ficha['email']}></Item>
                <Item>
                    <PessoaAcessoLabels classe={(this.props.ficha['classe'] || '')} />
                </Item>
                
                <Link to={`/solicitante-edit/${this.props.ficha['id_pessoa']}`} className="btn btn-primary btn-block">Editar</Link>


                <ButtonPrimary align_class='btn-block' onClick={() => { modal_open('pessoa-troca-email-modal') }}>Trocar Email</ButtonPrimary>

                <ButtonInfo align_class="btn-block" onClick={() => { modal_open('solicitante-cliente-add') }}>Adicionar Cliente</ButtonInfo>
                
            </CardProfile>
        )
    }

    render() {

        return (
            <PageSetup title='Ficha do Solicitante'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.render_perfil()}
                        </Grid>
                        <Grid cols="md-8">
                            <SolicitanteClienteList                                 
                                dados={this.props.solicitante_cliente.lista}
                                reservar={(dados) => { this.props.reservar_cliente(dados) }}
                            />
                        </Grid>

                    </Row>
                </ContentSection>
                <SolicitanteClienteAdd/>
                <SolicitanteClienteRemover/>
                <PessoaTrocaEmail/>

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({
    login: state.login,
    ficha: state.ficha,
    solicitante_cliente: state.solicitante_cliente
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter, listar_clientes, reservar_cliente }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SolicitanteShow)
