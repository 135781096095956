import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Input2, SelectGroup2 } from '../common/form'
import { BoxSimple } from '../common/widget/box'

import { listar } from './os-action'
import { os_filter_read, os_filter_save } from './os-filter-persist'

const filter_init = {
    dia: '',
    ate: '',
    periodo_tipo: '',
    ordem: 'natural',
    modalidade: '',
    origem: '',
    field: 'cliente',
    field_value: '',
    sit_os: '',
    sit_negociacao: ''
}


function get_filter_predefinido(tipo) {
    if (tipo === 'contatos_para_hoje')
        return {
            dia: '',
            ate: '',
            periodo_tipo: '',
            ordem: 'natural',
            modalidade: '',
            origem: '',
            field: 'cliente',
            field_value: '',
            sit_os: '',
            sit_negociacao: '-10'
        }

    if (tipo === 'contatos_vencidos')
        return {
            dia: '',
            ate: '',
            periodo_tipo: '',
            ordem: 'natural',
            modalidade: '',
            origem: '',
            field: 'cliente',
            field_value: '',
            sit_os: '',
            sit_negociacao: '-20'
        }

    if (tipo === 'criados_hoje')
        return {
            dia: '',
            ate: '',
            periodo_tipo: '',
            ordem: 'natural',
            modalidade: '',
            origem: 'Comercial',
            field: 'cliente',
            field_value: '',
            sit_os: '',
            sit_negociacao: '-30'
        }

    if (tipo === 'criados_neste_mes')
        return {
            dia: '',
            ate: '',
            periodo_tipo: '10',
            ordem: 'natural',
            modalidade: '',
            origem: 'Comercial',
            field: 'cliente',
            field_value: '',
            sit_os: '',
            sit_negociacao: ''
        }

    return null
}



export default function OSFilter() {
    const dispatch = useDispatch()
    const [data, set_data] = useState(filter_init)
    const routeParams = useParams();

    useEffect(() => {
        console.log(routeParams?.act)
        let filtro = get_filter_predefinido(routeParams?.act)
        let go = true
        if (!filtro) {
            filtro = os_filter_read()
            go = false
        }
        set_data({ ...data, ...filtro })
        if (go) {
            dispatch(listar(filtro))
            os_filter_save(filtro)
        }
    }, [])


    const handleChange = (ev) => {
        const vo = {}
        if (ev.target?.type === 'checkbox') {
            vo[ev.target.name] = ev.target.checked ? ev.target.value : ''
        } else {
            vo[ev.target.name] = ev.target.value
        }
        set_data({ ...data, ...vo })
    }

    const onSubmit = (ev) => {

        ev.preventDefault()
        let vo = {
            dia: data.dia,
            ate: data.ate,
            periodo_tipo: data.periodo_tipo,
            field: data.field,
            value: data.field_value,
            sit_os: data.sit_os,
            sit_negociacao: data.sit_negociacao,
            ordem: data.ordem,
            modalidade: data.modalidade,
            origem: data.origem
        }
        dispatch(listar(vo))
        os_filter_save(vo)
    }


    return (
        <BoxSimple>
            <form onSubmit={onSubmit}>

                <SelectGroup2
                    name="sit_os"
                    value={data?.sit_os}
                    onChange={handleChange}
                >
                    <option value="">Todas Situações</option>
                    <option value="1">Em Edição</option>
                    <option value="3">Em Aprovação</option>
                    <option value="4">Aprovada</option>
                    <option value="5">Reprovada</option>
                    <option value="6">Em Tramite</option>
                    <option value="7">Finalizada</option>
                    <option value="9">Cancelada</option>
                </SelectGroup2>
                <SelectGroup2
                    name="periodo_tipo"
                    value={data?.periodo_tipo}
                    onChange={handleChange}
                >
                    <option value="">Qualquer Período</option>
                    <option value="1">Hoje</option>
                    <option value="2">Amanhã</option>
                    <option value="3">Ontem</option>
                    <option value="4">Esta Semana</option>
                    <option value="5">Proxima Semana</option>
                    <option value="6">Última Semana</option>
                    <option value="7" default>Está Quinzena</option>
                    <option value="8">Próxima Quinzena</option>
                    <option value="9">Quinzena Anterior</option>
                    <option value="10">Este Mês</option>
                    <option value="11">Proxima Mês</option>
                    <option value="12">Último Mês</option>
                    <option value="13">Últimos 15 dias</option>
                    <option value="14">Próximos 15 dias</option>
                    <option value="0">Informar Período</option>
                </SelectGroup2>


                < span style={{ display: data?.periodo_tipo === "0" ? 'block' : 'none' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px' }}>Dia</span>
                        <input
                            className='form-control'
                            name="dia"
                            defaultValue={data?.dia}
                            type="date"
                            onBlur={handleChange}
                        />
                    </span>
                    <br />
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px' }}>Até</span>
                        <input
                            className='form-control'
                            name="ate"
                            defaultValue={data?.ate}
                            type="date"
                            onBlur={handleChange}
                        />
                    </span>
                    <br />
                </span>

                <SelectGroup2
                    name="ordem"
                    onChange={handleChange}
                    value={data?.ordem}
                >
                    <option value="natural">ordem Natural</option>
                    <option value="ultima">primeiro as Últimas</option>
                    <option value="por-agente">por Agente</option>
                    <option value="por-cliente">por Cliente</option>
                    <option value="data">por Data</option>
                </SelectGroup2>

                <SelectGroup2
                    name="modalidade"
                    onChange={handleChange}
                    value={data?.modalidade}
                >
                    <option value="">Todas as Modalidades</option>
                    <option value="10">Fixo</option>
                    <option value="20">Pontual</option>
                    <option value="30">Sob Demanda</option>
                </SelectGroup2>

                <SelectGroup2
                    name="origem"
                    onChange={handleChange}
                    value={data?.origem}
                >
                    <option value="">Todos departamentos</option>
                    <option value="Comercial">Comercial</option>
                    <option value="Operaciona">Operaciona</option>
                </SelectGroup2>

                <SelectGroup2
                    name="sit_negociacao"
                    value={data?.sit_negociacao}
                    onChange={handleChange}
                >
                    <option value="">Qualquer Negociações</option>
                    <option value="10">Negociação Pendente</option>
                    <option value="15">Negociação Quente</option>
                    <option value="20">Negociação Fria</option>
                    <option value="25">Negociação Fechada</option>
                    <option value="50">Perdida por preço</option>
                    <option value="55">Perdida por falta de recurso</option>
                    <option value="60">Perdida por falta de agenda</option>
                    <option value="70">Perdida desistência do cliente</option>
                    <option value="-10">Contatos para Hoje</option>
                    <option value="-20">Contatos Vencidos</option>
                    <option value="-30">Criados Hoje</option>
                </SelectGroup2>

                <SelectGroup2
                    name="field"
                    value={data?.field}
                    onChange={handleChange}
                >
                    <option value="cliente">Cliente</option>
                    <option value="celular">Celular</option>
                    <option value="documento">Documento</option>
                    <option value="email">E-mail</option>
                    <option value="placa">Placa</option>
                    <option value="origem">Origem</option>
                    <option value="faturarPara">Faturar Para</option>
                </SelectGroup2>
                <div className="input-group input-group-sm">
                    <Input2
                        name="field_value"
                        onChange={handleChange}
                        value={data?.field_value}
                        className="form-control"
                    />
                    <span className="input-group-btn">
                        <button type="submit" className="btn btn-info btn-flat">GO!</button>
                    </span>
                </div>

            </form>
        </BoxSimple>

    )
}
