import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { Redirect } from 'react-router-dom'

import { init, incluir, pesquisar} from './os-action'
import { cel_mask, cpf_cnpj_mask } from '../sys/normalize-mask'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'

import { BoxTitle, BoxBody, BoxFooter } from '../common/widget/box'
import { InputGroupAddon, InputGroupAutocompleteAddon, FormRow } from '../common/form'
import { ButtonDefault, ButtonInfo } from '../common/ui/buttons'
import { CalloutWarning, CalloutSucess } from '../common/msg/callout'
import config from '../main/config'

const validarFormulario  = (values) => {
    const erros = {}

    if ( !values.nome || values.nome.length === 0 )
        erros.nome = 'Informe o nome'
        
    return erros
}


class OSAdd extends Component {

    //state = this.props.init()

    onSubmit(values) {
        let dados = {
            cliente: values.cliente,
            contato: values.contato,
            documento: values.documento,
            celular: values.celular,
            email: values.email
        }
        if (this.props.os.pesquisa && this.props.os.pesquisa['id_pessoa'])
            dados['id_pessoa'] = this.props.os.pesquisa['id_pessoa'];

        if (values.incluir) {
            this.props.incluir(dados)
        } else {
            this.props.pesquisar(dados)
        }
    }

    pessoa_onSelected = (item) => {
        this.props.change("cliente", item['nome'] || '')
        this.props.change("documento", item['documento'] || '')
        this.props.change("email", item['email'] || '')
        this.props.change("celular", item['celular'] || '')
    }



    renderFormulario() {
        const { handleSubmit, pristine, submitting } = this.props
        const endpoint_cliente_load = `${config().API_URL}/cliente-load&query=`
        return(
            <BoxTitle title="Nova Missão">
                <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
                <BoxBody>   
                    <FormRow>
                        <Field label='Pesquisa Cliente' name="cleintepesq" icon='fa fa-search' placeholder='Pesquisa de cliente' 
                                    url={endpoint_cliente_load}
                                    onSelected={(item) => this.pessoa_onSelected(item)}
                                    columnShow="nome"
                                    component={InputGroupAutocompleteAddon}  
                                    cols='md-3' 
                                    />
                        <br />
                    </FormRow>   
                    <FormRow>
                        <Field label='Cliente' name="cliente" icon='fa fa-user' placeholder='Nome do Cliente' required component={InputGroupAddon}  cols='md-4' />
                        <br/>
                        <Field label='Contato' name="contato" icon='fa fa-user' placeholder='Nome do Contato' required component={InputGroupAddon}  cols='md-4' />
                        <br/>
                        <Field label='Celular' name="celular" icon='fa fa-mobile' placeholder='Celular' component={InputGroupAddon}  normalize={cel_mask} cols='md-4' />
                        <br/>
                        <Field label='C.P.F./C.N.P.J.' name="documento" icon='fa fa-file-photo-o' placeholder='C.P.F./C.N.P.J.' component={InputGroupAddon}  normalize={cpf_cnpj_mask} cols='md-4' />
                        <br/>
                        <Field label='Email' name="email" type='email' icon='fa fa-envelope' placeholder='Email' required component={InputGroupAddon}  cols='md-4' />
                    </FormRow>
                </BoxBody>

                <BoxFooter>
                    <ButtonDefault type="reset" disabled={submitting}>Limpar</ButtonDefault>
                    <ButtonInfo type="submit" align_class="pull-right" disabled={pristine || submitting}>Pesquisar</ButtonInfo>
                    <br/><br/>

                    {this.props.os.upd_status === 1 &&  
                        <CalloutWarning title='Cliente Existente.'>
                            <p>{this.props.os.pesquisa['nome']}</p>
                            <p>{this.props.os.pesquisa['documento']}</p>
                            <p>{this.props.os.pesquisa['celular']}</p>
                            <div className="pull-left">
                                <ButtonDefault 
                                    onClick={handleSubmit(values => 
                                        this.onSubmit({ 
                                            ...values,
                                            incluir: true
                                        }))} 
                                    align_class="pull-right" disabled={pristine || submitting}>Cadastrar Nova Missão para este Cliente
                                </ButtonDefault>
                            </div>
                            <br /><br />
                        </CalloutWarning>
                        
                    }

                    {this.props.os.upd_status === 2 &&  
                        <CalloutSucess title='Nova Missão | Novo Cliente'>
                            <div className="pull-left">
                                <ButtonDefault 
                                    onClick={handleSubmit(values => 
                                        this.onSubmit({ 
                                            ...values,
                                            incluir: true
                                        }))} 
                                    align_class="pull-right" disabled={pristine || submitting}>Cadastrar Missão
                                </ButtonDefault>
                            </div>
                            <br /><br />
                        </CalloutSucess>
                    }                    
                </BoxFooter>

                </form>
            </BoxTitle>

        )

    }

    renderPagina() {
        return(
            <PageSetup title='Missões'>
                <ContentSection>
                    
                    <Row>
                        <Grid cols='md-4'>
                            {this.renderFormulario()}
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

    render() {
        if (this.props.os.upd_status === 5) {
            return <Redirect to={`/missao-edit/${this.props.os.dados['id_os']}`} />
        }
        return this.renderPagina()
    }
}

OSAdd = reduxForm({
    form: 'osAddForm',
    validate: validarFormulario
})(OSAdd)


const mapStateToProps = state => ({
    os: state.os
})
const mapDispatchToProps = dispatch => bindActionCreators({init, incluir, pesquisar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSAdd)

