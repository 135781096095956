import React from 'react'
import SimpleTable from '../common/tables/simple-table'
import { Anchor } from '../common/helper'
import { money_loc } from '../sys/normalize-location'

import { modal_open } from '../common/ui/modal'

const os_ref_edit_link = (obterOSRef, row) => {
    return (
    <Anchor onClick={() => {obterOSRef(row['id_ref'] || 0); modal_open('os-ref-edit')}}> {row['ref'] || ''} </Anchor>
    )
}

export default  props => (
    <SimpleTable
        title="Referências"
        columns={[
            { name: 'ref', title: 'Referência', value: ((row)=>{return os_ref_edit_link(props.obterOSRef, row)}) },
            { name: 'descricao', title: 'Descrição'},
            { name: 'preco', title: 'Preço', value: ((row)=>{return money_loc(row['preco'])}) },
            { name: 'custo', title: 'Custo', value: ((row)=>{return money_loc(row['custo'])}) },
            { name: 'tipo_label', title: 'Tipo'},
        ]}
        data={props.dados}
        row_key={(row) => ('id_ref' + row['id_ref'])}
    >
    </SimpleTable>
)
