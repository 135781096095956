import React from 'react'
import { LabelSucess, LabelDanger, LabelDefault, LabelInfo, LabelWarning } from '../common/ui/labels'

export const os_servico_situacao = (s, sf, tp) => {
    let label_financeiro
    switch (sf) {
        case '2': label_financeiro = () => { return <>| <LabelDanger title='em Pagamento' /></> }; break
        case '3': label_financeiro = () => { return <>| <LabelWarning title='Pag.Processado' /></> }; break
        case '4': label_financeiro = () => { return <>| <LabelDanger title='em Faturamento' /></> }; break
        case '5': label_financeiro = () => { return <>| <LabelWarning title='Fat.Processado' /></> }; break
        default: label_financeiro = () => { return '' };
    }

    let label_tipo_pagamento
    switch (tp) {
        case '2': label_tipo_pagamento = () => { return <>| <LabelDanger title='Pg.Adi.' /></> }; break
        default: label_tipo_pagamento = () => { return '' };
    }
    switch (s) {
        case '1': return <><LabelSucess title='Programado' />{label_financeiro()}{label_tipo_pagamento()}</>
        case '2': return <><LabelDanger title='Andamento' />{label_financeiro()}{label_tipo_pagamento()}</>
        case '7': return <><LabelInfo title='Finalizado' />{label_financeiro()}{label_tipo_pagamento()}</>
        case '8': return <><LabelDanger title='Contestado' />{label_financeiro()}{label_tipo_pagamento()}</>
        case '9': return <><LabelDefault title='Cancelado' />{label_financeiro()}{label_tipo_pagamento()}</>
        default: return <><LabelDefault title='Indefinido' />{label_financeiro()}{label_tipo_pagamento()}</>
    }

}