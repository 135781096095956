import { initialize } from 'redux-form'
import { transmitir, download } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'lote-cnab'


export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[dados['field']] = dados['value']
    }
    if (dados && dados['sit_lote']) {
        vo['sit_lote'] = dados['sit_lote']
    }
    return transmitir(vo, 'get', ROTA + '-list',
        (payload) => ({ type: act.LOTE_CNAB_LISTADO, payload: payload })
    )
}


export const obter = (id_lote) => {
    let vo = []
    vo['id_lote'] = id_lote
    return transmitir(vo, 'get', ROTA,
        (payload) => ({ type: act.LOTE_CNAB_CARREGADO, payload: payload[0] })
    )
}

export const cancelar = (id_lote) => {
    let vo = {}
    vo['id_lote'] = id_lote
    return transmitir(vo, 'post', ROTA + '-cancelar',
        (payload) => ([
            { type: act.LOTE_CNAB_SALVO, payload: payload || {} },
            obter(vo['id_lote']),
            obter_items(vo['id_lote']),
        ])
    )
}

export const finalizar = (vo) => {
    return transmitir(vo, 'post', ROTA + '-finalizar',
        (payload) => ([
            { type: act.LOTE_CNAB_SALVO, payload: payload || {} },
            obter(vo['id_lote']),
            obter_items(vo['id_lote']),
        ])
    )
}

export const obter_items = (id_lote) => {
    let vo = []
    vo['id_lote'] = id_lote
    return transmitir(vo, 'get', ROTA+'-items-list',
        (payload) => ({ type: act.LOTE_CNAB_ITEMS_LISTADO, payload: payload })
    )
}

export const listar_mais = (page, id_lote) => {
    let vo = []
    vo['page'] = page;
    vo['id_lote'] = id_lote
    return transmitir(vo, 'get', ROTA+'-items-list',
        (payload) => ([
            { type: act.LOTE_CNAB_ITEMS_LISTADO_MAIS, payload: payload },
            { type: act.LOTE_CNAB_ITEMS_ADD_PAGE, payload: page }
        ])
    )
}

export const download_arquivo = (filename, id_lote) => {
    let vo = {}
    vo['filename'] = filename
    vo['id_lote'] = id_lote
    return download(vo, ROTA+'-download', filename)
}

export const carregar_arquivo_retorno = (dados) => {
    return transmitir(dados, 'post', ROTA+'-upload-retorno', 
        (payload)=> ([
            listar([])
        ])
    )
}

export const init = () => {
    return [
        initialize('loteCnabEditForm', VALORES_INICIAIS)
    ]
}
