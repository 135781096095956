import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'

import { Input, SelectGroup } from '../common/form'
import { BoxSimple } from '../common/widget/box'

import { listar } from './lote-cnab-action'

class LoteCnabFilter extends Component {

    onSubmit(values) {
        let dados = {
            field: values.pesqcampo,
            value: values.pesqvalor,
            sit_lote: values.pesq_sit_lote
        }
        this.props.listar(dados)
    }


    render() {

        const { handleSubmit } = this.props

        return (
            <BoxSimple>
                <form onSubmit={handleSubmit(values => this.onSubmit(values))}>

                    <Field name="pesq_sit_lote" component={SelectGroup} defaultValue="sit_lote">
                        <option value="">Todos</option>
                        <option value="0">Gerado</option>
                        <option value="1">Download Realizado</option>
                        <option value="3">Pago Parcialmente</option>
                        <option value="7">Totalmente Pago</option>
                        <option value="9">Cancelado</option>
                    </Field>

                    <Field name="pesqcampo" component={SelectGroup} defaultValue="cliente">
                        <option value="arquivo">Arquivo</option>
                        <option value="referencia">Referencia</option>
                    </Field>

                    <div className="input-group input-group-sm">
                        <Field name="pesqvalor" component={Input} className="form-control" />
                        <span className="input-group-btn">
                            <button type="submit" className="btn btn-info btn-flat">GO!</button>
                        </span>
                    </div>

                </form>
            </BoxSimple>

        )
    }

}

LoteCnabFilter = reduxForm({
    form: 'loteCnabPesquisaForm',
    initialValues: { pesqcampo: 'origem' }
})(LoteCnabFilter)

const mapStateToProps = state => ({ lote_cnab: state.lote_cnab })
const mapDispatchToProps = dispatch => bindActionCreators({ listar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoteCnabFilter)
