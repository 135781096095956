import React from 'react'
import { Link } from 'react-router-dom'

import { date_time_loc, date_loc, money_loc } from '../sys/normalize-location'
import config from '../main/config'

import { os_servico_situacao } from '../os-servico/os-servico-labels'

export default function os_servico_card(list) {

    function addValue(label, vl, bold) {
        if (parseFloat(vl) === 0 && !bold ) return
        if (bold) return (
            <>
                <span className="pull-right text-muted">
                    <strong>{label}</strong>: {money_loc(vl)}
                </span> <br />
            </>
        )
        return (
            <>
                <span className="pull-right text-muted">
                    {label}: {money_loc(vl)}
                </span> <br />
            </>
        )
    }


    const img_profile = (config().IMAGE_PATH + 'profile.jpg')
    if (!list) {
        return (
            <div>Vazio</div>
        )
    }
    return (list).map(vo => {
        let box
        switch (vo['sit_servico']) {
            case '1': box = 'success'; break
            case '2': box = 'warning'; break
            case '7': box = 'info'; break
            case '8': box = 'danger'; break
            case '9': box = 'default'; break
            default: box = 'default'; break
        }
        return (
            <div className={`box box-${box}`} key={vo['id_servico']}>
                <div className="box-header with-border">
                    <div className="user-block">
                        <img className="img-circle" src={img_profile} alt="Agente" />
                        <span className="username"><Link to={`/ficha/${vo['id_agente']}`}>{vo['agente']}</Link></span>
                        <span className="description">
                            {date_time_loc(vo['dia_real'] + ' ' + vo['hora_real'])}
                            {' | '} <Link to={`/ficha/${vo['id_cliente']}`}>{vo['cliente']}</Link>
                            {' | '} <Link to={`/missao-ficha/${vo['id_os']}`}>{vo['os_numero']}</Link>
                            {' | '} {os_servico_situacao(vo['sit_servico'], vo['sit_financeiro'], vo['tipo_pag'])}
                        </span>
                    </div>
                </div>
                <div className="box-body">
                    <p>
                        {vo['servico']}  {vo['placas'] ? ' | ' + vo['placas'] : ''}
                        {vo['critica'] ? <span className="text-red"><br /> {vo['critica']}</span> : ''}
                        {(vo['cnab_retorno_msg'] && vo['vl_pag_agente'] > 0) ? <span className="text-green"><br /> {vo['cnab_retorno_msg']}</span> : ''}
                        {(vo['cnab_retorno_msg'] && vo['vl_pag_agente'] <= 0) ? <span className="text-red"><br /> {vo['cnab_retorno_msg']}</span> : ''}
                        {(vo['dia_pag_agente_prog']) ? <span className="text-green"><br /> {
                            'Quitar Agente em ' + date_loc(vo['dia_pag_agente_prog']) + ' | ' + vo['cnab_arq_envio']
                        }</span> : ''}
                        {(vo['vl_pag_agente'] > 0) ? <span className="text-green"><br /> {
                            'Agente Quitado: ' + money_loc(vo['vl_pag_agente'])
                            + ' em ' + date_loc(vo['dia_pg_agente'])
                            + ' | ' + vo['cnab_arq_retorno']
                        }</span> : ''}

                        <span className="pull-right margin-r-5">
                            {addValue('Total a Cobrar', vo['total_cobrar'], true)}
                            {addValue('Preço', vo['preco'])}
                            {addValue('HE Cobrar', vo['he_cobrar'])}
                            {addValue('Adicional', vo['preco_adicional'])}
                            {addValue('Combustível', vo['combustivel'])}
                            {addValue('Estacionamento', vo['estacionamento'])}
                            {addValue('Lavagem', vo['lavagem'])}
                            {addValue('Pedágio', vo['pedagio'])}
                            {addValue('Condução', vo['conducao'])}
                            {addValue('Alimentação', vo['alimentacao'])}
                            {addValue('Outros', vo['outros'])}
                        </span>

                        <span className="pull-right margin-r-5">
                            {addValue('Total a Pagar', vo['total_pagar'], true)}
                            {addValue('Custo', vo['custo'])}
                            {addValue('Adiantamento', vo['adiantamento_agente'])}
                            {addValue('Reembolso', vo['reembolsar_agente'])}
                            {addValue('HE Pagar', vo['he_pagar'])}
                            {addValue('Desconto', vo['descontar_agente'])}

                        </span>

                    </p>
                </div>
            </div>
        )
    })
}   