import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { obterContadores } from './dashboard-action'

import { Row } from '../common/layout'
import Smallbox from '../common/widget/small-box'
import { CirclePacking } from '../common/chart/CirclePack'

class Contadores extends Component {

    componentDidMount() {
        this.props.obterContadores()
    }


    render() {
        return (
            <>
                <Row>

                    <Smallbox
                        icon="fa fa-feed"
                        value={this.props.dash['total_srv_programados'] || 0}
                        sup=""
                        title="Programados para Semana"
                        add_class="bg-green"
                        cols="md-3"
                        route="/radar/progamados_semana"
                    />

                    <Smallbox
                        icon="fa fa-feed"
                        value={this.props.dash['total_srv_finalizados'] || 0}
                        sup=""
                        title="Finalizados na Semana"
                        add_class="bg-yellow"
                        cols="md-3"
                        route="/radar/finalizados_semana"
                    />

                    <Smallbox
                        icon="fa fa-feed"
                        value={this.props.dash['total_srv_indefinidos'] || 0}
                        sup=""
                        title="Programações Indefinidas"
                        add_class="bg-teal-active"
                        cols="md-3"
                        route="/radar/indefinidos"
                    />
                    <Smallbox
                        icon="fa fa-feed"
                        value={this.props.dash['total_srv_cancelados'] || 0}
                        sup=""
                        title="Cancelados na Semana"
                        add_class="bg-maroon"
                        cols="md-3"
                        route="/radar/cancelados_semana"
                    />


                </Row>
                <Row>
                    <CirclePacking data={this.props?.dash['total_srv_pacote'] ?? {}} />
                </Row>
            </>


        )
    }

}

const mapStateToProps = state => ({ dash: state.dash })
const mapDispatchToProps = dispatch => bindActionCreators({ obterContadores }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Contadores)

