import act from '../main/action-const'

const INITIAL_STATE = {
    lista: [],
    filtro: [],
    id_servico_atual: 0,
    page: 1,
    tem_mais: false
}

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.RADAR_LISTADO:
            return {
                ...state, lista: action.payload,
                page: 1,
                id_servico_atual: 0,
                tem_mais: action.payload.length > 0
            }

        case act.RADAR_LISTADO_MAIS:
            return {
                ...state, lista: [...state.lista, ...action.payload],
                tem_mais: action.payload.length > 0
            }

        case act.RADAR_ADD_PAGE:
            return {
                ...state, page: action.payload
            }

        case act.RADAR_LISTADO_SAVE_FILTRO:
            return {
                ...state, filtro: action.payload
            }

        case act.RADAR_SERVICO_ATUAL:
            return {
                ...state, id_servico_atual: action.payload
            }

        case act.RADAR_SALVO:
            const idx = state.lista.findIndex( e => e['id_servico'] === action.payload['id_servico'] )
            if (idx < 0) return {...state }
            const new_list = state.lista
            new_list[idx] = { ...new_list[idx] ,...action.payload}
            return {
                ...state, lista: new_list
            }            

        default:
            return state
    }
}
