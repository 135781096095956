import act from '../main/action-const'

const INITIAL_STATE = {
    dados: {},
    lista: [],
    pesquisa: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
    recibo_list: []
}

const DADOS_CLEAR = {
    id_servico: 0,
    id_os: 0,
    id_agente: 0,
    sit_servico: 0,
    tipo: 1,
    ref: '',
    descricao: '',
    dia_prog: '',
    hora_prog: '',
    dia_real: '',
    hora_real: '',
    dia_pg_agente: '',
    preco: 0.0,
    custo: 0.0,
    preco_adicional: 0.0,
    placas: '',
    custo_adicional_total: 0.0,
    combustivel: 0.0,
    lavagem: 0.0,
    estacionamento: 0.0,
    conducao: 0.0,
    alimentacao: 0.0,
    pedagio: 0.0,
    outros: 0.0,
    outros_custos_descricao:'',
    observacao: '',
    preco_abater: 0.0,
    he_cobrar: 0.0,
    descontar_agente: 0.0,
    adiantamento_agente: 0.0,
    reembolsar_agente: 0.0,
    he_pagar: 0.0
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

        case act.OS_SERVICO_CARREGADO:
            return { ...state, curso: action.payload }

        case act.OS_SERVICO_LISTADA:
            return {
                ...state, lista: action.payload,
                upd_status: 0
            }

        case act.OS_SERVICO_CARREGADA:
            return { ...state, dados: action.payload, upd_status: 4 }
            
        case act.OS_SERVICO_DADOS_ATUALIZAR:
            const dados = { ...state.dados,  ...action.payload}
            return { ...state, dados: dados }

        case act.OS_SERVICO_SALVA:
        case act.OS_SERVICO_CANCELADO:
        case act.OS_SERVICO_FINALIZADO:
            return {
                ...state, dados: action.payload,
                upd_status: 3
            }
            
        case act.OS_SERVICO_PESQUISADA:
            return { 
                ...state, 
                pesquisa: action.payload, 
                upd_status: (Array.isArray(action.payload) && action.payload.length > 0) ? 1 : 2 
            }

        case act.OS_SERVICO_LIMPAR:
            return  { ...state, dados: DADOS_CLEAR }


        case act.OS_SERVICO_RECIBO_LISTADA:
            return {
                ...state, recibo_list: action.payload
            }

        default:
            return state
    }
}
