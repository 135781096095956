import act from '../main/action-const'

const INITIAL_STATE = {
    lista: [],
    data: {}
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

        case act.SOLICITANTE_CLIENTE_LISTADA:
            return {
                ...state, lista: action.payload
            }

        case act.SOLICITANTE_CLIENTE_RESERVA:

            return {
                ...state, data: action.payload
            }

        default:
            return state
    }
}
