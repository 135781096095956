import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'

import { Input, SelectGroup } from '../common/form'
import { BoxSimple } from '../common/widget/box'

import {listar} from './os-ref-action'

class OSRefFilter extends Component {

    onSubmit(values) {
        let dados = {
            field: values.pesqcampo,
            value: values.pesqvalor,
        }
        this.props.listar(dados)
    }


    render() {

        const { handleSubmit } = this.props

        return(
            <BoxSimple>
                <form onSubmit={handleSubmit( values => this.onSubmit(values))}>

                    <Field name="pesqcampo" component={SelectGroup} defaultValue="ref">
                        <option value="descricao">Descrição</option>
                        <option value="ref">Referencia</option>
                    </Field>

                    <div className="input-group input-group-sm">
                        <Field name="pesqvalor" component={Input} className="form-control"/>
                        <span className="input-group-btn">
                            <button type="submit" className="btn btn-info btn-flat">GO!</button>
                        </span>
                    </div>

                </form>
            </BoxSimple>

        )
    }

}

OSRefFilter = reduxForm({
    form: 'osrefPesquisaForm',
    initialValues: {pesqcampo: 'descricao'}
})(OSRefFilter)

const mapStateToProps = state => ({os: state.os})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSRefFilter)
