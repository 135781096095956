import act from '../main/action-const'

const INITIAL_STATE = {
    dados: {},
    lista: [],
    items: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
    page: 1,
    tem_mais: false
}

function juntar_ocorrencias(items) {
    for(let i in items) {
        let ocorrencias = items[i]['ocorrencias']
        let text = ''
        for (let j in ocorrencias) {
            text = ocorrencias[j]+'; \n'
        }
        items[i]['ocorrencias'] = text

    }
    return items
}


export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.LOTE_CNAB_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0

            }

        case act.LOTE_CNAB_ITEMS_LISTADO:
            return {
                ...state, items: juntar_ocorrencias(action.payload),
                page: 1,
                tem_mais: action.payload.length > 0
            }

        case act.LOTE_CNAB_ITEMS_LISTADO_MAIS:
            return {
                ...state, items: [...state.items, ...juntar_ocorrencias(action.payload)],
                tem_mais: action.payload.length > 0
            }

        case act.LOTE_CNAB_ITEMS_ADD_PAGE:
            return {
                ...state, page: action.payload
            }

        case act.LOTE_CNAB_CARREGADO:
            return { ...state, dados: action.payload, upd_status: 4 }


        case act.LOTE_CNAB_SALVO:
            return {
                ...state, dados: action.payload,
                upd_status: 3
            }

        case act.LOTE_CNAB_INCLUIDO:
            return {
                ...state, dados: action.payload,
                upd_status: 5
            }

        default:
            return state
    }
}
