import React, { Component } from 'react'
// import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'

import { listar, listar_mais, set_servico_atual } from './radar-action'
import os_servico_card from '../os-servico/os-servico-card'

import { ButtonInfo } from '../common/ui/buttons'

import RadarFilter from './radar-filter-2'

class Radar extends Component {

    render() {
        return (
            <PageSetup title='Radar de Serviços' subtitle='Consultas'>
                <ContentSection>

                    <Row>
                        <Grid cols='md-3'>
                            <RadarFilter />
                        </Grid>
                        <Grid cols='md-9'>
                            {os_servico_card(this.props.radar.lista)}
                            {(this.props.radar.tem_mais) &&
                                <ButtonInfo onClick={() => {
                                    this.props.listar_mais(this.props.radar.page + 1, this.props.radar.filtro)
                                }}>Carregar mais...</ButtonInfo>
                            }
                        </Grid>
                    </Row>
                </ContentSection>

            </PageSetup>
        )
    }
}

const mapStateToProps = state => ({
    radar: state.radar
})
const mapDispatchToProps = dispatch => bindActionCreators({
    listar,
    listar_mais,
    set_servico_atual
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Radar)
