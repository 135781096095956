import React, { Component } from 'react'
import { Field } from 'redux-form'

import { BoxTitle, BoxBody } from '../common/widget/box'
import { InputGroup, SelectGroup, FormRow } from '../common/form'

export const documentos_push  = (values, dados) => {
    dados['cnh'] = values.cnh
    dados['cnh_categoria'] = values.cnh_categoria
    dados['cnh_dt_1habilitacao'] = values.cnh_dt_1habilitacao
    dados['cnh_dt_validade'] = values.cnh_dt_validade
    dados['rg'] = values.rg
    dados['rg_uf'] = values.rg_uf
    dados['rg_orgao_exped'] = values.rg_orgao_exped
    dados['rg_uf'] = values.rg_uf
    dados['uf_nascimento'] = values.uf_nascimento
}

class DocumentosEdit extends Component {
    render() {
        return(
            <BoxTitle title="Outros Documentos">
                <BoxBody>    
                    <FormRow>
                        <Field label='CNH' name="cnh"  placeholder='CNH' component={InputGroup}  cols='md-4' />
                        <Field label='Categoria' name="cnh_categoria" component={SelectGroup}  cols='md-2'>
                            <option/>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                        </Field>
                        <Field label='Validade CNH' name="cnh_dt_validade" type="date" placeholder='Validade' component={InputGroup} cols='md-4' />
                    </FormRow>
                    <FormRow>
                        <Field label='R.G.' name="rg"  placeholder='R.G.' component={InputGroup}  cols='md-4' />
                        <Field label='R.G. Org. Exped.' name="rg_orgao_exped"  placeholder='R.G. Org. Exped.' component={InputGroup}  cols='md-4' />
                        <Field label='R.G. UF' name="rg_uf" component={SelectGroup}  cols='md-2'>
                        <option/>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Brasília</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                            <option value="EX">Exterior</option>
                        </Field>
                        <Field label='UF Nascimento' name="uf_nascimento" component={SelectGroup}  cols='md-2'>
                        <option/>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Brasília</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                            <option value="EX">Exterior</option>
                        </Field>

                    </FormRow>
                </BoxBody>
            </BoxTitle>
        )
    }
}
export default DocumentosEdit