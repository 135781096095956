import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'os-paga'


export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[dados['field']] = dados['value']
    }
    if (dados && dados['sit_paga']) {
        vo['sit_paga'] = dados['sit_paga']
    }
    return transmitir(vo, 'get', 'os-paga-list',
        (payload) => ({ type: act.OS_PAGA_LISTADO, payload: payload })
    )
}


export const obter = (id_paga) => {
    let vo = []
    vo['id_paga'] = id_paga
    return transmitir(vo, 'get', ROTA,
        (payload) => ({ type: act.OS_PAGA_CARREGADO, payload: payload[0] })
    )
}

export const gerar = (dados) => {
    return transmitir(dados, 'post', ROTA + '-gerar',
        (payload) => ({ type: act.OS_PAGA_INCLUIDO, payload: payload })
    )
}

export const gerar_lote = (dados) => {
    return transmitir(dados, 'post', ROTA + '-gerar-lote',
        (payload) => ({ type: null, payload: payload })
    )
}

export const simular = (dados) => {
    let vo = []
    if (dados['id_cliente']) vo['id_cliente'] = dados['id_cliente']
    if (dados['dia_inicio']) vo['dia_inicio'] = dados['dia_inicio']
    if (dados['dia_final']) vo['dia_final'] = dados['dia_final']
    return transmitir(vo, 'get', ROTA + '-simular', (payload) => ({ type: act.OS_PAGA_SIMULADO, payload: payload[0] }))
}

export const cancelar = (vo) => {
    return transmitir(vo, 'post', ROTA + '-cancelar',
        (payload) => ([
            { type: act.OS_PAGA_SALVO, payload: payload || {} },
            obter(vo['id_paga']),
            listarServicos({ id_paga: vo['id_paga'] }),
        ])
    )
}

export const finalizar = (vo) => {
    return transmitir(vo, 'post', ROTA + '-finalizar',
        (payload) => ([
            { type: act.OS_PAGA_SALVO, payload: payload || {} },
            obter(vo['id_paga']),
            listarServicos({ id_paga: vo['id_paga'] }),
        ])
    )
}

export const listarServicos = (dados) => {
    let vo = []
    vo['id_paga'] = dados['id_paga']
    if (dados && dados['field'] && dados['value']) {
        vo[dados['field']] = dados['value']
    }
    if (dados && dados['sit_servico']) {
        vo['sit_servico'] = dados['sit_servico']
    }
    return transmitir(vo, 'get', 'os-paga-servico-list',
        (payload) => ([
            { type: act.OS_PAGA_SERVICO_SAVE_FILTRO, payload: vo },
            { type: act.OS_PAGA_SERVICO_LISTADO, payload: payload }
        ])
    )
}

export const listar_mais = (page, id_paga, filtro_servico) => {
    let vo = filtro_servico
    vo['page'] = page;
    vo['id_paga'] = id_paga
    return transmitir(vo, 'get', 'os-paga-servico-list',
        (payload) => ([
            { type: act.OS_PAGA_SERVICO_LISTADO_MAIS, payload: payload },
            { type: act.OS_PAGA_SERVICO_ADD_PAGE, payload: page }
        ])
    )
}

export const criticar = (id_paga) => {
    let vo = {}
    vo['id_paga'] = id_paga
    return transmitir(vo, 'post', ROTA + '-criticar',
        (payload) => ([
            { type: act.OS_PAGA_SERVICO_LISTADO, payload: [] },
            listarServicos({ field: 'id_paga', value: id_paga }),
        ])
    )
}

export const init = () => {
    return [
        initialize('osPagaEditForm', VALORES_INICIAIS)
    ]
}
